import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-non-cash-payment',
  templateUrl: './trucking-non-cash-payment.component.html',
  styleUrls: ['./trucking-non-cash-payment.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class TruckingNonCashPaymentPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/gruzoperevozki-dlya-yur-lic',
      linkTitle: 'Грузоперевозки для юридических лиц'
    }, {
      link: '',
          linkTitle: 'Грузоперевозки безналичный расчет'
        }]
  }

  description = 'Транспортная компания «АКРО» занимается перевозками грузов по Москве, области и другим регионам России. Мы доставляем товары до магазинов, складов, распределительных центров Яндекс Маркета, Озона, Вайлдберриз и РЦ (Перекрёсток, Магнит и других). Мы работаем с крупными, средними и мелкими предприятиями по договору.\n' +
    'Все перевозки выполняются с помощью собственного автопарка. Наша компания может предложить вам оплату по безналичному расчету как с НДС, так и без НДС. «АКРО» перевозит любые виды грузов, за исключением опасных видов. '

  blockWithItems = {
    title: 'Наши преимущества',
    description: '',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: '',
      title: '',
      description: 'У нас имеются все необходимые документы для сдачи отчетности (отчетные документы к договору, акт \n' +
        'о выполнении работ).',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'У нас вы найдете максимально  выгодные тарифы, подобранные индивидуально с учетом типа вашего груза, а также мы подберем самый подходящий транспорт.',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Доставка выполняется после 100% предоплаты согласно условиям договора.',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Постоянным корпоративным клиентам предоставляются скидки.',
      class: 'p-20-xs border-blue'
    }]
  }

  blockWithItems2 = {
    title: 'Что вы получаете, работая с «АКРО»',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Быструю доставку по Москве и расчет по безналу с НДС',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Полный пакет отчетных документов для бухгалтерии',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Возможность перевезти любой груз (кроме опасного)',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Персонального логиста. Сопровождение отправления \n' +
        'от точки А до точки Б в режиме онлайн',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Персонального логиста. Сопровождение отправления \n' +
        'от точки А до точки Б в режиме онлайн',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'Работа с документами и решение любых возникающих сложностей находятся в зоне его ответственности. Логист контролирует целостность посылки во время транспортировки, а также передачу и подпись документов на грузоперевозки по безналичному расчету.',
      class: ''
    }]
  }

  blockWithItems3 = {
    title: 'Преимущества расчётов по безналу',
    description: 'Оплата доставки по безналу ускоряет бизнес-процессы и помогает быстрее уладить бюрократические формальности, а значит мы можем оперативно начать работать с вами.',
    class: 'col-12 col-md-6 col-lg-3 text-center',
    items: [{
      image: 'assets/images/trucking-non-cash-payment/trucking-non-cash-payment-5.svg',
      alt: 'Оформление',
      title: '',
      description: 'Простой способ оформления заявки — напрямую на сайте или по телефону.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-non-cash-payment/trucking-non-cash-payment-6.svg',
      alt: 'Подбор',
      title: '',
      description: 'Логистический подбор транспорта, подходящего тарифа, \n' +
        'или организация оперативной доставки.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-non-cash-payment/trucking-non-cash-payment-7.svg',
      alt: 'Форма оплаты',
      title: '',
      description: 'Удобная форма оплаты для крупного бизнеса и налог на добавленную стоимость. ',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-non-cash-payment/trucking-non-cash-payment-8.svg',
      alt: 'Доставка',
      title: '',
      description: 'Доставка по безналу \n' +
        'для предпринимателей. Видна в налоговой отчетности, за счет чего можно уменьшить налоговую базу',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'AKRO - доставка по безналу. Грузоперевозки безналичный расчет Москва, Россия' });
    this.meta.addTag({ property: 'og:description', content: 'Транспортная компания АКРО.✅ Доставка безнал.✅ Доставка оплата по безналу.✅ Грузоперевозки с НДС Москва.✅ ГАЗель по безналу' });
    this.meta.addTag({ name: 'description', content: 'Транспортная компания АКРО.✅ Доставка безнал.✅ Доставка оплата по безналу.✅ Грузоперевозки с НДС Москва.✅ ГАЗель по безналу' });
    this.titleService.setTitle('AKRO - доставка по безналу. Грузоперевозки безналичный расчет Москва, Россия');
  }
}
