<app-banner
  [description]="'Гибкая система скидок и индивидуальные условия'"
  [title]="'Грузоперевозки <br>для юридических лиц'"
  [image]="'/assets/images/transportation-for-businesses/transportation-for-businesses-18.svg'"
  [showButtonCall]="true"
  [showButtonKp]="true">
</app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <app-block-description-page [description]="description"></app-block-description-page>
</div>

<app-service [services]="services"></app-service>

<div class="container pb-50-xs pb-60-lg">
  <app-block-with-items [title]="blockWithItems['title']" [description]="blockWithItems['description']"
    [class]="blockWithItems['class']" [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="row align-items-center text-gray3 pt-0-xs pb-0-xs pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <p class="text-16-lg pt-0">Предпринимателя важным конкурентным преимуществом является своевременная перевозка и доставка товара без повреждения упаковки или внешнего вида, ведь задержка и порча груза влекут за собой убытки и потерю репутации фирмы. При конкуренции выживает тот, кто способен обеспечить хороший сервис услуг по перевозкам. Наша организация гарантирует, что ваш товар будет доставлен до клиентов быстро и в полной сохранности благодаря налаженной логистике.</p>
  </div>
</div>

<div class="container">
  <app-box-image-right [alt]="'услуги'"
                       [descriptionClass]="'col-lg-8'"
                       [description]="'
                    <ul class=p-0>
                        <li class=my-20-xs>Мы организуем переезд магазина или офиса в Москве; довезем ваш товар на склад интернет-магазинов или в розничные точки. Мы предоставляем транспорт, подходящий для безопасной перевозки и доставки вашей продукции. Перед перевозкой грузов с юридическими лицами подписывается официальный договор. После выполнения услуги грузоперевозки предоставляются дополнительные закрывающие документы.</li>
                        <li class=my-20-xs>Персональный менеджер нашей компании, закрепленный за вашей организацией, сделает просчет перевозки с учетом всех деталей, подберет для вас подходящий путь до места, подготовит и обеспечит учет всех необходимых документов, решит каждый момент, связанный с вашей грузоперевозкой. Сопровождение специалистом осуществляется на всех стадиях - от создания онлайн-заявки до выставления счета.</li>
                        <li class=my-20-xs>По Вашему желанию мы предоставим аккуратных опытных грузчиков, а также профессиональных экспедиторов для грузоперевозки. Наши сотрудники проследят за надежностью фиксации груза во время перевозки, проконтролируют каждый этап доставки. Они подготовят всю необходимую документацию для грузоперевозки.</li>
                        <li class=my-20-xs>Квартирный и офисный переезды, покупка габаритных грузов, различных материалов и оборудования — всегда ставит вопрос о перевозке и доставке. Грузовые перевозки оказываются как раз кстати, когда необходимо доставить продукцию в торговые сети Москвы или на маркетплейс, осуществить перевозку офисной мебели и техники. Транспортная компания «АКРО» готова предоставить грузовой автомобиль на заказ определенной вместительности и объемов, который в целости и сохранности доставит грузы к точке выгрузки как в пределах МКАД, так и за его пределами. Обращайтесь в проверенные фирмы, чтобы получить ответственное решение и перевозку лучшего качества.</li>
                        <li class=my-20-xs>Транспортная компания «АКРО» — это идеальный выбор для представителей сфер малого, среднего и крупного бизнеса, которым понадобились срочные грузоперевозки по Москве и Московской области. Наша компания в процессе своей работы успела зарекомендовать себя как надежный и честный партнер, о чем свидетельствуют отзывы наших довольных заказчиков.</li>
                    </ul>'"
                       [imageClass]="'col-12 col-lg-4 mt-50-xs mt-0-lg d-flex justify-content-center'"
                       [image]="'/assets/images/transportation-for-businesses/transportation-for-businesses-17.svg'"
                       [showButtonCall]="true"
                       [title]="'Какие услуги мы оказываем'"
                       class="point-blue">
</app-box-image-right>
</div>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg mt-60-xs mb-60-xs border-grey3">
    <h2 class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Наш автопарк и тарифы</h2>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs">
    </app-tariff>


    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        Квартирный и офисные переезды, покупка габаритных грузов, различных материалов и оборудования — всегда ставит
        вопрос о перевозке
        и доставке. Грузовые перевозки оказываются как раз кстати, когда необходимо доставить новую продукцию в торговые
        сети, на маркетплейс
        или склад клиента, осуществить перевозку офисной мебели и техники. Компания «АКРО» готова предоставить грузовой
        автомобиль на заказ любой вместительности и объемов, который в целости и сохранности доставит грузы к точке
        выгрузки, как в пределах МКАД, так и за его пределами. Обращайтесь в проверенные фирмы, чтобы получить
        ответственность и перевозку лучшего качества.
        <br><br>
        Компания «АКРО» — это идеальный выбор для представителей любых сфер малого, среднего и крупного бизнеса, которым
        время от времени нужны срочные грузоперевозки по Москве и Подмосковью. Наша компания в процессе своей работы
        успела зарекомендовать себя как надежный
        и честный партнер.
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href="" role="button"
        class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href="" role="button" class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>
</div>
<div class="container">
  <div class="pt-50-xs pt-60-lg pb-50-xs pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как мы работаем</h2>

    <p>Мы не первый год занимаемся обслуживанием в сфере логистики по Москве. Мы оказываем услуги официально,  что подтверждается договором. Документ на перевозку содержит важные положения для качественной транспортировки грузов: используемые машины, требования для загрузки и выгрузки, а также для заказа грузчиков, страхование товара и ответственность сторон. Клиенты могут заказать как разовое, так и постоянное сотрудничество.</p>
    <p>Дополнительно в договоре на оказание услуг прописываются непредвиденные ситуации: авария, сложные погодные условия, необходимость погрузки/разгрузки грузчиками. В документе указывается фиксированная цена работ.</p>
    <p>В случае долгосрочной деятельности составляется график. Если требуется разовая перевозка, дату доставки указывается отдельным пунктом в контракте. В обозначенные сроки мы забираем груз с вашего производства или склада. Каждый этап транспортировки контролируется экспедитором.</p>
    <p>Лучшие тарифы и широкий спектр услуг позволяет не только подобрать путь, но и сэкономить ваши денежные и временные ресурсы.</p>
    <br>

    <div class="row text-grey4">
      <div class="mb-20-xs col-12 col-md-4 col-lg-4">
        <div class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column text-center-lg">
          <img src="/assets/images/transportation-for-businesses/transportation-for-businesses-15.svg"
            class="mb-0-lg ml-10-lg pb-20-xs" style="margin: auto;" alt="с кем сотрудничаем">
          <h3 class="text-24-xs mb-16-xs">С кем сотрудничаем</h3>
          <p class="text-16-xs p-0-xs m-0-xs">ТК «АКРО» – партнер перевозки для ритейлеров, оптовых поставщиков, производственных предприятий, сетевых торговых компаний. Осуществляем доставку  генеральных и сборных грузов в пункты терминалов маркетплейсов. Сотрудничать с нами выгодно и удобно!</p>
        </div>
      </div>

      <div class="mb-20-xs col-12 col-md-4 col-lg-4">
        <div
          class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column text-center-lg">
          <img src="/assets/images/transportation-for-businesses/transportation-for-businesses-14.svg"
            class="mb-0-lg ml-10-lg pb-20-xs" style="margin-left: auto; margin-right: auto;" alt="погрузка">
          <h3 class="text-24-xs mb-16-xs">Что перевозим</h3>
          <p class="text-16-xs p-0-xs m-0-xs">Транспортируем мебель, продукты, бытовую химию, промтовары, строительные материалы, оборудование, другие грузы в Москве.</p>
        </div>
      </div>

      <div class="mb-20-xs col-12 col-md-4 col-lg-4">
        <div
          class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column text-center-lg">
          <img src="/assets/images/transportation-for-businesses/transportation-for-businesses-16.svg"
            class="mb-0-lg ml-10-lg pb-20-xs" style="margin-left: auto; margin-right: auto;" alt="стоимость">
          <h3 class="text-24-xs mb-16-xs">От чего зависит стоимость</h3>
          <p class="text-16-xs p-0-xs m-0-xs">Зависит она от типа груза, веса (тонн) и дальности маршрута, используемого автомобиля. Дороже обойдется перевозка скоропортящихся товаров рефрижератором.</p>
        </div>
      </div>

      <div class="mb-20-xs col-12">
        <div
          class="b-r-16-xs height-to-full position-relative flex-column flex-md-row bg-purple border-grey3 p-20-xs d-flex align-items-center text-center-lg">
          <img src="/assets/images/transportation-for-businesses/transportation-for-businesses-13.svg"
            class="mb-0-lg ml-10-lg col-4 px-90-md pb-20-xs" alt="отслеживание">
          <div class="">
            <h3 class="text-24-xs mb-16-xs">Как отследить отправление и оплатить</h3>
            <p class="text-16-xs p-0-xs m-0-xs">Контролировать перемещение груза можно на сайте.<br>В работе мы используем следующие системы оплаты: наличные, безналичную оплату в соответствии с НДС (платеж осуществляется на расчетный счет). Постоянным заказчикам предоставляются бонусы и скидки. При заключении договора на регулярные перевозки стоимость очередной доставки снижается.</p>
          </div>
        </div>
      </div>


    </div>

    <!-- <div class="row mt-0-xs pb-50-xs">
      <div class="col-lg-6 text-16-xs text-grey6">
        Мы не первый год занимаемся обслуживанием в Москве. Мы работаем официально что подтверждается договором.
        Документ на перевозку содержит важные условия для качественной транспортировки грузов: стоимость, используемый
        транспорт, требования для загрузки и выгрузки, условия для заказа грузчиков, страхование товара. Клиенты могут
        заказать как разовое,
        так и постоянное сотрудничество. Дополнительно в договоре на оказание услуг прописываются непредвиденные
        ситуации: авария, сложные погодные условия.
      </div>
      <div class="col-lg-6 text-16-xs text-grey6">
        В документе указывается фиксированная стоимость работ. В случае долгосрочного сотрудничества составляется
        график. Если нужна разовая перевозка, время доставки указывается отдельным пунктом в договоре.
        В обозначенные сроки мы забираем груз с вашего производства или склада. Каждый этап транспортировки
        контролируется экспедитором.
      </div>
    </div> -->


    <div class="pb-50-xs pb-0 pb-60-lg pt-50-xs pt-60-lg">

          <h2 class="text-48-lg mb-32-xs text-grey4">Требования к грузу</h2>
          <div class="row m-0-xs mt-48-xs position-relative">
            <div class="mb-20-xs col-12 col-lg-4 b-r-16-xs height-to-full d-flex flex-column justify-content-center align-items-start p-20-xs p-0-lg bg-purple bg-white-lg border-grey3 border-white-lg">
              <span class="p-0-xs align-self-center text-16-xs text-grey6">Мы доставляем разные, в том числе скоропортящиеся грузы, требующие соблюдения температурного режима, весом от 500 кг до 20 тонн. Не перевозим опасные или нарушающие закон грузы. Транспортировка хрупкого, негабаритного товара обсуждается отдельно.</span>
            </div>
            <div class="mb-20-xs col-12 col-lg-4 b-r-16-xs height-to-full d-flex flex-column justify-content-center align-items-start p-20-xs p-0-lg bg-purple bg-white-lg border-grey3 border-white-lg">
              <span class="p-0-xs align-self-center text-16-xs text-grey6">Чтобы доставка прошла без задержек и проблем, требуется соблюсти простые правила:</span>
            </div>
            <div class="col-12 col-lg-5 mt-20-xs mt-0-lg my-30-xs position-absolute-lg" style="right: -100px; top: -85px;">
              <img loading="lazy" src="/assets/images/transportation-for-businesses/transportation-for-businesses-19.svg" alt="требования к грузу">
            </div>
          </div>


      <div class="row">
            <div class="mb-20-xs col-12 col-lg-4">
              <div
                class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-between align-items-start">
                <p class="text-18-xs p-0-xs m-0-xs text-grey4">максимальный объем одной коробки должен быть 20 кг;</p>
              </div>
            </div>
            <div class="mb-20-xs col-12 col-lg-4">
              <div
                class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-between align-items-start">
                <p class="text-18-xs p-0-xs m-0-xs text-grey4">при объеме больше кубометра коробки упаковываются в паллеты;</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mb-20-xs col-12 col-lg-4">
              <div
                class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-between align-items-start">
                <p class="text-18-xs p-0-xs m-0-xs text-grey4">высота паллеты не должна превышать 180 см;</p>
              </div>
            </div>
            <div class="mb-20-xs col-12 col-lg-4">
              <div
                class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-between align-items-start">
                <p class="text-18-xs p-0-xs m-0-xs text-grey4">упаковка не должна иметь повреждений.</p>
              </div>
            </div>
            <div class="mb-20-xs col-12 col-lg-4">
              <div
                class="b-r-16-xs height-to-full d-flex flex-column justify-content-center align-items-start p-20-xs p-0-lg bg-purple bg-white-lg border-grey3 border-white-lg">
                <p class="text-16-xs p-0-xs m-0-xs text-grey6">Уточнить ситуацию с перевозками продуктов, стройматериалов, сыпучих и других грузов можно написав электронное письмо, оставив заявку на грузоперевозку или позвонив по телефону. Наши специальные условия вас порадуют!</p>
              </div>
            </div>
          </div>
  </div>

  <div class="pb-50-xs pb-0 pb-60-lg pt-50-xs pt-60-lg">

    <h2 class="text-48-lg mb-32-xs text-grey4">Что вы получаете с ТК «АКРО»:</h2>
    <div class="row mt-20-xs mt-30-lg pt-50-lg">
      <div class="col-12 col-lg-6 text-18-xs point-blue text-grey6 pr-50-lg pb-30-xs pb-0-lg">
        <ul>
          <li class="mb-10-xs">Оперативную перевозку юридическим лицам</li>
          <li class="mb-10-xs">Гибкое ценообразование: индивидуальный подход, акции и скидки постоянным клиентам</li>
          <li class="mb-10-xs">Персонального менеджера, который всегда даст информацию, построит оптимальный маршрут и оформит все сопроводительные документы</li>
          <li class="mb-10-xs">Личную страницу, на которой вы легко можете создавать свежие заявки на грузоперевозку и отслеживать текущие, хранить и скачивать документы. Также в нем есть калькулятор , которым можно воспользоваться для расчета перевозки за км.</li>
          <li class="mb-10-xs">Большое количество транспорта для аренды с грузоподъемностью 500 кг, 1-3 тонны, 10-20 тонн. Вы можете выбрать тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши специалисты всегда помогут с выбором. Возможна подача авто в день обращения!</li>
        </ul>
      </div>
      <div class="col-12 col-lg-6 px-24-xs">
      <div class="text-16-xs text-16-xs b-r-16-xs p-24-xs border-blue text-grey6">
        У нас в штате вежливый внимательный персонал с богатым опытом в логистике. Перезвоним в течение двух минут. Наши специалисты по корпоративным клиентам бесплатно и подробно изложат нужную информацию, расскажут про услугу перевозки для юридических лиц и экспедирование.
        <br><br>
        Чтобы заказать грузоперевозку и доставку, позвоните нам или оставьте запрос. Собственный автопарк постоянно обновляется: новая техника регулярно проходит техосмотр и оперативно готов к отправке. Опытная команда обеспечит расчет, помощь и доставку до места в самые сжатые сроки и подготовит вам качественную перевозку.
        <br><br>
        Оставьте заявку на грузоперевозку сегодня до 17:00, и уже завтра ваш груз отправится получателю. Также по желанию мы предоставляем транспорт день в день.
      </div>
      </div>
    </div>
  </div>




  <app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>

    <div class="container">
    <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
      <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
      <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
    </div>
  </div>
