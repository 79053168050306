import {Component, Input} from '@angular/core';
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-box-image-right',
  templateUrl: './box-image-right.component.html',
  styleUrls: ['./box-image-right.component.less']
})
export class BoxImageRightComponent {
  @Input() image: string | undefined
  @Input() title: string | undefined
  @Input() description: string | undefined
  @Input() showButtonCall: boolean | undefined
  @Input() imageClass: string | undefined
  @Input() descriptionClass: string | undefined
  @Input() alt: string | undefined

  constructor(public readonly popupService: PopupService) {
  }
}
