import {Component, Input, ViewEncapsulation} from '@angular/core';
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class JobsPage {
  @Input() tabActive: number | undefined = 3;

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Вакансии'
    }]
  }

  blockWithItems = {
    title: 'О компании',
    description: 'За более чем  5 летний опыт на рынке грузоперевозок, наша компания сформировала базу клиентов. Сегодня у нас более 250 компаний, которым мы помогаем перевозить грузы по Москве и всей России разными видами транспорта.\n' +
      'Чтобы обеспечить каждому из них качественное обслуживание, мы используем современный софт и различные автоматизации,  постоянно улучшаем наш сервис \n' +
      'во всех областях!',
    rowClass: 'justify-content-between',
    class: 'col-12 col-md-6 col-lg-auto',
    items: [{
      image: '/assets/images/jobs/jobs-1.svg',
      alt: 'Клиенты',
      imageClass: 'order-1 mb-0-lg mr-14-xs mr-24-lg',
      title: '',
      description: 'более 260 <br> клиентов',
      class: 'd-flex flex-row-reverse justify-content-end align-items-center'
    },{
      image: '/assets/images/jobs/jobs-2.svg',
      alt: 'Процессы',
      imageClass: 'order-1 mb-0-lg mr-14-xs mr-24-lg',
      title: '',
      description: 'Четко выстроенные <br> процессы',
      class: 'd-flex flex-row-reverse justify-content-end align-items-center'
    },{
      image: '/assets/images/jobs/jobs-3.svg',
      alt: 'Развитие',
      imageClass: 'order-1 mb-0-lg mr-14-xs mr-24-lg ',
      title: '',
      description: 'Возможность развиваться <br> вместе с компанией',
      class: 'd-flex flex-row-reverse justify-content-end justify-content-lg-start align-items-center'
    },{
      image: '/assets/images/jobs/jobs-4.svg',
      alt: 'Автопарк',
      imageClass: 'order-1 mb-0-lg mr-14-xs mr-24-lg ',
      title: '',
      description: 'Собственный <br> автопарк',
      class: 'd-flex flex-row-reverse justify-content-end justify-content-lg-start align-items-center'
    }]
  }

  changeTabsActive(index: any) {
    this.tabActive = index['value'] ? parseInt(index['value']) : index
  }

  constructor(
    public readonly popupService: PopupService
  ) {
  }
}
