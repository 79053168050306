import {AfterContentInit, Component, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {PopupService} from "../../popup.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-tariff-short',
  templateUrl: './tariff-short.component.html',
  styleUrls: ['./tariff-short.component.less']
})
export class TariffShortComponent implements OnInit, AfterContentInit {
  @Input() carActive: number | undefined
  @Input() typeActive: number | undefined
  @Input() typeTitle: string | undefined
  @Input() showButtonCall: boolean | undefined
  @Input() tariffs: any = []


  @ViewChild("slickModal") slickModal: SlickCarouselComponent | undefined;

  nds: boolean = false
  isMobile: boolean = false
  items: [] | undefined
  isLoaded = false;

  slideConfig = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  constructor(
    public readonly popupService: PopupService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.getScreenSize()
  }

  ngOnInit(): void {
    this.getItems()
  }

  changeTypeActive(index: any) {
    this.typeActive = index['value'] ? parseInt(index['value']) : index
    this.getItems()

    if (this.isMobile && this.slickModal) {
      this.slickModal.slides[0].refresh
    }
  }

  changeCarActive(index: number) {
    this.carActive = index
    this.getItems()

    if (this.isMobile && this.slickModal) {
      this.slickModal.slides[0].refresh
    }
  }

  getTypeItems() {
    let items = []

    if (this.carActive != undefined) {
      items = this.tariffs[this.carActive]['items']
    } else {
      items = this.tariffs
    }

    return items
  }

  getItems() {
    this.items = []

    if (this.carActive != undefined) {
      // @ts-ignore
      this.items = this.tariffs[this.carActive]['items'][this.typeActive]['items']
    } else {
      // @ts-ignore
      this.items = this.tariffs[this.typeActive]['items']
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    if (this.isLoaded) {
      this.processSlick();
    }
  }

  ngAfterContentInit(): void {
    this.isLoaded = true;
    this.processSlick();
  }

  public processSlick(): void {
    if (this.isLoaded) {
      this.isMobile = Boolean(this.document && this.document.body?.offsetWidth < 1024);
      if (this.isMobile && this.slickModal && !this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
      if (!this.isMobile && this.slickModal && this.slickModal.initialized) {
        this.slickModal.unslick()
      }
    }
  }
}



