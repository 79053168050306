<div class="container pt-50-xs pt-100-md pb-50-xs pb-60-md">
  <div class="row text-md-start pr-30-xs pr-0-lg pl-30-xs pl-0-lg px-15-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Акции и скидки</h2>

    <div class="d-none d-md-block">
      <div class="row">
        <div class="col-12 col-lg-6 pr-20-xs pr-30-lg pl-20-xs pb-20-md" *ngFor="let item of items">
          <div class="row height-to-full border-blue text-blue pr-30-md pl-30-md pt-20-md pb-20-md b-r-16-xs">
            <div class="col-12 col-md-7">
              <div class="row height-to-full">
                <span class="col-12 text-24-md align-items-start">{{item['title']}}</span>

                <div class="col-12 align-self-end">
                  <div class="row align-items-end">
                    <span class="col-12 text-16-md">{{item['description']}}</span>
                    <span class="col-12 text-48-md">{{item['percent']}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="row height-to-full">
                <div class="col-12 align-self-center">
                  <img alt="акция" loading="lazy" src="{{item['image']}}">
                </div>
                <div class="col-12 align-self-end text-end mt-40-md">
                  <a href="" class="text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
                    <span class="mr-10-md">Подробнее</span>
                    <img alt="акция 2" loading="lazy" src="assets/images/icon/arrow-right.svg" class="w-30-md h-30-md" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ngx-slick-carousel class="carousel d-block d-md-none"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let item of items" class="slide p-2-xs">
        <div class="border-blue text-blue p-30-xs b-r-16-xs">
          <div class="row">
            <span class="col-12 text-24-xs align-items-start mb-16-xs">{{item['title']}}</span>

            <div class="col-12 align-self-end">
              <div class="row align-items-end">
                <span class="col-12 text-16-xs">{{item['description']}}</span>
                <span class="col-12 text-48-xs">{{item['percent']}}</span>
              </div>
            </div>

            <div class="col-12 align-self-center">
              <img alt="акция" loading="lazy" src="{{item['image']}}">
            </div>
            <div class="col-12 align-self-center text-start">
              <a href=""
                class="row text-decoration-none justify-content-end"
                role="button"
                (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
                <span class="col-auto align-self-center mr-10-xs">Подробнее</span>
                <img alt="акция 2" loading="lazy" src="assets/images/icon/arrow-right.svg" class="p-0-xs w-30-xs h-30-xs" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
