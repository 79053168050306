import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.less']
})

export class FaqItemComponent {
  @Input() item: {
    title: string;
    description: string;
  } | undefined

  public isCollapsed = true;
}
