import {Component, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-block-with-items',
  templateUrl: './block-with-items.component.html',
  styleUrls: ['./block-with-items.component.less']
})
export class BlockWithItemsComponent implements OnInit {
  @Input() title: string | undefined
  @Input() description: string | undefined
  @Input() class: string | undefined
  @Input() rowClass: string | undefined
  @Input() showMobileSlider: boolean | undefined
  @Input() items: {
    image: string,
    alt?: string | 'image',
    imageClass?: string,
    title: string,
    description: string,
    button?: {
      title: string,
      link: string
    } | null,
    class: string
  }[] | undefined


  @ViewChild("slickModal") slickModal: SlickCarouselComponent | undefined;

  isMobile: boolean = false;
  isLoaded = false;

  slideConfig = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          infinite: false,
          centerPadding: '40px',
        }
      }
    ]
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.getScreenSize()
  }

  ngOnInit(): void {

  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    if (this.isLoaded) {
      this.processSlick();
    }
  }

  ngAfterContentInit(): void {
    this.isLoaded = true;
    this.processSlick();
  }

  public processSlick(): void {
    if (this.isLoaded) {
      this.isMobile = Boolean(this.document && this.document.body?.offsetWidth < 1024);
      if (this.isMobile && this.slickModal && !this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
      if (!this.isMobile && this.slickModal && this.slickModal.initialized) {
        this.slickModal.unslick()
      }
    }
  }
}

