import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-transportation-for-businesses',
  templateUrl: './transportation-for-businesses.component.html',
  styleUrls: ['./transportation-for-businesses.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TransportationForBusinessesPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки для юридических лиц'
    }]
  }

  description="Ищете партнера по грузоперевозкам? Компания «АКРО» предлагает отличные условия сотрудничества и комфортные цены юридическим лицам, представляющим крупный, средний и малый бизнес. <br><br> Отличные тарифы на автомобильные перевозки и широкий спектр услуг нашей компании позволяют не только изучить наиболее подходящий маршрут для каждого направления, но и сэкономить ваши денежные и временные ресурсы."

  public services = [{
    items: [{
      class: "",
      title: "Тарифы на перевозки <br> по Москве и области",
      image: "assets/images/services/service-1.svg",
      imageClass: '',
      link: "/gruzoperevozki-po-moskve-i-mo",
      alt: "Перевозки по москве"
    }]
  },{
    items: [{
      class: 'mb-10-lg',
      title: "Доставка <br> на Яндекс <br> Маркет",
      image: "assets/images/services/service-2.svg",
      imageClass: 'pt-10-lg float-end',
      link: "/marketplejsy/yandex-market",
      alt: "Доставка на яндекс маркет"
    },{
      class: 'mt-10-lg',
      title: "Доставка <br> на Wildberries",
      image: "assets/images/services/service-3.svg",
      imageClass: 'pt-50-lg float-end',
      link: "/marketplejsy/wildberries",
      alt: "Доставка на вайлдберрис"
    }]
  },{
    items: [{
      class: 'mb-10-lg',
      title: "Рефрижераторные <br> перевозки",
      image: "assets/images/services/service-4.svg",
      imageClass: 'pt-10-lg float-end',
      link: "/avtopark/refrizheratornye-perevozki",
      alt: "Рефрижераторные перевозки"
    },{
      class: 'mt-10-lg',
      title: "Доставка <br> на OZON",
      image: "assets/images/services/service-5.svg",
      imageClass: 'float-end',
      link: "/marketplejsy/ozon",
      alt: "Доставка на озон"
    }]
  },{
    items: [{
      class: 'mb-10-lg',
      title: "Услуги <br> спецтехники",
      image: "assets/images/services/service-6.svg",
      imageClass: 'pt-60-lg float-end',
      link: "/manipulyatory",
      alt: "Услуги спецтехники"
    },{
      class: 'mt-10-lg',
      title: "Перевозки <br> по России",
      image: "assets/images/services/service-7.svg",
      imageClass: 'float-end',
      link: "/perevozki-po-rossii",
      alt: "Перевозки по россии"
    }]
  }]

  blockWithItems = {
    title: 'Почему выбирают нас',
    description: '',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-12.svg',
      alt: "Поминутная тарификация",
      imageClass: 'mb-0-lg ml-10-lg',
      title: '',
      description: 'Поминутная тарификация',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-11.svg',
      alt: "Большой опыт работы в перевозках",
      imageClass: 'mb-0-lg ml-10-lg',
      title: '',
      description: 'Большой опыт работы в перевозках',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-10.svg',
      alt: "Личный кабинет",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Личный кабинет',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-9.svg',
      alt: "Квалифицированные водители",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Квалифицированные водители',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-8.svg',
      alt: "Срочная перевозка грузов",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Срочная перевозка грузов',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-7.svg',
      alt: "Гарантия сохранности груза",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Гарантия сохранности груза',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-6.svg',
      alt: "Персональный менеджер",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Персональный менеджер',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-5.svg',
      alt: "Индивидуальный подход",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Индивидуальный подход',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-4.svg',
      alt: "Работаем по всей стране",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Работаем по всей стране',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-3.svg',
      alt: "Большой выбор автотранспорта",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Большой выбор автотранспорта',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-2.svg',
      alt: "Налаженный документооборот",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Налаженный документооборот',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    },{
      image: '/assets/images/transportation-for-businesses/transportation-for-businesses-1.svg',
      alt: "Постоплата с первых дней работы",
      imageClass: 'mb-0-lg ml-10-lg ',
      title: '',
      description: 'Постоплата с первых дней работы',
      class: 'bg-purple border-grey3 p-20-xs d-flex flex-row-reverse justify-content-between align-items-center'
    }]
  }

  carActive   = 0;
  typeActive  = 0;

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3260 ₽',
          ndsPrice: '3750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '5868 ₽',
          ndsPrice: '6750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '20 ₽/км',
        ndsCheckout: '23 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '3475 ₽',
          ndsPrice: '4000 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6255 ₽',
          ndsPrice: '7200 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4590 ₽',
          ndsPrice: '5280 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6885 ₽',
          ndsPrice: '7920 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '5100 ₽',
          ndsPrice: '5880 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7650 ₽',
          ndsPrice: '8820 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '30 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '750 ₽/950 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '7000 ₽',
          ndsPrice: '8050 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1150 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '30 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '800 ₽/1000 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '8320 ₽',
          ndsPrice: '9600 ₽',
          advPrice: '<span class="d-block">1040 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '37 ₽/км',
        pass: '870 ₽/1050 ₽/1400 ₽',
        ndsPass: '1000 ₽/1200 ₽/1600 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '9760 ₽',
          ndsPrice: '11200 ₽',
          advPrice: '<span class="d-block">1220 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '48 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽ /1750 ₽ /2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1750 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '53 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  },{
    title: 'Бортовой',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета',
        title: '<span class="d-block">Бизнес</span> <span class="d-block">Включено 70 км по Москве</span>',
        business: {
          hour: 4,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 2',
        title: '<span class="d-block">Бизнес+</span> <span class="d-block">Включено 100 км по Москве</span>',
        business: {
          hour: 4,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  },{
    title: 'Манипулятор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        businessPlus: {
          hour: 9,
          price: '',
          ndsPrice: '',
          advPrice: '',
          ndsAdvPrice: ''
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4980 ₽',
          ndsPrice: '5700 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7470 ₽',
          ndsPrice: '8550 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '28 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: '6090 ₽',
          ndsPrice: '7000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7830 ₽',
          ndsPrice: '9000 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '35 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: '7350 ₽',
          ndsPrice: '8400 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '9450 ₽',
          ndsPrice: '10800 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '750 ₽/950 ₽'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: '12600 ₽',
          ndsPrice: '14400 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '50 ₽/км',
        pass: '870 ₽/1050 ₽/1400',
        ndsPass: '1000 ₽/1200 ₽/1600'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: '14130 ₽',
          ndsPrice: '16200 ₽',
          advPrice: '<span class="d-block">1570 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: '16650 ₽',
          ndsPrice: '18900 ₽',
          advPrice: '<span class="d-block">1850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '56 ₽/км',
        ndsCheckout: '65 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  }]

  tags = {
    title: 'Автомобильные грузоперевозки',
    class: '',
    items: [{
      class: '',
      title: '',
      children: [{
        title: 'Перевозка рефрижераторами',
        link: ''
      },{
        title: 'Перевозка малогабаритных грузов',
        link: ''
      },{
        title: 'Дешевая грузоперевозка',
        link: ''
      },{
        title: 'Перевозка с температурным режимом',
        link: ''
      },{
        title: 'Перевозка автозапчастей',
        link: ''
      },{
        title: 'Перевозка бытовой химии',
        link: ''
      },{
        title: 'Перевозка негабаритных грузов',
        link: ''
      },{
        title: 'Грузоперевозка отдельной машиной',
        link: ''
      },{
        title: 'Перевозка ценных вещей',
        link: ''
      },{
        title: 'Мультимодальная перевозка',
        link: ''
      },{
        title: 'Междугородние грузоперевозки',
        link: ''
      },{
        title: 'Перевозка бытовок',
        link: ''
      },{
        title: 'Быстрая грузоперевозка',
        link: ''
      },{
        title: 'Перевозка сборных грузов',
        link: ''
      },{
        title: 'Контейнерная перевозка',
        link: ''
      },{
        title: 'Перевозка хрупких грузов',
        link: ''
      },{
        title: 'Коммерческая перевозка',
        link: ''
      },{
        title: 'Срочная перевозка',
        link: ''
      },{
        title: 'Грузоперевозки из Москвы',
        link: ''
      },{
        title: 'Погрузка и разгрузка',
        link: ''
      },{
        title: 'Перевозка медикаментов',
        link: ''
      },{
        title: 'Перевозка офисных вещей',
        link: ''
      },{
        title: 'Перевозка продуктов питания',
        link: ''
      },{
        title: 'Квартирный переезд',
        link: ''
      },{
        title: 'Грузоперевозки с грузчиками',
        link: ''
      },{
        title: 'Грузоперевозки и погрузочно-разгрузочные работы',
        link: ''
      },{
        title: 'Грузоперевозки 3 тонны',
        link: ''
      },{
        title: 'Счет на грузоперевозки',
        link: ''
      },{
        title: 'Транспортно-логистическая перевозка',
        link: ''
      },{
        title: 'Грузоперевозки с гидробортом',
        link: ''
      },{
        title: 'Перевозки спецтехники',
        link: ''
      },{
        title: 'Международные перевозки',
        link: ''
      },{
        title: 'Грузоперевозки больших объемов',
        link: ''
      },{
        title: 'Логистическая перевозка',
        link: ''
      },{
        title: 'Перевозка под ключ',
        link: ''
      }]
    }]
  };

  faq = [{
    title: "Как зарегистрироваться в личном кабинете?",
    description: "Главная страница <a href='https://tk-akro.ru/'>https://tk-akro.ru/</a> содержит ссылку «Личный кабинет», нажимая на которую, вы переходите в форму и заполняете обязательные поля, даете разрешение на хранение и обработку персональных данных и нажимаете кнопку «зарегистрироваться». После этого вам будут доступны различные преимущества нашего сервиса, в том числе онлайн отслеживание груза и калькулятор. Обратите внимание, что заполняя онлайн -форму, вы принимаете нашу политику конфиденциальности. Также у нас есть полезная информация о мире грузоперевозок, включая актуальные новости."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Офис ООО «ТК АКРО»  расположен по адресу: город Москва, ул.Щёлковское ш., дом 2а. Наше точное местоположение легко найти на карте, а также там есть схема проезда! Рабочее время: с понедельника по пятницу с 9:00 до 18:00, выходной сб и вс.  Наши контакты: телефон 8 (499) 350-90-02, круглосуточно можно писать на электронную почту <a href='mailto:info@tk-akro.ru'>info@tk-akro.ru</a> - ни одно письмо не останется без внимания.  Свяжитесь с нами любым удобным для вас способом!"
  }, {
    title: "Оказываете ли вы услуги частным лицам?",
    description: "Мы организуем грузовое такси по городу Москва от центра до периферии на авто с мелким, средним или большим типом кузова, услуги спецтехники, услуги грузчиков (в зависимости от габаритов груза, его массы и объёма грузоперевозки, разгрузку и доставку до двери может осуществлять водитель-экспедитор или бригада грузчиков)."
  }, {
    title: "Сколько стоят ваши услуги?",
    description: "Прайс лист грузоперевозки зависит от многих факторов (типа автомобиля, особенности груза и его параметров, расстояния и т.д.). Например, минимальная стоимость перевозки автомобилем с грузоподъемностью до 1,5 тонн - от 3350 руб за 4 часа. Со всеми тарифами вы можете ознакомиться в соответствующем разделе."
  }]

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Грузоперевозки для юридических лиц в Москве и области с ндс' });
    this.meta.addTag({ property: 'og:description', content: 'Грузоперевозки для юридических лиц ✅ Личный кабинет ✅ Гарантия ✅ Срочная перевозка грузов ✅ Постоплата ✅ Звоните +7(499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: 'Грузоперевозки для юридических лиц ✅ Личный кабинет ✅ Гарантия ✅ Срочная перевозка грузов ✅ Постоплата ✅ Звоните +7(499) 350-90-02' });
    this.titleService.setTitle('Грузоперевозки для юридических лиц в Москве и области с ндс');
  }
}
