<div class="pt-50-xs pb-50-xs pr-20-xs pr-0-lg pl-20-xs pl-0-lg"
     *ngIf="(!isMobile && showMobileSlider) || (isMobile && !showMobileSlider)"> <!-- pb-50-xs pb-60-lg -->
  <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs" *ngIf="title" [innerHTML]="title"></h2>
  <p class="text-24-xs mb-40-xs text-grey9" *ngIf="description" [innerHTML]="description"></p>

  <div class="row text-grey4" [ngClass]="rowClass ?? ''">
    <div class="mb-20-xs" [ngClass]="class" *ngFor="let item of items">
      <div class="b-r-16-xs height-to-full position-relative" [ngClass]="item['class'] ? item['class'] : 'p-20-xs'">
        <img alt="{{item['alt']}}" src="{{item['image']}}" *ngIf="item['image']" [ngClass]="item['imageClass'] ? item['imageClass'] : 'mb-36-xs'">
        <h3 class="text-24-xs mb-16-xs" [innerHTML]="item['title']" *ngIf="item['title']"></h3>
        <p class="text-18-xs p-0-xs m-0-xs" [innerHTML]="item['description']" *ngIf="item['description']"></p>
        <div class="d-block mt-20-xs" *ngIf="item['button']">
          <a href="{{item['button']['link']}}" class="text-16-xs border-blue d-block pt-6-xs pb-6-xs pr-30-xs pl-30-xs b-r-16-xs text-decoration-none">
            {{item['button']['title']}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pt-50-xs pb-50-xs" *ngIf="isMobile && showMobileSlider">
  <h2 class="text-48-lg mb-40-xs text-grey4 pr-20-xs pr-0-lg pl-20-xs pl-0-lg" *ngIf="title" [innerHTML]="title"></h2>
  <p class="text-24-xs mb-30-xs text-grey9 pr-20-xs pr-0-lg pl-20-xs pl-0-lg" *ngIf="description" [innerHTML]="description"></p>

  <ngx-slick-carousel class="carousel d-block d-lg-none p-0-xs"
                      #slickModal="slick-carousel"
                      [config]="slideConfig"
                      *ngIf="isMobile">

    <div ngxSlickItem class="slide p-10-xs" *ngFor="let item of items">
          <div class="b-r-16-xs height-to-full position-relative" [ngClass]="item['class'] ? item['class'] : 'p-20-xs'">
            <img alt="{{item['alt']}}" src="{{item['image']}}" *ngIf="item['image']" [ngClass]="item['imageClass'] ? item['imageClass'] : 'mb-36-xs mx-auto'">
            <h3 class="text-24-xs mb-16-xs" [innerHTML]="item['title']" *ngIf="item['title']"></h3>
            <p class="text-18-xs p-0-xs m-0-xs" [innerHTML]="item['description']" *ngIf="item['description']"></p>
          </div>
    </div>
  </ngx-slick-carousel>
</div>
