<div class="container pb-50-xs pb-100-lg pt-80-lg mt-20-xs mt-0-lg mb-40-xs border-bottom">
  <div class="row align-items-center text-lg-start flex-lg-nowrap position-relative pr-30-xs pr-0-lg pl-30-xs pl-0-lg" style="min-height: 300px;">
    <div class="col-12 col-lg-6 left-0 align-items-center p-0-xs pb-30-xs pb-0-lg px-15-lg z-100-xs">
      <h1 class="mb-20-xs mb-24-lg text-blue text-40-xs text-56-lg" *ngIf="title" [innerHTML]="title"></h1>
      <p class="mb-36-xs mb-44-lg text-18-xs text-20-lg" *ngIf="description" [innerHTML]="description"></p>

      <div>
        <a href=""
           role="button"
           *ngIf="showButtonCall"
           (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)"
           class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
          Заказать звонок
        </a>
        <a href=""
           role="button"
           *ngIf="showButtonKp"
           (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.KP)"
           class="btn text-18-lg text-blue col-auto">
          Получить КП
        </a>
        <a href=""
           role="button"
           *ngIf="showButtonDriverJob"
           (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.DRIVER_JOB)"
           class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
          Хочу с вами работать
        </a>
      </div>
    </div>
    <ng-container *ngIf="image">
      <div class="col-12 d-sm-flex justify-content-sm-center d-block col-lg-6 p-0-xs">
        <img loading="eager" src="{{image}}" alt="изображение в заголовке"/>
      </div>
    </ng-container>
  </div>
</div>
