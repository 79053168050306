<div class="overlay" [class.show]="popupService.show[popupService.PopupEnum.KP]" (click)="popupService.closePopups()"></div>
<div class="position-fixed popup p-30-xs b-r-16-xs bg-purple border-grey3" [class.show]="popupService.show[popupService.PopupEnum.KP]">
  <h3 class="mb-25 text-24-xs text-grey4 p-0-xs mb-16-xs">Получить коммерческое предложение</h3>
  <p class="mb-25 text-16-xs text-grey4 p-0-xs mb-24-xs">Заполните данные, что бы мы могли отправить коммерческое предложение на вашу почту.</p>

  <form [formGroup]="kpForm">
    <input formControlName="name" type="text"
           placeholder="Ваше имя"
           class="text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
    <div [class.mtm10]="kpForm.get('name')?.invalid" *ngIf="kpForm.get('name')?.invalid && kpForm.get('name')?.dirty || kpForm.get('name')?.touched">
      <small *ngIf="kpForm.get('name')?.errors?.['required']" class="text-danger">Не указано имя</small>
    </div>
    <input formControlName="phone" type="text"
           placeholder="Номер телефона" prefix="+7" mask="(000) 000 00 00"
           class="text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
    <div [class.mtm10]="kpForm.get('phone')?.invalid" *ngIf="kpForm.get('phone')?.invalid && kpForm.get('phone')?.dirty || kpForm.get('phone')?.touched">
      <small *ngIf="kpForm.get('phone')?.errors?.['required']" class="text-danger">Не указан номер телефона</small>
      <small *ngIf="kpForm.get('phone')?.errors?.['wrongNumber']" class="text-danger">Номер телефона указан неверно</small>
    </div>
    <input formControlName="email" type="email"
           placeholder="Ваша почта"
           class="text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
    <div [class.mtm10]="kpForm.get('email')?.invalid" *ngIf="kpForm.get('email')?.invalid && kpForm.get('email')?.dirty || kpForm.get('email')?.touched">
      <small *ngIf="kpForm.get('email')?.errors?.['required']" class="text-danger">E-mail не введен</small>
      <small *ngIf="kpForm.get('email')?.errors?.['email']" class="text-danger">Указан неверный e-mail</small>
    </div>

    <div class="row checkbox text-gray text-10-xs mb-24-xs">
      <div class="col-auto pr-8-xs">
        <input formControlName="privacy" id="privacy-kp" type="checkbox" [checked]="false">
        <label for="privacy-kp" class="checkmark"></label>
      </div>

      <span [class.text-danger]="kpForm.get('privacy')?.invalid && kpForm.get('privacy')?.touched" (click)="kpForm.get('privacy')!.setValue(!kpForm.get('privacy')!.value)" class="col pl-0-xs unselectable">
        Я подтверждаю ознакомление с политикой обработки персональных данных, в том числе с применением технологии SmartCaptcha
        и даю согласие на обработку моих данных в порядке и на условиях, указанных в Политике.
      </span>
    </div>
    <div #submitBtn>
      <button [class.bg-success]="isSended" [class.bg-blue]="isLoading || !isSended" type="button" [disabled]="!kpForm.valid || isLoading || isSended" (click)="onSubmit()" class="text-white b-r-8-xs text-16-xs p-10-xs unselectable">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{isLoading ? 'Отправка' : ''}}
        {{isSended ? 'Отправлено' : ''}}
        {{!isLoading && !isSended ? 'Отправить' : ''}}
      </button>
    </div>
  </form>
</div>
