<div class="container pt-50-xs pt-36-lg pb-20-xs pb-20-lg">
    <div class="row text-lg-start pr-30-xs pr-0-lg pl-30-xs pl-0-lg" style="margin: auto;">
      <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs px-15-lg">Автопарк</h2>
  
      <div class="col-12 col-lg-4 pr-0-xs pl-0-xs pr-6-lg pb-20-md" *ngFor="let column of columns">
          <div class="bg-purple mb-20-xs pl-0-xs p-20-xs pr-20-lg pl-20-lg pt-10-lg pb-10-lg b-r-16-xs border-grey3">
            <div class="row justify-content-between flex-lg-nowrap border-bottom pb-8-lg pb-16-xs mb-30-xs mb-lg-3">
              <div class="mb-25-lg col-auto align-self-center">
                <h3 class="text-grey4 text-24-lg mb-0">{{column['title']}}</h3>
              </div>
              <div class="mb-25-lg col-4 col-sm-2 col-lg-4">
                <img loading="lazy" [src]="column['image']" alt="{{column['alt']}}" />
              </div>
            </div>
            <div class="row justify-content-between mb-10-lg">
              <div class="col-6">
                <span class="text-grey5 text-16-lg">Стоимость</span>
                <br>
                <span class="text-18-lg">{{column['price']}}</span>
              </div>
              <div class="col-6">
                <span class="text-grey5 text-16-lg">Дополнительный час</span>
                <br>
                <span class="text-18-lg d-block">{{column['extra_hour']}}</span>
              </div>
            </div>
            <div class="row justify-content-between mb-10-lg">
                <div class="col-6">
                  <span class="text-grey5 text-16-lg">Включено</span>
                  <br>
                  <span class="text-18-lg">{{column['include']}}</span>
                </div>
                <div class="col-6">
                  <span class="text-grey5 text-16-lg">Перепробег</span>
                  <br>
                  <span class="text-18-lg d-block">{{column['overrun']}}</span>
                </div>
            </div>
            <div class="row justify-content-between mb-10-lg">
                <div class="col-6">
                  <span class="text-grey5 text-16-lg">Помощь водителя</span>
                  <br>
                  <span class="text-18-lg">{{column['driver_assistance']}}</span>
                </div>
                <div class="col-6">
                  <span class="text-grey5 text-16-lg">Размер палеты</span>
                  <br>
                  <span class="text-18-lg d-block">{{column['number_europallets']}}</span>
                </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 align-self-center">
                <a href="{{column['link']}}" class="btn d-block border-blue-hover bg-blue b-r-8-xs text-16-lg text-white text-blue-hover text-decoration-none mt-30-xs mb-10-xs pt-10-xs pb-10-xs pr-28-xs pl-28-xs">Заказать машину</a>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
  