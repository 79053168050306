<app-banner
  [image]="'/assets/images/banner/banner-shipping-on-ozon.svg'"
  [title]="'Доставка до <br>маркетплейса <br>Озон'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [typeActive]="typeActive"
      [typeTitle]="typeTitle"
      [tariffs]="tariffs"
      [header]="header"
    >
    </app-tariff>
  </div>

  <app-block-description-page [description]="description"></app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="row pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg text-grey9">
    <div class="col-12 col-lg mb-20-xs mb-0-lg">
      <p>Наши водители имеют большой опыт по доставке на маркетплейсы, в частности на Ozon. Вы можете не сомневаться в выполнении всех норм:</p>
    </div>
    <div class="col-12 col-lg">
      <p>Подробнее о требованиях маркетплейса вы можете прочитать в регламенте поставок на озон.</p>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg text-grey9">
    <div class="col-12 col-lg mb-20-xs mb-0-lg"><p>Подробнее об особенностях маркетплейса вы можете прочитать в регламенте поставок на Ozon.</p></div>
    <div class="col-12 col-lg mb-20-xs mb-0-lg">
      <p>Для того, чтобы при приемке поставки на маркетплейс не возникло проблем, груз необходимо упаковать в соответствии с условиям, определенными в правилах работы с Озон. Упаковка должна обеспечивать полную сохранность груза при транспортировке и хранении на складе, также без повреждений и следов сырости. В противном случае склад может не принять такой товар на хранение. При возникновении подобных ситуаций наши сотрудники делают все возможное, чтобы груз был принят на склад, либо оперативно связываются с вами для решения вопроса. При невозможности его решения на месте, мы можем осуществить возврат.
      </p>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg text-grey9">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Правила упаковки</h2>
    <div class="col-12 col-lg mb-20-xs mb-0-lg">
      <p>Товары необходимо аккуратно сложить на поддон (допускается укладка груза в транспортную коробку), при этом на каждой из паллет обязательно должен быть информационный ярлык, распечатанный из личного кабинета. <br><br> Чтобы получить более подробную консультацию по доставке до маркетплейса OZON, его хранении, вы можете заполнить заявку, и наши менеджеры свяжутся с вами в самое ближайшее время. Также ориентировочные сроки и стоимость доставки вы можете узнать по телефону 8 (499) 350-90-02. <br><br> Мы берем на себя полную ответственность и контролируем все шаги от загрузки до разгрузки на Озон. Сотрудничая с нами, вы получаете отличный сервис и высокое качество услуг, о чем свидетельствуют отзывы наших довольных клиентов!
      </p>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как мы работаем</h2>
    <div class="row text-grey9">
      <div class="col-12 col-lg mb-20-xs mb-0-lg">
        <p class="text-20-xs pb-20-xs">Сотрудничество с ТК «Акро» — это просто и удобно. Оформляется оно в несколько шагов:</p>
      </div>
      <div class="col-12 col-lg">
        <p class="text-16-xs"></p>
      </div>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">создание заявки на доставку груза (можно заказать обратный звонок или использовать личный кабинет) - не забудьте указать ваш номер телефона и электронную почту, а также адрес вашего склада или производства;</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-ozon/shipping-on-ozon-4.svg" class="margin-auto-lg" alt="Создание заявки">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">подтверждение заявки и согласование информации (дата доставки, время и т.д.) - менеджер перезвонит вам в течение двух минут, озвучит цену и заключит с вами договор. Ему нужно предоставить информацию о маршруте и грузе (вес в кг и число коробок, габариты и т.д.);</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-ozon/shipping-on-ozon-5.svg" class="margin-auto-lg" alt="Подтверждение заявки">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">забор груза в подходящее для вас время - мы можем сделать это заблаговременно или непосредственно в момент оформления заявки: учитывайте, что подтверждение отправителем слота бронирования на портале Озон выполняется за 16 часов до доставки, а за 3 часа до поставки - внесение данных на автомобиль и водителя. При необходимости мы сами упакуем коробки на паллетах и поможем погрузить упакованную продукцию в автомобиль;</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-ozon/shipping-on-ozon-6.svg" class="margin-auto-lg" alt="Забор груза">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">затем мы отвозим груз в распределительный центр Ozon, где посылки сортируют и распределяют для отправки в пункты выдачи, курьерскую службу или на склад.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-ozon/shipping-on-ozon-7.svg" class="margin-auto-lg" alt="Сортировка">
          </div>
        </div>
      </div>
    </div>
    <p class="text-20-xs pt-30-xs">Мы можем обеспечить бесплатное страхование ценных и дорогостоящих товаров.</p>
  </div>

  <app-block-with-items
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <app-block-with-items
    [title]="blockWithItems4['title']"
    [description]="blockWithItems4['description']"
    [class]="blockWithItems4['class']"
    [items]="blockWithItems4['items']">
  </app-block-with-items>
  <p class="text-18-xs">Большое разнообразие транспортных средств для аренды: доставляем моно- и микс-паллеты, малогабаритный и крупногабаритный груз от 1 кг до 20 тонн. Вы можете выбрать например тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши менеджеры всегда помогут с выбором.</p>

  <app-block-description-page [description]="description2"></app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems5['title']"
    [description]="blockWithItems5['description']"
    [class]="blockWithItems5['class']"
    [items]="blockWithItems5['items']">
  </app-block-with-items>

  <app-block-description-page [description]="description3"></app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg">
    <app-tags [title]="tags['title']" [items]="tags['items']"></app-tags>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>

</div>
