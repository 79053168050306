<app-banner
  [image]="'/assets/images/banner/banner-news.svg'"
  [title]="'Новости'"
  [showButtonCall]="false"
  [showButtonKp]="false"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container pt-100-xs pt-60-lg">
  <div class="d-none d-lg-block">
    <div class="row">
      <div class="col pr-30-xs" *ngFor="let item of items">
        <div class="row b-r-16-xs border-grey height-to-full align-content-start position-relative">
          <div class="image p-0-xs">
            <img loading="lazy" src="{{item['image']}}" class="w-100">
          </div>
          <div class="p-20-xs pb-70-xs">
            <div class="text-16-xs text-grey mb-16-xs">
              {{item['date']}}
            </div>
            <div class="text-20-xs text-blue mb-8-xs">
              {{item['title']}}
            </div>
            <div class="text-16-xs text-grey4 mb-48-xs">
              {{item['descriptions']}}
            </div>
            <a href="{{item['link']}}" class="d-table text-decoration-none border-blue b-r-8-xs text-18-xs pt-12-xs pb-12-xs pr-30-xs pl-30-xs position-absolute bottom-20-xs">
              Подробнее
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-slick-carousel class="carousel d-block d-lg-none pr-20-xs pr-0-lg pl-20-xs pr-0-lg"
                      #slickModal="slick-carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let item of items" class="slide pr-12-xs pl-12-xs">
      <div class="row b-r-16-xs border-grey height-to-full align-content-start position-relative">
        <div class="image p-0-xs">
          <img loading="lazy" src="{{item['image']}}" class="w-100">
        </div>
        <div class="p-20-xs pb-70-xs">
          <div class="text-16-xs text-grey mb-16-xs">
            {{item['date']}}
          </div>
          <div class="text-20-xs text-blue mb-8-xs">
            {{item['title']}}
          </div>
          <div class="text-16-xs text-grey4 mb-48-xs">
            {{item['descriptions']}}
          </div>
          <a href="{{item['link']}}" class="d-table text-decoration-none border-blue b-r-8-xs text-18-xs pt-12-xs pb-12-xs pr-30-xs pl-30-xs position-absolute bottom-20-xs">
            Подробнее
          </a>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
