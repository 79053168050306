<div class="pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
  <h2 *ngIf="title" [innerHTML]="title" class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs"></h2>
  <p *ngIf="description" [innerHTML]="description" class="text-16-xs mb-48-xs text-grey9"></p>
  <div class="row" [ngClass]="rowClass">
    <div class="text-grey4" [ngClass]="item.class" *ngFor="let item of items">
      <div class="mb-20-xs" [ngClass]="subitem.class" *ngFor="let subitem of item.subitems">
        <div class="b-r-16-xs height-to-full position-relative row flex-column">
          <img *ngIf="subitem['image']" [ngClass]="subitem['imageClass'] ? subitem['imageClass'] : 'mb-36-xs'"
               src="{{subitem['image']}}">
          <h3 class="text-24-xs mt-10-xs mb-30-xs" [innerHTML]="subitem['title']" *ngIf="subitem['title']"></h3>
          <p *ngIf="subitem['description']" [innerHTML]="subitem['description']"
             [ngClass]="subitem['descriptionClass']" class="text-18-xs p-0-xs m-0-xs pl-15-xs pr-15-xs"></p>
          <div class="d-block mt-20-xs" *ngIf="subitem['button']">
            <a class="text-16-xs border-blue pt-6-xs pb-6-xs pr-30-xs pl-30-xs b-r-16-xs text-decoration-none"
               href="{{subitem['button']['link']}}">
              {{subitem['button']['title']}}
            </a>
          </div>
        </div>
      </div>
    </div>
</div>
  </div>
