import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {fromEvent, tap} from 'rxjs';
import {PopupService} from 'src/app/popup.service';
import {PhoneNumberValidator} from 'src/app/validators/phone-number.validator';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {CategoryTypeEnum} from "../../enums/category.enum";
import {DriverJobClientApiService} from "../../api/client/driver-job/driver-job.client.api.service";
import {DriverJobClientApiInterface} from "../../api/client/driver-job/driver-job.client.api.interface";
import {DOCUMENT} from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'app-popup-job',
  templateUrl: './popup-job.component.html',
  styleUrls: ['./popup-job.component.less'],
})
export class PopupJobComponent {
  @ViewChild('submitBtn') public submitBtn!: ElementRef<HTMLButtonElement>;
  public isLoading: boolean = false;
  public isSended: boolean = false;
  public readonly CategoryTypeEnum = CategoryTypeEnum;
  public readonly jobForm = new FormGroup({
    name: new FormControl('', Validators.required),
    phone: new FormControl('',
      Validators.required,
      PhoneNumberValidator('RU')
    ),
    category: new FormControl<null | CategoryTypeEnum | "null">(null),
    hasOwnCar: new FormControl<boolean>(false),
    privacy: new FormControl<boolean>(false, Validators.requiredTrue),
  });

  constructor(
    public readonly popupService: PopupService,
    private readonly driverJobClientApiService: DriverJobClientApiService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  ngAfterViewInit(): void {
    fromEvent(this.submitBtn.nativeElement, 'click').pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.jobForm.valid) {
        this.jobForm.markAllAsTouched();
      }
    })
  }

  public onSubmit(): void {
    this.isLoading = true;
    if (this.jobForm.get('category')!.value! === 'null') {
      this.jobForm.get('category')!.setValue(null)
    }

    const data: DriverJobClientApiInterface = {
      name: this.jobForm.get('name')!.value!.charAt(0).toUpperCase()
        + this.jobForm.get('name')!.value!.slice(1),
      phone: '+7' + this.jobForm.get('phone')!.value!,
      hasOwnCar: this.jobForm.get('hasOwnCar')!.value!,
      category: this.jobForm.get('category')!.value! as null | CategoryTypeEnum,
      token: '',
    }
    // @ts-ignore
    const smartCaptcha = this.document["smartCaptcha"];
    // @ts-ignore
    const captchaWidgetId = this.document["containerJobWidget"];
    smartCaptcha.subscribe(captchaWidgetId, 'success', (token: string) => {
      this.driverJobClientApiService.handle({...data, token}).pipe(
        untilDestroyed(this),
        tap((_) => this.isLoading = false),
        tap((_) => this.isSended = true),
      ).subscribe()
    })
    smartCaptcha.subscribe(captchaWidgetId, 'challenge-hidden', () => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    })
    smartCaptcha.subscribe(captchaWidgetId, 'network-error', () => {
      this.isLoading = false;
    })
    // @ts-ignore
    smartCaptcha.execute(captchaWidgetId);
  }

  public setDefaults(): void {
    this.jobForm.get('name')!.setValue('');
    this.jobForm.get('phone')!.setValue('');
    this.jobForm.get('hasOwnCar')!.setValue(false);
    this.jobForm.get('privacy')!.setValue(false)
    this.jobForm.get('category')!.setValue(null);
    this.jobForm.get('name')!.markAsUntouched();
    this.jobForm.get('phone')!.markAsUntouched();
    this.jobForm.get('privacy')!.markAsUntouched();
    this.jobForm.get('hasOwnCar')!.markAsUntouched();
    this.jobForm.get('category')!.markAsUntouched();
    this.jobForm.get('name')!.markAsPristine()
    this.jobForm.get('phone')!.markAsPristine();
    this.jobForm.get('privacy')!.markAsPristine();
    this.jobForm.get('hasOwnCar')!.markAsPristine();
    this.jobForm.get('category')!.markAsPristine();
    this.isLoading = false;
    this.isSended = false;
  }
}
