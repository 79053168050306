    <div class="row justify-content-end align-items-center position-relative pr-30-xs pr-0-lg pl-30-xs pl-0-lg container pt-20-xs pt-50-lg pb-50-xs pb-50-lg">
      <div class="col-12 col-lg-6 p-0-xs" [ngClass]="descriptionClass">
        <h2 class="text-30-xs text-48-lg mb-36-xs d-block text-grey4" *ngIf="title" [innerHTML]="title"></h2>
        <p class="text-18-xs" *ngIf="description" [innerHTML]="description"></p>

        <div>
          <a href=""
          role="button"
          *ngIf="showButtonCall"
          (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)"
             class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
            Заказать перевозку
          </a>
        </div>
      </div>
      <div [ngClass]="imageClass">
        <img loading="lazy" src="{{image}}" alt="{{alt}}" />
      </div>
    </div>
