<app-banner
  [image]="'/assets/images/banner/banner-trucking-bullock.svg'"
  [title]="'Грузоперевозки Бычок'"
  [description]="'По Москве и Московской области'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs"  alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-30-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        При наличии ежедневных отгрузок или постоянных маршрутов, мы подготовим индивидуальный прайс со скидкой для вас.
        <br>
        <br>
        Подробную информацию можно получить по номеру телефона +7(499)350-90-02
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg mb-20-xs">
        <h2 class="text-48-lg mb-32-xs text-grey4">Грузоперевозки на бычке</h2>
        <span class="text-18-xs">
          Одно из востребованных направлений нашей деятельности - перевозка грузов «бычком». Этот вид транспорта представляет собой грузовой автомобиль средней тоннажности, являясь промежуточным вариантом между каблуком
и фурой. В тех случаях, когда вместительности каблука или газели недостаточно, а аренда транспорта с большой грузоподъемностью нерентабельна, на помощь приходит зил «бычок».
        </span>
      </div>
      <div class="col-12 col-lg">
        <img loading="lazy" src="assets/images/trucking-heel/trucking-heel-1.svg" alt="Бычок">
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>


  <div class="row text-lg-start pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-30-xs pr-0-lg pl-30-xs pl-0-lg d-md-block d-none">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Особенности грузового автомобиля типа «бычок»</h2>

      <div class="row">
        <div class="col-12 col-lg pr-0-xs pr-50-lg">
            Среднетоннажный бычок ЗИЛ предназначен для перевозок не очень объемных грузов весом до 1 тонны. Этот тип авто отличается характеристиками:
            <br><br>
            грузоподъемностью до 3 тонн и вместимостью кузова до 10 европаллет (до 25 куб.м.);<br>
            небольшим радиусом разворота;<br>
            малой погрузочной высотой кузова;<br>
            оптимальной скоростью передвижения.
            <br><br>
            Ширина и высота вещей не должна превышать 2,2 метра, длина — 5 м. Благодаря этим особенностям, зил бычок является наиболее удобным вариантом, когда требуется перевезти небольшие по массе (кг) партии товара, имеющие маленький объем.
            <br><br>
        </div>
      </div>

    <div class="row text-grey3 d-block-xs-lg ">
      <div class="col bg-purple b-r-16-xs mr-20-lg d-flex flex-column col-12-xs">
        <div class="row align-items-start text-gray3 p-20-xs">
          <div class="p-0-xs">
            <img loading="lazy" class="pb-20-lg" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Перевозка Бычок">
          </div>
          <p class="text-16-lg p-0-xs m-0-xs">Для частного пользования, когда нужно быстро перевезти тяжелую мебель
            с техникой в новое место, например, квартиру или дом</p>
        </div>
      </div>
      <div class="col mr-20-lg d-flex flex-column">
        <div class="row bg-purple b-r-16-xs mb-10-lg flex-fill align-items-start text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Строительных компаний и производителей мебели</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Строительных">
          </div>
        </div>
        <div class="row bg-purple b-r-16-xs mt-10-lg flex-fill align-items-start text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Сети торговых точек, продуктовых и бытовых магазинов</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Продуктов">
          </div>
        </div>
      </div>
      <div class="col mr-20-lg d-flex flex-column">
        <div class="row bg-purple b-r-16-xs mb-10-lg flex-fill align-items-start text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Компаний, занимающихся проведением выездных мероприятий</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Выездные мероприятия"></div>
        </div>
        <div class="row bg-purple b-r-16-xs mt-10-lg flex-fill align-items-start text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Галерей, музеев, выставочных центров</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Галерея"></div>
        </div>
      </div>
      <div class="col mr-20-lg d-flex flex-column">
        <div class="row bg-purple b-r-16-xs mb-10-lg flex-fill align-items-start text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Для физических и юридических лиц, которым необходимо осуществлять  офисные переезды</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Офисный переезд"></div>
        </div>
        <div class="row bg-purple b-r-16-xs mt-10-lg flex-fill align-items-start text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Доставки или перевозки оборудования</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="оборудование"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-lg-start pr-20-xs pr-0-lg pl-20-xs pl-0-lg d-md-none d-block">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Особенности грузового автомобиля типа «бычок»</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
          Среднетоннажный бычок ЗИЛ предназначен для перевозок не очень объемных грузов весом до 1 тонны. Этот тип авто отличается характеристиками:
          <br><br>
          грузоподъемностью до 3 тонн и вместимостью кузова до 10 европаллет (до 25 куб.м.);<br>
          небольшим радиусом разворота;<br>
          малой погрузочной высотой кузова;<br>
          оптимальной скоростью передвижения.
          <br><br>
          Ширина и высота вещей не должна превышать 2,2 метра, длина — 5 м. Благодаря этим особенностям, зил бычок является наиболее удобным вариантом, когда требуется перевезти небольшие по массе (кг) партии товара, имеющие маленький объем.
          <br><br>
      </div>
    </div>

    <div class="row text-grey3 px-20-xs">

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="text-18-lg p-0 m-0" style="width: 80%;">Для частного пользования, когда нужно быстро перевезти тяжелую мебель
            с техникой в новое место, например, квартиру или дом</p>
          <div class="col-auto" style="width: 20%;"><img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="квартира дом"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Строительных компаний и производителей мебели</p>
          <div class="col-auto"><img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Строительных компании"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Сети торговых точек, продуктовых и бытовых магазинов</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="продуктовых бытовых"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Компаний, занимающихся проведением выездных мероприятий</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Выездных мероприятий"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Галерей, музеев, выставочных центров</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Галерей"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Для физических и юридических лиц, которым необходимо осуществлять офисные переезды</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Офисный переезд"></div>
        </div>

      <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="text-18-lg p-0 m-0" style="width: 80%;">Доставки или перевозки оборудования</p>
          <div class="col-auto" style="width: 20%;"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Перевозка оборудования"></div>
        </div>
    </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-block-with-items
  [title]="blockWithItems3['title']"
  [description]="blockWithItems3['description']"
  [class]="blockWithItems3['class']"
  [items]="blockWithItems3['items']">
  </app-block-with-items>

  <app-block-with-items
  [title]="blockWithItems4['title']"
  [description]="blockWithItems4['description']"
  [class]="blockWithItems4['class']"
  [items]="blockWithItems4['items']">
  </app-block-with-items>

  <app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
