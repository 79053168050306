import {Component} from '@angular/core';
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-autopark',
  templateUrl: './autopark.component.html',
  styleUrls: ['./autopark.component.less'],
  providers: [NgbCarouselConfig],
})
export class AutoparkComponent {
  slides = [{
    title: "500кг / 2м³",
    min_price: "от 3350 Р",
    hours: "4 часов",
    price: "610 Р / в час",
    number_europallets: "1 шт",
    image: "/assets/images/auto-park/auto-park-1.svg",
    link: "/avtopark",
    alt: "авто 500кг"
  }, {
    title: "1 тонна / 8м³",
    min_price: "от 2750 Р",
    hours: "4 часов",
    price: "550 Р / в час",
    number_europallets: "4 шт",
    image: "/assets/images/auto-park/auto-park-2.svg",
    link: "/avtopark",
    alt: "авто 1т"
  },
  {
    title: "1.5 тонны / 12м³",
    min_price: "от 4170 Р",
    hours: "5 часов",
    price: "695 Р / в час",
    number_europallets: "4 шт",
    image: "/assets/images/auto-park/auto-park-4.svg",
    link: "/avtopark",
    alt: "авто 1.5т"
  },{
    title: "3 тонны / 18м³",
    min_price: "от 4680 Р",
    hours: "5 часов",
    price: "780 Р / в час",
    number_europallets: "7 шт",
    image: "/assets/images/auto-park/auto-park-5.svg",
    link: "/avtopark",
    alt: "авто 3т"
  },{
    title: "3 тонны / 25м³",
    min_price: "от 6090 Р",
    hours: "6 часов",
    price: "870 Р / в час",
    number_europallets: "10 шт",
    image: "/assets/images/auto-park/auto-park-6.svg",
    link: "/avtopark",
    alt: "авто 3т.25м"
  },{
    title: "5 тонн / 32м³",
    min_price: "от 7680 Р",
    hours: "7 часов",
    price: "960 Р / в час",
    number_europallets: "14 шт",
    image: "/assets/images/auto-park/auto-park-7.svg",
    link: "/avtopark",
    alt: "авто 5т"
  },{
    title: "10 тонн / 45м³",
    min_price: "от 10170 Р",
    hours: "8 часов",
    price: "1130 Р / в час",
    number_europallets: "14 шт",
    image: "/assets/images/auto-park/auto-park-8.svg",
    link: "/avtopark",
    alt: "авто 10т"
  },{
    title: "20 тонн / 82м³",
    min_price: "от 13320 Р",
    hours: "8 часов",
    price: "1480 Р / в час",
    number_europallets: "32 шт",
    image: "/assets/images/auto-park/auto-park-9.svg",
    link: "/avtopark",
    alt: "авто 20т"
  }]

  slideConfig = {
    dots: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
}
