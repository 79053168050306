import {ClientApiBaseService} from "../client.api.base.service";
import {Observable, publishReplay, refCount} from "rxjs";
import {Injectable} from "@angular/core";
import {OfferClientApiRequestInterface} from "./offer.client.api.interface";

@Injectable()
export class OfferClientApiService extends ClientApiBaseService {
  public handle(data: OfferClientApiRequestInterface): Observable<any> {
    const url = [this.apiUrl, 'api', 'landing', 'offer'].join('/');
    return this.http.post(url, data, {headers: this.defaultHeaders}).pipe(
      publishReplay(1), refCount()
    )
  }
}
