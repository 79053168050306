import {Component, Injectable, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import { Location } from '@angular/common';
import {debug} from "util";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'akro-angular';

    // @ts-ignore
  @Injectable({
    providedIn: 'root'
  })

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private location: Location,
    private meta: Meta,
  ) {
    const head = this.doc.head.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.head.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical');
    element.setAttribute('href',location.path() === '' ? this.doc.URL + 'gruzoperevozki-po-moskve-i-mo' : this.doc.URL)

    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:locale', content: 'ru_RU' });
    this.meta.addTag({ property: 'og:site_name', content: 'tk-akro' });
    this.meta.addTag({ property: 'og:url', content: location.path() === '' ? this.doc.URL + 'gruzoperevozki-po-moskve-i-mo' : this.doc.URL });
  }
}
