import {NgModule} from "@angular/core";
import {OfferClientApiService} from "./offer/offer.client.api.service";
import {CallbackClientApiService} from "./callback/callback.client.api.service";
import {DriverJobClientApiService} from "./driver-job/driver-job.client.api.service";

@NgModule({
  imports: [],
  providers: [OfferClientApiService, CallbackClientApiService, DriverJobClientApiService],
})
export class ClientApiModule {}
