import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NewsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Новости'
    }]
  }

  items = [{
    image: 'assets/images/news/news1.png',
    date: '15.03.2021',
    title: 'Порядок прибытия, разгрузки и убытия на складе OZON',
    descriptions: 'Если ТС прибыло заблаговременно, поставщик получит отказ в проезде на склад. Проехать станет возможно при наступлении времени отгрузки либо при освобождении ворот. При опоздании более, чем на шесть часов, поставщик получает отказ на выгрузку',
    link: ''
  },{
    image: 'assets/images/news/news1.png',
    date: '20.12.2020',
    title: 'Камеры на дорогах расставят по новым правилам. Что нужно знать?',
    descriptions: 'Кто и как раскрывает информацию о местах установки камер, будут ли обозначать знаками камеры-треноги, кто потребовал отменить штрафы с комплексов видеофиксации и где вообще можно',
    link: ''
  },{
    image: 'assets/images/news/news1.png',
    date: '20.12.2020',
    title: 'На трассе М-11 Москва — Санкт-Петербург открыт новый участок',
    descriptions: 'Во вторник, 3 сентября, состоялась церемония открытия нового участка скоростной платной трассы М-11 «Москва — Санкт-Петербург» протяженностью более 100 км: для проезда с 18:00 открыт',
    link: ''
  }]

  slideConfig = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false
  };

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'НОВОСТИ транспортной компании АКРО. Полезные статьи' });
    this.meta.addTag({ property: 'og:description', content: 'Новости и полезные статьи транспортной компании АКРО.✅ Доставка по Москве, МО, СПБ, России' });
    this.meta.addTag({ name: 'description', content: 'Новости и полезные статьи транспортной компании АКРО.✅ Доставка по Москве, МО, СПБ, России' });
    this.titleService.setTitle("НОВОСТИ транспортной компании АКРО. Полезные статьи");
  }
}
