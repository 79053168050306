import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-heel',
  templateUrl: './trucking-heel.component.html',
  styleUrls: ['./trucking-heel.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingHeelPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/avtopark',
      linkTitle: 'Автопарк'
    }, {
      link: '',
          linkTitle: 'Грузоперевозки каблук'
        }]
  }

  carActive   = 0;
  typeActive  = 0;
  header = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3260 ₽',
          ndsPrice: '3750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '5868 ₽',
          ndsPrice: '6750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '20 ₽/км',
        ndsCheckout: '23 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '3475 ₽',
          ndsPrice: '4000 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6255 ₽',
          ndsPrice: '7200 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4590 ₽',
          ndsPrice: '5280 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6885 ₽',
          ndsPrice: '7920 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '5100 ₽',
          ndsPrice: '5880 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7650 ₽',
          ndsPrice: '8820 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '30 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '750 ₽/950 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '7000 ₽',
          ndsPrice: '8050 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1150 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '30 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '800 ₽/1000 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '8320 ₽',
          ndsPrice: '9600 ₽',
          advPrice: '<span class="d-block">1040 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '37 ₽/км',
        pass: '870 ₽/1050 ₽/1400 ₽',
        ndsPass: '1000 ₽/1200 ₽/1600 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '9760 ₽',
          ndsPrice: '11200 ₽',
          advPrice: '<span class="d-block">1220 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '48 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽ /1750 ₽ /2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1750 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '53 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4980 ₽',
          ndsPrice: '5700 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7470 ₽',
          ndsPrice: '8550 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '28 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: '6090 ₽',
          ndsPrice: '7000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7830 ₽',
          ndsPrice: '9000 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '35 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: '7350 ₽',
          ndsPrice: '8400 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '9450 ₽',
          ndsPrice: '10800 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '750 ₽/950 ₽'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: '12600 ₽',
          ndsPrice: '14400 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '50 ₽/км',
        pass: '870 ₽/1050 ₽/1400',
        ndsPass: '1000 ₽/1200 ₽/1600'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: '14130 ₽',
          ndsPrice: '16200 ₽',
          advPrice: '<span class="d-block">1570 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: '16650 ₽',
          ndsPrice: '18900 ₽',
          advPrice: '<span class="d-block">1850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '56 ₽/км',
        ndsCheckout: '65 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  }]

  description = 'Вот уже более 5 лет транспортная компания «Акро» осуществляет перевозки и переезды различными видами транспорта на любые расстояния от газели до фуры, оказывая полный комплекс логистических услуг физическим и юридическим лицам. Приоритетными направлениями в нашей географии являются Москва и Московская область, но также мы работаем и в других регионах России. Если вам требуется быстрая и качественная грузовая перевозка любых видов грузов или переезд  — обращайтесь в нашу компанию! Надежно перевозим коробки, упаковки, тяжелых и малотоннажных предметов заказчика по столице или вне её без выходых.'

  blockWithItems = {
    title: 'Профессиональные сотрудники',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Мы составим для Вас самый удобный маршрут, который подходит для доставки груза или переезда на автомобиле. Мы постараемся полностью сократить цену на Ваши дополнительные расходы',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Консультант будет в течение всего времени информировать Вас о статусе перевозки груза на грузовой машине',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Мы даем Вам возможность бесплатной консультации от наших специалистов, достаточно сделать заказ обратного звонка. Будем рады проконсультировать Вас по вопросам перевозок или переезда на авто в удобное время',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems2 = {
    title: 'Преимущества',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Грузоподъемность  не превышает 600 кг, с объемом кузова до 3 м³',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Идеальный вариант для малогабаритных перевозок как в черте города, так и за его пределами',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Небольшие размеры и возможность беспрепятственного въезда в центр города (для авто с массой более 2500 тонн требуется пропуск)',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Отсутствие переплаты за свободный объем в кузове автомобиля',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Менеджер подберет для вас оптимальный автомобиль и сможет рассчитать стоимость грузоперевозки, учитывая все нюансы',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Только у нас вы найдете самые выгодные условия на качественную перевозку ваших товаров в день обращения',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  description2='Перевозка и переезд с нами — это просто и выгодно. Мы гарантируем низкие цены на доставку, комфортные условия сотрудничества и варианты оплаты, сохранность вашего груза и соблюдение заранее оговоренных сроков. <br><br>Если вам требуется перевозка нестандартного груза (стиральных машин, пианино, холодильника, вы можете прислать нам фото или видео, чтобы наши специалисты могли подобрать для вас наилучший авто и в кратчайшие сроки рассчитать стоимость. <br><br>Если перевозка ваших товаров или переезд предполагает въезд в центр, то «каблук» будет идеальным вариантом, поскольку у этих маневренных автомобилей нет проблем с передвижением по самым оживленным улицам.'

  faq = [{
    title: "Почему перевозка по Москве и области каблуком это лучший выбор?",
    description: "Наш успешный опыт работы подтверждают многочисленные отзывы довольных клиентов. Перевозки и переезды на каблуке с компанией «Акро» — это быстро, надежно и выгодно. Вы можете быть уверены, что в штате нашей компании работают самые квалифицированные, надёжные сотрудники, а наш автопарк укомплектован транспортными средствами, которые удовлетворят любые требования:"
    + "<ul>" +
    "<li>все наши водители имеют большой стаж работы, отлично ориентируются на местности, максимально бережно относятся к доверенному им грузу и в случае форс-мажорных ситуаций всегда найдут выход;</li>" +
    "<li>наши грамотные менеджеры гарантируют отличный сервис: они ответят на все ваши вопросы, подберут для вас оптимальный автомобиль каблук, маршрут и индивидуальный тариф с учетом особенностей вашего груза;</li>" +
    "<li>если погрузочно-разгрузочные работы своими силами невозможны, к вам на помощь придут наши аккуратные грузчики (они осуществят вынос вашего груза из квартиры, дома или офиса, помогут осуществить подъем на этаж, загрузку в машину и т.д.);</li>" +
    "<li>все наши машины каблук находятся в отличном техническом состоянии, регулярно проходят техосмотры, тщательно и своевременно обслуживаются и будут доставлены к вам полностью готовыми к работе.</li>" +
    "</ul>" +
    "Перевозка товара будет произведена качественно и в срок. Машина каблук будет доставлена к вам точно в оговоренное время. Мы максимально аккуратно и осторожно перевезём даже самые хрупкие вещи: стекло, антиквариат, мягкую мебель, оборудование, строительные материалы, технику и при необходимости даже животных, а также осуществим вывоз строительного мусора. Перевозка грузов с помощью компании «Акро» из любого региона или города России до пункта назначения быстрая, безопасная и с соблюдением всех правил транспортировки. Мы гарантируем полное сопровождение сделки на всех этапах. "
  },{
    title: "Какие тарифы вы можете выбрать при заказе переезда автомобилем типа «каблучок»?",
    description: "Грузоперевозки каблуком в Москве осуществляются транспортной компанией «Акро» по двум основным тарифам:" +
      "<ul>" +
      "<li>Бизнес: в данном тарифе включено 70 км по Москве, минимальная стоимость грузоперевозки составляет 3 050 руб при стоимости часа 610 руб. Аренда каблука начинается от 4 часов и 1 час — на подачу автомобиля.</li>" +
      "<li>Бизнес +: 100 км по Москве включено в минимальную стоимость 5 490 руб. Аренда предполагает минимум 9 часов, при этом подача автомобиля каблук — за наш счет. Цена одного часа — 610 руб.</li>" +
      "</ul>" +
      "Стоимость грузоперевозки или переезда на машине каблук за пределами МКАД обсуждается в индивидуальном порядке."
  },{
    title: "Какие предельные габариты у груза, перевозимого каблуком?",
    description: "В кузове автомобиля каблук достаточно места для 1 европаллеты, ширина которой составляет 1 м, высота — 1 м, длина — 1,5 м. Объем груза не может превышать два кубических метра с максимальным весом 0,5 тонны."
  },{
    title: "Как осуществляется расчет за услугу?",
    description: "Выберите удобный для вас способ: постоплату, которая производится на наш расчетный счет после оказания услуги, или предоплату (в этом случае будет действовать акция и стоимость перевозки или переезда будет ниже)."
  },{
    title: "Как оформить заявку на аренду каблука?",
    description: "Для заказа авто каблук в Москве или Московской области вам нужно выбрать один из нескольких удобных вариантов:"
    + "<ol>" +
          "<li>оставить заявку на обратный звонок, заполнив форму обратной связи на сайте (менеджер свяжется с вами с течение двух минут);</li>" +
          "<li>создать новую заявку в личном кабинете;</li>" +
          "<li>позвонить нам по телефону 8 (499) 350-90-02;</li>" +
          "<li>написать на почту info@tk-akro.ru</li>" +
      "</ol>" +
      "Заполняя онлайн-форму на сайте, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных. После уточнения всей информации, наш менеджер подбирает для вас оптимальную машину каблук для переезда или грузоперевозки и делает просчет стоимости вашего заказа. Затем мы заключаем с вами договор и в назначенный срок осуществляется перевозка."
  }, {
    title: "Как осуществляется расчет за услугу?",
    description: "Выберите удобный для вас способ: постоплату, которая производится на наш расчетный счет после оказания услуги, или предоплату (в этом случае будет действовать акция и стоимость перевозки или переезда будет ниже)."
  }, {
    title: "Предоставляете ли вы дополнительные услуги грузчиков?",
    description: "Да, при необходимости, помимо доставки, вы можете заказать эту услугу. Цена будет зависеть от того, сколько весит ваш груз, и его габаритов. В зависимости от этих параметров погрузку и разгрузку может осуществлять водитель-экспедитор или бригада грузчиков."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Наш офис расположен по адресу: город Москва, ул Черкизовская Б., д. 24а стр. 1. Наши контакты: телефон 8 (499) 350-90-02, электронная почта info@tk-akro.ru, на которую вы можете писать круглосуточно."
  }, {
    title: "Каким образом вы возвращаете оригиналы документов?",
    description: "После оказания услуги грузоперевозки или переезда два раза в месяц мы доставляем оригиналы документов к вам в офис по адресу, указанному в личном кабинете при регистрации."
  }, {
    title: "Как зарегистрироваться в личном кабинете?",
    description: "При переходе на главную страницу сайта https://tk-akro.ru/ вы нажимаете на ссылку «Личный кабинет», заполняете обязательные поля формы, даете разрешение на хранение и обработку персональных данных и нажимаете кнопку «зарегистрироваться». После этого вам будут доступны все преимущества нашего сервиса. "
  }, {
    title: "Где можно получить информацию о том, какие вакансии открыты в вашей компании?",
    description: "Все открытые вакансии размещены на сайте hh.ru"
  }];

  tags = {
    title: 'Автомобильные перевозки',
    class: '',
    items: [{
      class: '',
      title: '',
      children: [{
        title: 'Перевозка 3 тонны',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Перевозка 5 тонн',
        link: '/avtopark/gruzoperevozki-5tonn'
      },{
        title: 'Перевозка 10 тонн',
        link: '/avtopark/tent-furgon/tent-10-tonn'
      },{
        title: 'Перевозка 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn'
      },{
        title: 'Офисный переезд',
        link: '#price'
      },{
        title: 'Квартирный переезд',
        link: '#price'
      },{
        title: 'Переезд на дачу',
        link: '#price'
      },{
        title: 'Перевозка по Москве и области',
        link: '/'
      },{
        title: 'Переезд в Москве',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Перевозка личных вещей',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Домашний переезд',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Перевозка вещей',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Перевозка мебели',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'VIP переезд',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Переезд магазина',
        link: '/'
      },{
        title: 'Загородный переезд',
        link: '/gruzoperevozki-po-moskve-i-mo'
      },{
        title: 'Переезд склада',
        link: '/'
      }]
    }]
  };

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Грузоперевозки Каблук по Москве - недорогие перевозки на Каблуке' });
    this.meta.addTag({ property: 'og:description', content: '✅ Услуги грузоперевозки на автомобиле Каблук от 610 руб ✅ Подача автомобиля в день обращения! Звоните +7 (499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: '✅ Услуги грузоперевозки на автомобиле Каблук от 610 руб ✅ Подача автомобиля в день обращения! Звоните +7 (499) 350-90-02' });
    this.titleService.setTitle('Грузоперевозки Каблук по Москве - недорогие перевозки на Каблуке');
  }
}
