<app-banner
  [image]="'/assets/images/banner/banner-msk-spb.svg'"
  [title]="'Грузоперевозки из Москвы в Санкт-Петербург'"
  [description]="''"
  [showButtonCall]="true"
  [showButtonKp]="true">
</app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page [description]="descriptionFirst"></app-block-description-page>

  <app-tariff-short
    [typeActive]="typeActive"
    [typeTitle]="typeTitle"
    [tariffs]="tariffs"
    [showButtonCall]="true">
  </app-tariff-short>

  <app-block-with-items
    [title]="blockWithItems1['title']"
    [description]="blockWithItems1['description']"
    [class]="blockWithItems1['class']"
    [items]="blockWithItems1['items']"
    [showMobileSlider]="true">
  </app-block-with-items>

  <p class="mb-50-px mb-50-px pr-20-xs pr-0-lg pl-20-xs pl-0-lg">Доставка в Санкт Петербург из Москвы осуществляется по тщательно выверенному оптимальному маршруту в среднем за один день.</p>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-lg mb-40-xs text-grey4">Транспортная компания «Акро»</h2>
    <div class="row">
      <div class="col-12 col-lg-8 pr-50-lg text-grey9">
        <div class="row">
          <div class="col-12 col-lg-6 mb-30-xs">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">01</div>
              <div class="col pl-20-xs text-18-xs">Гарантируем, что груз будет доставлен в срок и без каких-либо повреждений</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-30-xs">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">02</div>
              <div class="col pl-20-xs text-18-xs">Согласовываете нюансы перевозки с менеджером</div>
            </div>
          </div>
          <div class="col-12 mb-30-xs">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">03</div>
              <div class="col pl-20-xs text-18-xs">Наши низкие цены, выгодные тарифы и специальные условия для
                постоянных клиентов вас порадуют, а многочисленные положительные отзывы о нашей работе подскажут, что вы
                сделали правильный выбор, оформив заказ на грузоперевозку у нас.</div>
            </div>
          </div>
          <div class="col-12 mb-30-xs">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">04</div>
              <div class="col pl-20-xs text-18-xs">Подача транспорта в срочном порядке может быть организована уже
                спустя несколько часов после оформления заявки.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg">
        <div
          class="border-blue b-r-16-xs mt-20-xs mt-0-lg p-30-xs text-grey4 bg-purple position-relative height-to-full">
          <p class="text-16-xs">Транспортная компания «Акро» — одна из лучших компаний в сегменте автомобильных
            перевозок по маршруту Москва Санкт-Петербург с опытом успешной работы более 5 лет.</p>
          <img src="assets/images/msk-spb/msk-tk-image.svg" alt="процент"
            class="position-absolute-lg bottom-0-lg right-0-lg">
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-lg mb-32-xs text-grey4">С «AKRO» ваша логистика <br> станет эффективнее</h2>

    <app-feedback
      [title]="'Заполните форму обратной связи и получите:'"
      [description]="'— Расчет стоимости перевозки сборных грузов\n'+
        '— Индивидуальное коммерческое предложение\n' +
         '— Доставку товаров в маркетплейс.'">
    </app-feedback>
  </div>

  <!-- <app-service [services]="services"></app-service> -->

  <div class="pb-50-xs pt-50-xs pt-60-lg pb-30-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg border-bottom">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Стоимость доставки по России <br>и из Москвы в
      Санкт-Петербург</h2>
    <p class="text-24-xs text-grey4">В частности  рассчитывается в индивидуальном порядке по запросу и зависит от:</p>

    <div class="row d-flex align-items-cente p-10-xs">

      <div class="col-12 col-lg-4 d-flex flex-column p-12-xs p-0-lg pr-10-lg">
        <div
          class="border-blue b-r-16-xs mb-10-xs mb-0-lg align-items-center text-gray3 p-20-xs position-relative z-2-xs height-to-full bg-purple h-310-xs h-percent-100-lg">
          <p class="text-17-lg p-0 m-0">Параметров груза (вес в кг, объем, длина, ширина и высота)</p>
          <img src="assets/images/msk-spb/msk-card-1.svg" alt="процент"
            class="position-absolute-xs bottom-0-xs right-0-xs">
        </div>
      </div>

      <div class="col-12 col-lg-8 position-relative">
        <div class="row">
          <div class="col-12 col-lg-8">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs position-relative z-2-xs bg-purple h-310-xs h-percent-100-lg">
              <p class="text-17-lg p-0 m-0 col-lg-6">Необходимости дополнительных услуг (погрузка и разгрузка,
                необходимость страхования, помощь в упаковке, ответственное хранение)</p>
              <img src="assets/images/msk-spb/msk-card-2.svg" alt="процент"
                class="position-absolute-xs right-0-xs top-10-lg bottom-0-xs">
            </div>
          </div>

          <div class="col-12 col-lg-4 mb-20-xs mb-0-lg">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs position-relative z-2-xs height-to-full bg-purple h-210-xs h-percent-100-lg">
              <p class="text-17-lg p-0 m-0 col-lg-10">Типа и особенностей груза</p>
              <img src="assets/images/msk-spb/msk-card-3.svg" alt="процент"
                class="position-absolute-xs right-0-xs top-10-lg bottom-0-xs">
            </div>
          </div>

          <div class="col-12 col-lg-4 mt-20-lg">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs position-relative z-2-xs mh-150-lg bg-purple h-210-xs h-percent-100-lg">
              <p class="text-17-lg p-0 m-0 col-lg-6">Протяженности <br>и сложности дороги</p>
              <img src="assets/images/msk-spb/msk-card-4.svg" alt="процент"
                class="position-absolute-xs right-0-xs bottom-0-xs">
            </div>
          </div>

          <div class="col-12 col-lg-8 mt-20-lg">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs position-relative z-2-xs height-to-full bg-purple h-210-xs h-percent-100-lg">
              <p class="text-17-lg p-0 m-0 col-lg-10">Периодичности перевозок</p>
              <img src="assets/images/msk-spb/msk-card-5.svg" alt="процент"
                class="position-absolute-xs right-0-xs top-0-lg bottom-0-xs">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row text-grey4 pb-60-lg p-22-xs p-0-xs">
    <div class="col-12 col-lg-8 text-24-xs mb-30-xs pt-30-xs">
      Для заказа доставки груза <br>Москва — Санкт-Петербург вы можете
    </div>
    <div class="col-12 col-lg-6 text-16-xs mt-20-xs mt-0-lg">
      <div class="text-grey4 text-16-lg point-blue">
        <ul class="p-0-xs">
          <li class="mb-30-lg">Оставить заявку на сайте через форму обратной связи</li>
          <li class="">Заполнить заявку через личный кабинет - <br>оформление займет не более 5 минут</li>
        </ul>
      </div>
    </div>

    <div class="col-12 col-lg-6 mt-20-xs mt-0-lg">
      <div class="text-grey4 text-16-lg point-blue">
        <ul class="p-0-xs">
          <li class="mb-30-lg">Позвонить нам по номеру телефона <a href="tel:+74993509002">8 (499) 350-90-02</a></li>
          <li class="">Написать на электронную почту <a href="mailto:info@tk-akro.ru">info@tk-akro.ru</a></li>
        </ul>
      </div>
      <a href="" role="button"
        (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)"
        class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
        Заказать звонок
      </a>
    </div>

  </div>

  <app-block-with-items
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']"
    [showMobileSlider]="true">
  </app-block-with-items>

  <div class="pb-50-xs pt-50-xs pt-60-lg pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-40-xs mt-0-xs">Преимущества работы с нами</h2>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-auto text-blue text-48-xs pr-0-xs pr-20-xs">01</div>
          <div class="col">
            <h3 class="text-24-xs mb-16-xs">Индивидуальный подход к расчету стоимости</h3>
            <p class="text-16-xs">Выгодные тарифы, акции и скидки постоянным клиентам</p>
          </div>
          <div class="col-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/msk-spb/procent.svg" alt="процент">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Персональный менеджер</h3>
            <p class="text-16-xs">Который всегда ответит на все ваши вопросы, построит маршрут от Москвы до
              Санкт-Петербурга и оформит все сопроводительные документы</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/msk-spb/geo.svg" alt="Транспорт с геопозицией">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Удобный личный кабинет</h3>
            <p class="text-16-xs">Который позволяет легко создавать новые заявки на грузоперевозки и отследить текущие,
              хранить и скачивать документы. Также в нем есть калькулятор , которым можно воспользоваться для расчета
              стоимости отправления.</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/msk-spb/computer.svg" alt="Компьютер">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Большое разнообразие грузовиков для аренды</h3>
            <p class="text-16-xs">Их грузоподъемность 500 кг, 1-3 тонны, 10-20 тонн. Вы можете выбрать тент -фургон,
              рефрижератор, борт или манипулятор. Если вы затрудняетесь с выбором, можно сделать это с помощью
              менеджера.</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/msk-spb/2-trucks.svg" alt="два грузовика">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row position-relative pt-70-xs pb-50-xs pl-20-xs pr-20-xs pr-0-sm-0 pl-0-sm">
    <div class="col col-lg-6">
      <h3 class="text-32-xs text-48-lg mb-32-xs text-grey4">Гарантия сохранности перевозимого груза</h3>
      <p>В ТК «Акро» работают только опытные водители, квалифицированные логисты и грамотные менеджеры, благодаря
        которым обеспечивается самый высокий уровень сервиса. Заказывая доставку из москвы в санкт петербург у нас, вы
        можете быть уверены в качестве выполнения услуги грузоперевозки.</p>
    </div>
    <div class="col-12 col-lg p-0-xs d-flex justify-content-center flex-center align-items-center">
      <img loading="lazy" src="assets/images/msk-spb/garage-truck.svg" class="position-absolute-lg"
        alt="геометка фургон карта">
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>



</div>
