import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePage} from './pages/home/home.component';
import {ServicesPage} from './pages/services/services.component';
import {AutoparkPage} from './pages/autopark/autopark.component';
import {ActionsPage} from './pages/actions/actions.component';
import {ContactsPage} from './pages/contacts/contacts.component';
import {DeliveryToMarketplacesPage} from './pages/delivery-to-marketplaces/delivery-to-marketplaces.component';
import {FlatbedTrucksPage} from './pages/flatbed-trucks/flatbed-trucks.component';
import {NewsPage} from './pages/news/news.component';
import {OutsourcingTransportationServicesPage} from './pages/outsourcing-transportation-services/outsourcing-transportation-services.component';
import {RefrigeratedTruckTransportationPage} from './pages/refrigerated-truck-transportation/refrigerated-truck-transportation.component';
import {ServicesSpecialVehiclesPage} from './pages/services-special-vehicles/services-special-vehicles.component';
import {ShippingOnOzonPage} from './pages/shipping-on-ozon/shipping-on-ozon.component';
import {ShippingOnWildberriesPage} from './pages/shipping-on-wildberries/shipping-on-wildberries.component';
import {ShippingOnYandexMarketPage} from './pages/shipping-on-yandex-market/shipping-on-yandex-market.component';
import {TruckingBullockPage} from './pages/trucking-bullock/trucking-bullock.component';
import {TruckingByGazellePage} from './pages/trucking-by-gazelle/trucking-by-gazelle.component';
import {TruckingCollaborationPage} from './pages/trucking-collaboration/trucking-collaboration.component';
import {TruckingFuriesPage} from './pages/trucking-furies/trucking-furies.component';
import {TruckingHeelPage} from './pages/trucking-heel/trucking-heel.component';
import {TruckingNonCashPaymentPage} from './pages/trucking-non-cash-payment/trucking-non-cash-payment.component';
import {TruckingUpToFiveTonsPage} from './pages/trucking-up-to-five-tons/trucking-up-to-five-tons.component';
import {TruckingUpToThreeTonsPage} from './pages/trucking-up-to-three-tons/trucking-up-to-three-tons.component';
import {TruckingUpToTenTonsPage} from './pages/trucking-up-to-ten-tons/trucking-up-to-ten-tons.component';
import {TruckingUpToTwentyTonsPage} from './pages/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons.component';
import {ManipulatorTransportsPage} from "./pages/manipulator-transports/manipulator-transports.component";
import {TruckingForIndividualsPage} from "./pages/trucking-for-individuals/trucking-for-individuals.component";
import {TransportationForBusinessesPage} from "./pages/transportation-for-businesses/transportation-for-businesses.component";
import {FreightInsurancePage} from './pages/freight-insurance/freight-insurance.component';
import {TransportationOnRussiaPage} from './pages/transportation-on-russia/transportation-on-russia.component';
import {TentFurgonPage} from './pages/tent-furgon/tent-furgon.component';
import {TransportationCompanyPage} from './pages/transportation-company/transportation-company.component';
import {JobsPage} from './pages/jobs/jobs.component';
import {SitemapPage} from './pages/sitemap/sitemap.component';
import {MskSpbPage} from './pages/msk-spb/msk-spb.component';
import {SpbMskPage} from './pages/spb-msk/spb-msk.component';
import {PageNotFoundPage} from './pages/page-not-found/page-not-found.component';
import {SitemapComponent} from './pages/sitemap_index/sitemap_index.component';


const routes: Routes = [
  {path: 'gruzoperevozki-po-moskve-i-mo', component: HomePage},
  {path: 'services', component: ServicesPage},
  {path: 'akcii', component: ActionsPage},
  {path: 'avtopark', component: AutoparkPage},
  {path: 'flatbed-trucks', component: FlatbedTrucksPage},
  {path: 'novosti', component: NewsPage},
  {path: 'kontakty', component: ContactsPage},
  {path: 'marketplejsy', component: DeliveryToMarketplacesPage },
  {path: 'marketplejsy/yandex-market', component: ShippingOnYandexMarketPage },
  {path: 'marketplejsy/wildberries', component: ShippingOnWildberriesPage },
  {path: 'marketplejsy/ozon', component: ShippingOnOzonPage},
  {path: 'services-special-vehicles', component: ServicesSpecialVehiclesPage},
  {path: 'avtopark/refrizheratornye-perevozki', component: RefrigeratedTruckTransportationPage},
  {path: 'manipulyatory', component: ManipulatorTransportsPage},
  {path: 'avtopark/gruzoperevozki-na-gazeli', component: TruckingByGazellePage},
  {path: 'avtopark/gruzoperevozki-3tonny', component: TruckingUpToThreeTonsPage},
  {path: 'avtopark/gruzoperevozki-5tonn', component: TruckingUpToFiveTonsPage},
  {path: 'avtopark/gruzoperevozki-10tonn', component: TruckingUpToTenTonsPage},
  {path: 'avtopark/gruzoperevozki-20tonn', component: TruckingUpToTwentyTonsPage},
  {path: 'avtopark/gruzoperevozki-furami', component: TruckingFuriesPage},
  {path: 'avtopark/gruzoperevozki-kabluk', component: TruckingHeelPage},
  {path: 'avtopark/gruzoperevozki-bychok', component: TruckingBullockPage},
  {path: 'gruzoperevozki-dlya-yur-lic/gruzoperevozki-beznalichnyj-raschet', component: TruckingNonCashPaymentPage},
  {path: 'gruzoperevozki-dlya-yur-lic/gruzoperevozki-sotrudnichestvo', component: TruckingCollaborationPage},
  {path: 'gruzoperevozki-dlya-yur-lic/autsorsing-transportnyx-uslug', component: OutsourcingTransportationServicesPage},
  {path: 'gruzoperevozki-dlya-fiz-lic', component: TruckingForIndividualsPage},
  {path: 'gruzoperevozki-dlya-yur-lic', component: TransportationForBusinessesPage},
  {path: 'strahovanie-gruza', component: FreightInsurancePage},
  {path: 'perevozki-po-rossii', component: TransportationOnRussiaPage},
  {path: 'avtopark/tent-furgon', component: TentFurgonPage},
  {path: '', component: TransportationCompanyPage},
  {path: 'jobs', component: JobsPage },
  {path: 'sitemap', component: SitemapPage },
  {path: 'msk-spb', component: MskSpbPage},
  {path: 'spb-msk', component: SpbMskPage},
  {path: '404', component: PageNotFoundPage },
  {path: '**', component: PageNotFoundPage },
  {path: 'sitemap.xml', component: SitemapComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
