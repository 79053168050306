import {Component} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-what-choose-us',
  templateUrl: './what-choose-us.component.html',
  styleUrls: ['./what-choose-us.component.less'],
  providers: [NgbCarouselConfig],
})
export class WhatChooseUsComponent {
  slideConfig = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: false,
        }
      }
    ]
  };

  slides = [{
    title: "Штат квалифицированных <br> сотрудников",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Все рабочие процессы осуществляются опытными специалистами с большим стажем работы</li>" +
        "<li class='mb-30-lg'>Наши водители ежедневно доставляют до 800 тонн груза</li>" +
        "<li class='mb-30-lg'>Работу водителей в процессе перевозки контролирует служба отдела качества</li>" +
        "<li class=''>Слаженность работы команды «Акро» гарантирует своевременное прибытие машины, качественную доставку и безопасность транспортировки</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-1.svg",
    alt: "Штат сотрудников"
  },{
    title: "Большой <br> автопарк",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Большой выбор транспортных средств с любым видом кузова под все грузы</li>" +
        "<li class='mb-30-lg'>Все автомобили проходят тщательное и своевременное обслуживание</li>" +
        "<li class='mb-30-lg'>Систематическое пополнение и модернизация автопарка для решения большего количества логистических задач</li>" +
        "<li>Помощь в подборе варианта автомобиля конкретно под ваши нужды</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-2.svg",
    alt: "Большой автопарк"
  },{
    title: "Большой опыт работы <br> в перевозках",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>7 лет - средний стаж работы наших специалистов в логистике</li>" +
        "<li class='mb-30-lg'>Накопленный годами опыт позволяет не только проработать оптимальный маршрут для любого направления, но и сэкономить ваши денежные и временные ресурсы</li>" +
        "<li class='mb-30-lg'>Идеальный выбор для представителей сфер малого, среднего и крупного бизнеса, которым время от времени нужны срочные грузоперевозки по Москве и Подмосковью</li>" +
        "<li>«Акро» - надежный и честный партнер. Наши клиенты всегда довольны и  говорят нам “спасибо” - почитайте отзывы</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-3.svg",
    alt: "Опыт работы"
  },{
    title: "Гарантия <br> сохранности груза",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Мы на 100% гарантируем безопасность вашего товара</li>" +
        "<li class='mb-30-lg'>Полностью берем на себя заключение страхового договора</li>" +
        "<li class='mb-30-lg'>Обеспечиваем выгодные условия страхования груза</li>" +
        "<li>Несем полную ответственность за ваш груз на всех этапах перевозки</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-4.svg"
  },{
    title: "Работа <br> по всей России",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Обеспечим качественную перевозку грузов по всей России, в том числе в самые отдаленные уголки страны</li>" +
        "<li class='mb-30-lg'>Мы знаем все нюансы и тонкости работы практически в любых направлениях грузоперевозок по России</li>" +
        "<li>Опытные специалисты построят оптимальный маршрут</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-5.svg",
    alt: "Работа по россии"
  },{
    title: "Срочная <br> перевозка груза",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Доставим транспорт срочно без проблем в день обращения</li>" +
        "<li class='mb-30-lg'>Подача транспорта до 3х т под загрузку в пределах МКАД в течение 2 часов</li>" +
        "<li class='mb-30-lg'>Если грузоподъемность автомобиля 5 т и выше - подача в течение 3-4 часов в день обращения</li>" +
        "<li>Гарантируем соблюдение сроков перевозки - вы можете быть уверены, что услуга будет оказана вовремя</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-6.svg",
    alt: "Срочная перевозка"
  },{
    title: "Индивидуальный <br> подход",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Наш принцип - «Клиент всегда прав»</li>" +
        "<li class='mb-30-lg'>Выстраиваем длительные и доверительные отношения со всеми бизнес-партнерами</li>" +
        "<li class='mb-30-lg'>Гибкая система скидок для постоянных клиентов - экономия до 20% от стоимости перевозки. Наши цены и акции вас приятно удивят!</li>" +
        "<li>Отсрочка платежа до 30 дней</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-7.svg",
    alt: "Индивидуальный подход"
  },{
    title: "Персональный <br> менеджер",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Персональный менеджер, закрепленный за вашей компанией, поможет с решением вопросов</li>" +
        "<li class='mb-30-lg'>Он сопровождает вас на всех стадиях перевозки: от подачи заявки до момента выставления счета</li>" +
        "<li>Всегда на связи: составит оптимальный маршрут, четко рассчитает цену, согласует скидку и поможет сэкономить ваше время и деньги</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-8.svg",
    alt: "Персональный менеджер"
  },{
    title: "Налаженный <br> документооборот",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Гарантируем корректность оформления сопроводительных документов для юридических лиц</li>" +
        "<li class='mb-30-lg'>Учитываем индивидуальные пожелания по заполнению и оформлению</li>" +
        "<li class='mb-30-lg'>Все сопроводительные документы хранятся в личном кабинете и доступны к скачиванию</li>" +
        "<li>Оригиналы документов от компании своевременно передаются клиенту любым способом</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-9.svg",
    alt: "Налаженный документооборот"
  },{
    title: "Удобство работы с нами",
    description:
      "<ul class='p-0-xs'>" +
        "<li class='mb-30-lg'>Индивидуальные условия работы</li>" +
        "<li class='mb-30-lg'>Все удобные способы оплаты</li>" +
        "<li class='mb-30-lg'>Возможность оплаты после предоставления услуг по перевозке</li>" +
        "<li>Экономия вашего времени, сил и средств</li>" +
      "</ul>",
    image: "/assets/images/what-choose-us/what-choose-us-11.svg",
    alt: "Удобство работы"
  }]

  constructor(config: NgbCarouselConfig) {
    config.interval = 100000000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }
}
