import {Component, Input} from '@angular/core';
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-actions-and-sales',
  templateUrl: './actions-and-sales.component.html',
  styleUrls: ['./actions-and-sales.component.less']
})
export class ActionsAndSalesComponent {

  @Input() items: {
    title: string;
    description: string;
    percent: string;
    image: string;
  }[] | undefined

  slideConfig = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false
  };

  constructor(
    public readonly popupService: PopupService
  ) {}
}
