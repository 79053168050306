<app-banner
  [image]="'/assets/images/banner/banner-delivery-to-marketplaces.svg'"
  [title]="'Доставка до маркетплейсов'"
  [description]="'Ozon, Wildberries, Яндекс.Маркет'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <app-block-description-page
  [description]="description2"
  [class]="'bg-purple border-grey'"
  >
  </app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-block-description-page
    [description]="description"
    [class]="'bg-purple border-grey3'"
  >
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">С ТК «Акро» ваша логистика станет эффективнее</h2>
    <div class="row">
      <p>разместить заказ на доставку груза на склад маркетплейса, заполнив форму обратной связи - мы перезвоним вам через 2 минуты. Для просчета стоимости доставки груза нам потребуется данная информация: количество коробок или количество паллет, общая масса груза (кг). Укажите даты забора и поставки на склад маркетплейса, а также ваш адрес, и склад доставки.</p>
      <p>оставьте заявку самостоятельно через личный кабинет. Вам следует просто указать данные по отправке - дату и время подачи автомобиля, заполнить характеристики груза, подобрать желаемый тип транспорта и маршрут, а также дополнительные услуги, которые будут отражены в приложении к договору при необходимости. В личном кабинете вы можете отслеживать ваши отправления, там же будут доступны копии всех закрывающих документов.</p>
      <p>получите предварительную консультацию по телефону 8 (499) 350-90-02: наш менеджер свяжется с вами, подберет для вас оптимальный тариф доставки груза до склада и ответит на все интересующие вопросы.</p>
    </div>
    <app-feedback
      [title]="''"
      [description]="'ВАЖНО: мы не принимаем к доставке на склады товары с классом опасности, а перевозки хрупкого груза обговариваются отдельно. Для транспортировки ценных и дорогостоящих товары на маркетплейсы мы можем заключить бесплатный страховой договор.'"
    >
    </app-feedback>

  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Доставка груза до склада маркетплейса с помощью «Акро» - это легко, быстро и эффективно!</h2>
    <p class="text-16-xs mb-48-xs">Оформите доставку до маркетплейса подходящим для вас способом</p>

    <div class="row mb-40-xs">
      <div class="col-12 col-lg-3 py-10-xs py-0-lg">
        <div class="row b-r-16-xs p-20-xs bg-purple height-to-full border-grey3 align-items-center mr-10-xs">
          <p class="col text-18-xs p-0-xs m-0-xs">Через форму обратной связи</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/delivery-to-marketplaces/delivery-to-marketplaces-7.svg" alt="Форма связи">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 py-10-xs py-0-lg">
        <div class="row b-r-16-xs p-20-xs bg-purple height-to-full border-grey3 align-items-center mr-10-xs">
          <p class="col text-18-xs p-0-xs m-0-xs">В личном кабинете</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/delivery-to-marketplaces/delivery-to-marketplaces-8.svg" alt="В личном кабинете">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 py-10-xs py-0-lg">
        <div class="row b-r-16-xs p-20-xs bg-purple height-to-full border-grey3 align-items-center mr-10-xs">
          <p class="col text-18-xs p-0-xs m-0-xs">Отправьте заявку на почту</p>
          <div class="col-auto"><img src="assets/images/delivery-to-marketplaces/delivery-to-marketplaces-9.svg" alt="Отправление заявки"></div>
        </div>
      </div>
      <div class="col-12 col-lg-3 py-10-xs py-0-lg">
        <div class="row b-r-16-xs p-20-xs bg-purple height-to-full border-grey3 align-items-center mr-10-xs mr-0-lg">
          <p class="col text-18-xs p-0-xs m-0-xs">Позвоните нам</p>
          <div class="col-auto"><img src="assets/images/delivery-to-marketplaces/delivery-to-marketplaces-10.svg" alt="Звонок"></div>
        </div>
      </div>
    </div>

    <p class="p-0-xs m-0-xs text-16-xs text-grey4">В нужное время и день осуществляем забор товара с вашего производства или склада. Мы подберем авто под ваши потребности и учтем, чтобы транспорт подходил именно под ваш тип погрузки: например, авто под пандус и погрузки погрузчиком или краном (авто будет с верхней растентовкой). Также мы можем загрузить коробки вручную (возможна помощь водителя при загрузке товаров весом до 50 кг). При необходимости вы можете дополнительно заказать услуги нескольких грузчиков, если товара очень много, и погрузо-разгрузочные работы возможны только вручную.<br><br>
      В определенную дату ваша посылка доставляется в распределительный центр маркетплейса. Доставкой груза до складов/ пункт выдачи (пвз) маркетплейсов занимаются высококвалифицированные сотрудники, которые детально знакомы с регламентом работы всех площадок e-commerce соответственно схеме их работы (fbo, fbs, dbs). Перед тем, как оформить отправку посылки или перед ее доставкой на наш склад, упакуйте ее и обязательно проверьте правильность упаковки, а также нанесена ли необходимая маркировка на товары. В случае, если на распределительном центре торговой площадки Ozon, Wildberries, Яндекс Маркет не принимают товар, мы самостоятельно постараемся решить проблему на месте и предпримем все меры для того, чтобы ваш товар приняли. Если без отправителя груза нарушения устранить невозможно, мы уже подключаем к решению проблемы и вас, чтобы решить ее совместно!<br><br>
      После оказания услуги доставки груза до склада маркетплейса вы производите оплату тарифа на наш расчетный счет. Копии всех документов будут доступны в личном кабинете, а оригиналы мы направим курьером. <br><br>
      Услуги по доставке товаров на склад маркетплейса оказываются нами напрямую «под ключ», что подразумевает полное сопровождение и оказание поддержки на всех этапах — от оформления заказа до возврата сопроводительных документов. Мы проконтролируем качество приема товара на погрузке, правильность оформления и передачи всех документов на провоз, окажем помощь в решении нестандартных ситуаций при выгрузке на складе и в максимально сжатые сроки отправим закрывающие документы. С компанией «Акро» вы можете быть уверены, что доставка заказов в терминал Озон, Вайлдберис будет осуществлена быстро и аккуратно. Для постоянных партнеров действуют специальные условия!</p>
  </div>


  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
