import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-up-to-ten-tons',
  templateUrl: './trucking-up-to-ten-tons.component.html',
  styleUrls: ['./trucking-up-to-ten-tons.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingUpToTenTonsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: 'avtopark',
      linkTitle: 'Наш автопарк'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки 10 тонн'
        }]
  }

  carActive   =  0;
  typeActive  =  4;
  header = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3260 ₽',
          ndsPrice: '3750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '5868 ₽',
          ndsPrice: '6750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '20 ₽/км',
        ndsCheckout: '23 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '3475 ₽',
          ndsPrice: '4000 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6255 ₽',
          ndsPrice: '7200 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4590 ₽',
          ndsPrice: '5280 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6885 ₽',
          ndsPrice: '7920 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '5100 ₽',
          ndsPrice: '5880 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7650 ₽',
          ndsPrice: '8820 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '30 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '750 ₽/950 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '7000 ₽',
          ndsPrice: '8050 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1150 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '30 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '800 ₽/1000 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '8320 ₽',
          ndsPrice: '9600 ₽',
          advPrice: '<span class="d-block">1040 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '37 ₽/км',
        pass: '870 ₽/1050 ₽/1400 ₽',
        ndsPass: '1000 ₽/1200 ₽/1600 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '9760 ₽',
          ndsPrice: '11200 ₽',
          advPrice: '<span class="d-block">1220 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '48 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽ /1750 ₽ /2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1750 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '53 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4980 ₽',
          ndsPrice: '5700 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7470 ₽',
          ndsPrice: '8550 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '28 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: '6090 ₽',
          ndsPrice: '7000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7830 ₽',
          ndsPrice: '9000 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '35 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: '7350 ₽',
          ndsPrice: '8400 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '9450 ₽',
          ndsPrice: '10800 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '750 ₽/950 ₽'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: '12600 ₽',
          ndsPrice: '14400 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '50 ₽/км',
        pass: '870 ₽/1050 ₽/1400',
        ndsPass: '1000 ₽/1200 ₽/1600'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: '14130 ₽',
          ndsPrice: '16200 ₽',
          advPrice: '<span class="d-block">1570 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: '16650 ₽',
          ndsPrice: '18900 ₽',
          advPrice: '<span class="d-block">1850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '56 ₽/км',
        ndsCheckout: '65 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  }]

  description = 'Грузоперевозки 10 тонн наиболее востребованная услуга в компании «AKRO», которая оказывается по Москве и Московской области больше 5 лет. К нам обращаются многие представители среднего, малого и крупного бизнеса. Ведь многие знают, что заказать машину на 7-10 тонн удобно и просто именно в нашей компании. В том числе потому, что своим клиентам мы предлагаем большое разнообразие различных фур с грузоподъемностью транспорта до 10 тонн. '

  blockWithItems = {
    title: 'Наш автопарк имеет следующие виды кузовов:',
    description: '',
    class: 'col-12 col-md-6 col-lg-4 text-center',
    items: [{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-1.svg',
      alt: 'Тент',
      title: '',
      description: 'Тентованный',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-2.svg',
      alt: 'Борт',
      title: '',
      description: 'Бортовой',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-3.svg',
      alt: 'Гидроборт',
      title: '',
      description: 'Гидроборт',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-4.svg',
      alt: 'Рефрижератор',
      title: '',
      description: 'Рефрижератор',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-5.svg',
      alt: 'Фургон',
      title: '',
      description: 'Классический фургон',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-6.svg',
      alt: 'Изотермический',
      title: '',
      description: 'Изотермический',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems2 = {
    title: 'Почему именно наша «Перевозка грузов 10 тонн» является лидером транспортировки?',
    description: '',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: '',
      title: 'Знаем все нюансы перевозок',
      description: 'За весь период работы с клиентами из разных сфер юридических направлений, мы накопили большой опыт',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: 'Удобные условия сотрудничества',
      description: 'Услуга «Грузоперевозки 10 тонн — Москва и область» осуществляется как разово, так и на долгосрочной основе',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: 'Предлагаем гибкие тарифы',
      description: 'Для постоянных клиентов индивидуально подготавливаются выгодные и гибкие тарифы',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: 'Высокое качество',
      description: 'Каждая машина находится в исправном техническом состоянии, а все водители высококвалифицированны и хорошо знают город. Вы можете доверять нам даже самый ценный груз',
      class: 'p-20-xs border-blue'
    }]
  }

  blockWithItems3 = {
    title: 'Что доставляем фурами?',
    description: 'Мы регулярно доставляем фурами оптовые партии: ',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-7.svg',
      alt: 'Стройматериалы',
      title: '',
      description: 'Стройматериалов, насыпных грузов (щебень, грунт) и кирпичей',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-8.svg',
      alt: 'Прокат металлоконструкций',
      title: '',
      description: 'Проката и готовых металлоконструкций',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-9.svg',
      alt: 'Спецтехника',
      title: '',
      description: 'Спецтехники и сельхозтехники',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-10.svg',
      alt: 'Энерго оборудование',
      title: '',
      description: 'Энергетического оборудования ',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-11.svg',
      alt: 'Грузов на паллетах',
      title: '',
      description: 'Грузов на паллетах',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-12.svg',
      alt: 'Мебель',
      title: '',
      description: 'Перевозка мебели с мебельных цехов от производителя',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-ten-tons/trucking-up-to-ten-tons-13.svg',
      alt: 'Автоцистерны',
      title: '',
      description: 'Наливные грузы в автоцистернах',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'С нами оптимально перевозить грузы! Просто позвоните нам по телефону на сайте и убедитесь в этом!',
      class: 'p-20-xs text-10-xs border-blue'
    }]
  }

  tags = {
    title: 'Что мы можем перевезти:',
    items: [{
      class: '',
      title: '',
      children: [{
        title: 'Мебель',
        link: ''
      },{
        title: 'Электроника',
        link: ''
      },{
        title: 'Сыпучие материалы в упаковке',
        link: ''
      },{
        title: 'Доски',
        link: ''
      },{
        title: 'Продукты питания',
        link: ''
      },{
        title: 'Стройматериалы',
        link: ''
      },{
        title: 'Спортивное оборудование',
        link: ''
      },{
        title: 'Шины и диски',
        link: ''
      },{
        title: 'Пластик',
        link: ''
      },{
        title: 'Поликарбонат',
        link: ''
      },{
        title: 'Отопительное оборудование',
        link: ''
      },{
        title: 'Пенопласт',
        link: ''
      },{
        title: 'Термобоксы',
        link: ''
      },{
        title: 'Растения',
        link: ''
      },{
        title: 'Шпалы и рельсы',
        link: ''
      },{
        title: 'Сталь',
        link: ''
      },{
        title: 'Медицинское оборудование',
        link: ''
      },{
        title: 'Станки',
        link: ''
      },{
        title: 'Известь',
        link: ''
      }]
    }]
  };

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Грузоперевозки 10 тонн Москва и Москвоская область цена перевозки снижена' });
    this.meta.addTag({ property: 'og:description', content: '✅ Грузоперевозки 10 тонн от 1130 руб ✅ Погрузка и разгрузка грузчиками ✅ Подача автомобиля в день обращения ✅ Звоните +7 (499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: '✅ Грузоперевозки 10 тонн от 1130 руб ✅ Погрузка и разгрузка грузчиками ✅ Подача автомобиля в день обращения ✅ Звоните +7 (499) 350-90-02' });
    this.titleService.setTitle('Грузоперевозки 10 тонн Москва и Москвоская область цена перевозки снижена');
  }
}
