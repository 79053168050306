import { Component } from '@angular/core';

@Component({
  template: '',
  styleUrls: []
})
export class SitemapComponent {
  constructor() { }

  ngOnInit() {
    const xml = `<?xml version="1.0" encoding="UTF-8"?>
      <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        <sitemap>
          <loc>http://example.com/sitemap1.xml.gz</loc>
          <lastmod>2023-09-15T14:04:35+00:00</lastmod>
        </sitemap>
      </sitemapindex>`;
    const parser = new DOMParser();
    const xmlDom = parser.parseFromString(xml, 'text/xml');
    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(xmlDom);

    const headers = new Headers({ 'Content-Type': 'application/xml' });
    const response = new Response(xmlString, { headers: headers });

    return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  }
}
