<div class="bg-purple border-grey3 mt-20-lg mb-28-lg overflow-hidden">
  <div class="pt-50-xs pt-90-lg pb-50-xs pb-50-lg">
    <div class="row container text-lg-start pr-30-xs pr-0-lg pl-30-xs pl-0-lg">
      <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs px-15-lg">Наши клиенты</h2>
    </div>

    <div class="bg-purple2 border-grey3 pt-14-xs pb-14-xs">
      <ngx-slick-carousel class="carousel pt-14-lg pr-10-xs pr-0-lg pl-10-xs pl-0-lg border-top"
                          #slickModal="slick-carousel"
                          [config]="slideConfigTop">
        <div ngxSlickItem *ngFor="let slide of slidesTop" class="slide pr-10-xs pl-10-xs">
          <div>
            <img loading="lazy" src="{{slide['image']}}" alt="{{slide['alt']}}">
          </div>
        </div>
      </ngx-slick-carousel>

      <div class="pt-4-xs pb-4-xs mx-auto d-table">
        <img loading="lazy" src="/assets/images/our-client/our-client-line.svg" alt="прерывистая линия">
      </div>

      <ngx-slick-carousel dir="rtl" class="carousel pb-14-xs pr-10-xs pr-0-lg pl-10-xs pl-0-lg border-bottom"
                          #slickModal="slick-carousel"
                          [config]="slideConfigBottom">
        <div ngxSlickItem *ngFor="let slide of slidesBottom" class="slide pr-10-xs pl-10-xs">
          <div>
            <img loading="lazy" src="{{slide['image']}}" alt="{{slide['alt']}}">
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
