<div class="container">
  <div class="text-lg-start pb-50-xs pt-50-xs pb-60-lg pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h4 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs"[innerHTML]="title"></h4>

    <div class="row" *ngFor="let item of items" [ngClass]="item.class">
      <div class="col-12 p-0 mb-20-xs" *ngIf="item.title" ><h4 class="">{{item.title}}</h4></div>
      <div class="col-12">
        <div class="row">
          <div class="col-auto border-grey b-r-12-xs mr-10-xs mb-10-xs" *ngFor="let children of item.children">
            <ng-container *ngIf="children.link">
              <a *ngIf="children.link !== 'modal'" href="{{children.link}}" class="p-6-xs text-12-xs text-grey4 d-block text-decoration-none">{{children.title}}</a>
              <a *ngIf="children.link === 'modal'" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)" class="p-6-xs text-12-xs text-grey4 d-block text-decoration-none">{{children.title}}</a>
            </ng-container>
            <p *ngIf="!children.link" class="p-6-xs text-12-xs text-grey4 d-block text-decoration-none m-0-xs">{{children.title}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
