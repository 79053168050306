export enum CategoryTypeEnum {
  CAT1 = 'CAT1',
  CAT2UPTO1T = 'CAT2UPTO1T',
  CAT2 = 'CAT2',
  CAT2PLUS = 'CAT2PLUS',
  CAT3 = 'CAT3',
  CAT4 = 'CAT4',
  CAT5 = 'CAT5',
  CAT5PLUS = 'CAT5PLUS',
  CAT6 = 'CAT6',
  CAT7 = 'CAT7'
}
