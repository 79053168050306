<app-banner
  [image]="'/assets/images/banner/banner-manipulator-transports.svg'"
  [title]="'Перевозки манипуляторами'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <app-block-description-page [description]="description"></app-block-description-page>

  <div class="pb-60-xs">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Какие бывают манипуляторы </h2>
    <p class="text-16-xs">Спецтехнику с КМУ (манипулятор) можно классифицировать по разным признакам:</p>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col">
            <h3 class="text-24-xs mb-16-xs">По типу складывания стрелы</h3>
            <p class="text-16-xs">L-образные установки (они имеют выдвижные секции стрелы и лебедку с крюком, подходят для наиболее тяжеловесных грузов). Z-образные установки без лебедки (все манипуляции осуществляются благодаря складыванию и раскладыванию цилиндров. Это более маневренная конструкция манипулятора для стесненных условий).</p>
          </div>
          <div class="col-auto margin-auto-lg py-20-xs py-0-lg">
            <img src="assets/images/manipulator/manipulator-type.svg" class="text-center-lg" alt="l-образные">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col">
            <h3 class="text-24-xs mb-16-xs">По типу подвески</h3>
            <p class="text-16-xs">Жесткая с гидравлическим управлением и гибкая — с применением лебедочного троса.</p>
          </div>
          <div class="col-auto margin-auto-lg py-20-xs py-0-lg">
            <img src="assets/images/manipulator/manipulator-type.svg" alt="подвеска">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col">
            <h3 class="text-24-xs mb-16-xs">По разновидности шасси</h3>
            <p class="text-16-xs">автомобильная, железнодорожная, рельсовая и даже плавучая платформа. При этом наиболее ходовым вариантом является грузовой автомобиль (например, камаз) с крановой установкой.</p>
          </div>
          <div class="col-auto margin-auto-lg py-20-xs py-0-lg">
            <img src="assets/images/manipulator/manipulator-type.svg" alt="шасси">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col">
            <h3 class="text-24-xs mb-16-xs">По максимальной грузоподъемности</h3>
            <p class="text-16-xs">Краны-манипуляторы до 1,5 тонн, от 3 до 5 тонн и свыше 5 тонн.</p>
          </div>
          <div class="col-auto margin-auto-lg py-20-xs py-0-lg">
            <img src="assets/images/manipulator/manipulator-type.svg" alt="Грузоподъемность">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">С «AKRO» ваша логистика станет эффективнее</h2>

    <app-feedback
      [title]="'Стоимость услуги манипулятора зависит от выбранной вами техники, грузоподъемности \n'+
'и вылета стрелы'"
      [description]="'Для того, чтобы узнать точную цену на услугу грузоперевозка с манипулятором, вы можете\n'+
'оставить онлайн заявку на сайте'"
    >
    </app-feedback>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">В каких случаях могут потребоваться услуги манипуляторов</h2>
    <div class="row text-16-xs mb-48-xs">
      <div class="col-auto">
        Мы на 100% гарантируем сохранность вашего груза.
        Кран-манипулятор обладает высокой маневренностью и выполнит свою задачу даже в ограниченном пространстве. Все наши водители имеют большой опыт взаимодействия с подобной техникой, поэтому без труда выполнят работы любой сложности.
      </div>
      <div class="col-auto">
        При помощи манипуляторов мы не только осуществим погрузочно-разгрузочные работы с любыми видами габаритных и тяжеловесных грузов, но и выполним их доставку в место назначения.
      </div>
    </div>
    <div class="row">
      <div class="col pr-50-xs">
        <ul class="text-18-xs">
          <li class="mb-24-xs">Если вы хотите сэкономить время и денежные средства. </li>
          <li class="mb-24-xs">Если необходимо перевти контейнеры, станкои и промышленное оборудование, специализированную технику, бытовку и металлоконструкции, строительные материалы (например, железо-бетонных плит, блоков или кирпича), рекламные стенды или другие конструкции, трубы, машины, гаражи и других негабаритные объекты и тяжелые грузы.</li>
          <li>Подъем грузов на высоту многоэтажного здания,
            мы можем сделать это при помощи специальной автовышки с рабочей платформой.</li>
        </ul>
      </div>
      <div class="col text-16-xs">
        <p class="row">Аренда крана-манипулятора в транспортной компании «Акро» позволит вам быстро, недорого и без проблем решить определенные задачи для вашего бизнеса или какие-то повседневные вопросы. </p>
        <p class="mt-30-xs row">В нашем автопарке есть автомобили, которые подходят
          для любых видов грузов и для любых целей. Минимальное время аренды техники. у нас составляет 7  часов
          (сюда же включается 1 час на время подачи авто).</p>
        <a href=""
           role="button"
           class="row btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
          Заказать перевозку
        </a>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как оформить заявку на аренду крана-манипулятора</h2>
    <div class="row text-16-xs mb-32-xs">
      <div class="col-auto">
        Для заказа манипулятора в Москве или Московской области мы предлагаем вам выбрать один из удобных способов:
      </div>
    </div>
    <div class="row">
      <div class="col my-20-xs">
        <div class="border-blue b-r-16-xs pt-40-xs pb-40-xs pr-34-xs pl-34-xs height-to-full text-center">
          <img src="assets/images/icon/leave-a-request-for-a-callback.svg" class="mb-36-xs" alt="заявка">
          <h3 class="text-24-xs mb-18-xs">Оставить заявку на обратный звонок</h3>
          <p class="text-18-xs">Заполнив форму обратной связи на сайте</p>
        </div>
      </div>
      <div class="col my-20-xs">
        <div class="border-blue b-r-16-xs pt-40-xs pb-40-xs pr-34-xs pl-34-xs height-to-full text-center">
          <img src="assets/images/icon/create-a-new-request-in-myAlpari.svg" alt="личный кабинет">
          <h3 class="text-24-xs mb-18-xs">Создать новую заявку в личном кабинете</h3>
          <p class="text-18-xs">Оставив заявку в личном кабинете</p>
        </div>
      </div>
      <div class="col my-20-xs">
        <div class="row border-blue b-r-16-xs pt-40-xs pb-40-xs pr-34-xs pl-34-xs height-to-full text-center m-0-xs align-content-end">
          <img src="" class="mb-36-xs">
          <h3 class="text-24-xs mb-18-xs">Написать нам на электронную почту</h3>
          <p class="text-18-xs">Напишите нам на нашу электронную почту  info@tk-akro.ru</p>
        </div>
      </div>
    </div>
    <div class="row mt-24-xs">
      <div class="col-12 col-lg pb-20-xs pb-0-lg">
        После уточнения всей информации, наш менеджер подбирает для вас оптимальный транспорт и делает просчет стоимости вашего заказа. Затем мы заключаем с вами договор и в назначенный срок оказываем услугу.
      </div>
      <div class="col">
        Мы работаем как по предоплате, так и по системе постоплаты.
        при первом заказе  для вас может быть согласована дополнительная скидка.
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Преимущества аренды манипуляторов у нас</h2>
    <div class="row">
      <div class="col pr-50-xs">
        <ul class="text-18-xs">
          <li class="mb-24-xs">Большой выбор автомобилей с разными характеристиками для выполнения самых сложных задач</li>
          <li class="mb-24-xs">Гарантия сохранности ваших товаров и конфиденциальности ваших персональных данных</li>
          <li class="mb-24-xs">Индивидуальный подход к каждому бизнесу, самые выгодные условия работы, а также скидки при постоянном сотрудничестве;</li>
          <li>Удобный сервис: личный кабинет, в котором доступно отслеживание заявки в онлайн формате, персональный менеджер, который оперативно решит все ваши вопросы.</li>
        </ul>
      </div>
      <div class="col text-16-xs">
        <p class="row">Для того, чтобы арендовать манипулятор в Москве или МО, достаточно позвонить нам по телефону 8 (499) 350-90-02 или написать нам на почту info@tk-akro.ru</p>
        <p class="mt-30-xs row">Наш специалист ответит на все ваши вопросы, подберет требуемую вам лучшую технику для ваших нужд и сделает просчет в кратчайший срок.</p>
        <a href=""
           role="button"
           class="row btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
          Заказать перевозку
        </a>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
