import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-trucking-for-individuals',
  templateUrl: './trucking-for-individuals.component.html',
  styleUrls: ['./trucking-for-individuals.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingForIndividualsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки для физических лиц'
    }]
  }


  autoparkTwo = [{
    title: "Малый кузов",
    price: "2000 Р",
    extra_hour: "950 Р",
    include: "2 часа / 30 Км",
    overrun: "70 руб/км",
    driver_assistance: "1000 Р",
    number_europallets: "0,5т/2м³",
    image: "/assets/images/auto-park/auto-park-1.svg",
    alt: 'Малый',
    link: "/trucking-up-to-3-tons"
  },{
    title: "Средний кузов",
    price: "2300 Р",
    extra_hour: "950 Р",
    include: "2 часа / 30 Км",
    overrun: "70 руб/км",
    driver_assistance: "1000 Р",
    number_europallets: "0,5т/9м³",
    image: "/assets/images/auto-park/auto-park-2.svg",
    alt: 'Средний',
    link: "/trucking-up-to-3-tons"
  },{
    title: "Большой кузов",
    price: "2500 Р",
    extra_hour: "950 Р",
    include: "2 часа / 30 Км",
    overrun: "80 руб/км",
    driver_assistance: "1000 Р",
    number_europallets: "1,5т/12м³",
    image: "/assets/images/auto-park/auto-park-4.svg",
    alt: 'Большой',
    link: "/trucking-up-to-3-tons"
  }];

  workingCosts = {
    title: 'Стоимость работ <br>на услугу грузчиков',
    description: 'Зависит от габаритов груза, его веса и объема. В зависимости от этих параметров погрузку и разгрузку может осуществлять водитель-экспедитор или бригада грузчиков.',
    items: [{
      designation: "Погрузочно-разгрузочные работы в дневное время (07:00 до 22:00)",
      unit: "Чел./час",
      price: "400 ₽",
      min_price: "2 000 ₽"
    },{
      designation: "Погрузочно-разгрузочные работы в ночное время, выходные и праздничные дни",
      unit: "Чел./час",
      price: "450 ₽",
      min_price: "2 250 ₽"
    }]
  };
}
