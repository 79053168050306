import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {fromEvent, tap} from 'rxjs';
import {PopupService} from 'src/app/popup.service';
import {PhoneNumberValidator} from 'src/app/validators/phone-number.validator';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {CallbackClientApiService} from "../../api/client/callback/callback.client.api.service";
import {CallbackClientApiRequestInterface} from "../../api/client/callback/callback.client.api.interface";
import {OfferClientApiRequestInterface} from "../../api/client/offer/offer.client.api.interface";
import {OfferClientApiService} from "../../api/client/offer/offer.client.api.service";
import {DOCUMENT} from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'app-popup-kp',
  templateUrl: './popup-kp.component.html',
  styleUrls: ['./popup-kp.component.less'],
})
export class PopupKpComponent {
  @ViewChild('submitBtn') public submitBtn!: ElementRef<HTMLButtonElement>;
  public isLoading: boolean = false;
  public isSended: boolean = false;
  public readonly kpForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    phone: new FormControl('',
      Validators.required,
      PhoneNumberValidator('RU')
    ),
    privacy: new FormControl<boolean>(false, Validators.requiredTrue),
  });

  constructor(
    public readonly popupService: PopupService,
    private readonly offerClientApiService: OfferClientApiService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  ngAfterViewInit(): void {
    fromEvent(this.submitBtn.nativeElement, 'click').pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.kpForm.valid) {
        this.kpForm.markAllAsTouched();
      }
    })
  }

  public onSubmit(): void {
    this.isLoading = true;
    const data: OfferClientApiRequestInterface = {
      name: this.kpForm.get('name')!.value!.charAt(0).toUpperCase()
        + this.kpForm.get('name')!.value!.slice(1),
      email: this.kpForm.get('email')!.value!,
      phone: '+7' + this.kpForm.get('phone')!.value!,
      token: '',
    };
    // @ts-ignore
    const smartCaptcha = this.document["smartCaptcha"];
    // @ts-ignore
    const captchaWidgetId = this.document["containerKpWidget"];
    smartCaptcha.subscribe(captchaWidgetId, 'success', (token: string) => {
      this.offerClientApiService.handle({...data, token}).pipe(
        untilDestroyed(this),
        tap((_) => this.isLoading = false),
        tap((_) => this.isSended = true),
      ).subscribe()
    })
    smartCaptcha.subscribe(captchaWidgetId, 'challenge-hidden', () => {
      if (this.isLoading) {
        this.isLoading = false;
      }
    })
    smartCaptcha.subscribe(captchaWidgetId, 'network-error', () => {
      this.isLoading = false;
    })
    // @ts-ignore
    smartCaptcha.execute(captchaWidgetId);
  }

  public setDefaults(): void {
    this.kpForm.get('name')!.setValue('');
    this.kpForm.get('email')!.setValue('');
    this.kpForm.get('phone')!.setValue('');
    this.kpForm.get('privacy')!.setValue(false);
    this.kpForm.get('name')!.markAsUntouched();
    this.kpForm.get('email')!.markAsUntouched();
    this.kpForm.get('phone')!.markAsUntouched();
    this.kpForm.get('privacy')!.markAsUntouched();
    this.kpForm.get('name')!.markAsPristine()
    this.kpForm.get('email')!.markAsPristine();
    this.kpForm.get('phone')!.markAsPristine();
    this.kpForm.get('privacy')!.markAsPristine();
    this.isLoading = false;
    this.isSended = false;
  }
}
