<footer class="container text-14-xs mt-50-xs mt-100-md pb-20-xs pb-0-md" itemscope="http://schema.org/WPFooter" ng>
  <div class="row flex-xs-nowrap pr-20-xs pl-20-xs p-0-md">
    <div class="col-12 col-md-auto mb-40-xs mb-0-md">
      <app-logo-svg alt="logo image" class="pb-2-xs" itemprop="logo"></app-logo-svg>
      <p class="text-18-xs m-0-xs pb-46-xs" itemprop="name">Транспортная компания</p>
      <p class="text-grey2 m-0-xs" itemprop="address" itemscope="http://schema.org/PostalAddress">
        <span itemprop="postalCode">107553</span>, город Москва, <br> ул Черкизовская Б., д. 24а стр. 1
      </p>
    </div>

    <div class="d-none d-md-flex col-auto p-0-xs ml-100-xs mr-50-xs">
      <div class="bg-grey width-to-minimal height-to-full"></div>
    </div>

    <nav class="col-12 col-md text-blue2 pt-0-xs px-3-xs">
      <div class="row justify-content-between align-items-start">
        <div class="nav-item col-auto align-self-center">
          <div class="row">
            <ul class="column align-items-center list-group-numbered m-0-xs col-md-6" [ngClass]="{'col-lg-10': key === 0, 'col-lg-2': key === 1}" *ngFor="let items of menu; index as key">
              <div class="col-12 text-18-xs fw-semibold pb-4">{{items['title']}}</div>
            </ul>
          </div>
          <div class="row">
            <div class="align-items-center list-group-numbered m-0-xs col-md-6" [ngClass]="{'col-lg-10': key === 0, 'col-lg-2': key === 1}" *ngFor="let items of menu; index as key">
              <div class="row">
                <ul class="col-12-xs col-lg column align-items-center list-group-numbered m-0" *ngFor="let item of items['items']">
                  <li class="nav-item py-2" *ngFor="let element of item">
                    <a href="{{element.link}}" class="nav-link text-purple" aria-current="page">
                      {{element.title}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</footer>
