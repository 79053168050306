<app-banner
  [image]="'/assets/images/jobs/jobs.svg'"
  [title]="'Вакансии'"
  [description]="'Нам с вами по пути!'"
  [showButtonDriverJob]="true"
>
</app-banner>


<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="row align-items-end m-0-xs justify-content-between flex-column flex-lg-row">
      <div class="col text-16-xs py-32-xs px-40-xs cursor-pointer b-r-16-xs bg-purple mr-20-lg border-grey3 text-blue d-flex mb-20-xs mb-0-lg"
           [ngClass]="{'bg-blue text-white b-r-6-xs': 1 === this.tabActive}"
           (click)="changeTabsActive(1)">
        <img src="assets/images/jobs/tabs-1.svg" class="col-auto mr-20-xs img-svg" [ngClass]="{'d-none': 1 === this.tabActive}" alt="Специалист"/>
        <img src="assets/images/jobs/tabs-white-1.svg" class="col-auto mr-20-xs img-svg" *ngIf="1 === this.tabActive" alt="Специалист"/>
        <h3 class="text-24-xs fw-normal m-0 d-flex align-items-center">Специалистам</h3>
      </div>
      <div class="col text-16-xs py-32-xs px-40-xs cursor-pointer b-r-16-xs bg-purple mr-20-lg border-grey3 text-blue d-flex mb-20-xs mb-0-lg"
           [ngClass]="{'bg-blue text-white b-r-6-xs': 2 === this.tabActive}"
           (click)="changeTabsActive(2)">
        <img src="assets/images/jobs/tabs-3.svg" class="col-auto mr-20-xs img-svg" [ngClass]="{'d-none': 2 === this.tabActive}" alt="Водитель"/>
        <img src="assets/images/jobs/tabs-white-3.svg" class="col-auto mr-20-xs img-svg" *ngIf="2 === this.tabActive" alt="Водитель"/>
        <h3 class="text-24-xs fw-normal m-0 d-flex align-items-center">Водителям на авто компании</h3>
      </div>
      <div class="col text-16-xs py-32-xs px-40-xs cursor-pointer b-r-16-xs bg-purple border-grey3 text-blue d-flex mb-20-xs mb-0-lg"
           [ngClass]="{'bg-blue text-white b-r-6-xs': 3 === this.tabActive}"
           (click)="changeTabsActive(3)">
        <img src="assets/images/jobs/tabs-2.svg" class="col-auto mr-20-xs img-svg" [ngClass]="{'d-none': 3 === this.tabActive}" alt="Водитель с авто"/>
        <img src="assets/images/jobs/tabs-white-2.svg" class="col-auto mr-20-xs img-svg" *ngIf="3 === this.tabActive" alt="Водитель с авто"/>
        <h3 class="text-24-xs fw-normal m-0 d-flex align-items-center">Водители с собственным авто</h3>
      </div>
  </div>
    <div class="col text-16-xs py-32-xs px-20-xs px-40-md cursor-pointer b-r-16-xs border-blue mt-32-xs"
         *ngIf="1 === this.tabActive">
      <div class="row flex-column flex-sm-row">
        <h3 class="col text-blue text-24-xs mb-20-xs">Менеджер по продажам</h3>
        <div class="col text-24-xs text-grey5 d-flex justify-content-start justify-content-md-end mb-20-xs">ООО ТК АКРО</div>
      </div>
      <div class="row flex-column flex-sm-row">
        <div class="col text-20-xs text-grey5 mb-20-xs">Требуемый опыт работы: 1–3 года Полная занятость, полный день</div>
        <div class="col text-24-xs d-flex justify-content-start justify-content-md-end ">от 80 000 до 200000 руб. на руки</div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Обязанности:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Привлечение новых клиентов (входящие звонки, обработка готовой базы);</li>
              <li class="mb-10-xs">Выявление и анализ ключевых потребностей клиентов;</li>
              <li class="mb-10-xs">Подготовка коммерческих предложений;</li>
              <li class="mb-10-xs">Еженедельная отчетность;</li>
              <li>Работа в crm (Битрикс 24).</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Требования:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Опыт в продажах от 3х лет;</li>
              <li class="mb-10-xs">Грамотная устная и письменная речь;</li>
              <li class="mb-10-xs">Умение вести переговоры как в очной, так и телефонной беседе;</li>
              <li class="mb-10-xs">Знание техник продаж;</li>
              <li>Самоорганизованность.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Условия:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Офис в шаговой доступности от м.Черкизовская либо от МЦК Локомотив;</li>
              <li class="mb-10-xs">График работы 5/2;</li>
              <li class="mb-10-xs">Трудоустройство по ТК РФ;</li>
              <li class="mb-10-xs">Дружная атмосфера и молодой коллектив;</li>
              <li>Стабильный доход, который складывается из оклада + % от продаж, без границ. Оклад обсуждается по результатам собеседования). Выплаты 2 раза в месяц.</li>
            </ul>
          </div>
        </div>
      </div>
      <a (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.DRIVER_JOB)" role="button" class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs ng-star-inserted"> Откликнуться на вакансию </a>
    </div>
    <div class="col text-16-xs py-32-xs px-20-xs px-40-md cursor-pointer b-r-16-xs border-blue mt-32-xs"
         *ngIf="1 === this.tabActive">
      <div class="row flex-column flex-sm-row">
        <h3 class="col text-blue text-24-xs mb-20-xs">Менеджер по работе с клиентами</h3>
        <div class="col text-24-xs text-grey5 d-flex justify-content-start justify-content-md-end mb-20-xs">ООО ТК АКРО</div>
      </div>
      <div class="row flex-column flex-sm-row">
        <div class="col text-20-xs text-grey5 mb-20-xs">Требуемый опыт работы: 3–6 лет Полная занятость, полный день</div>
        <div class="col text-24-xs d-flex justify-content-start justify-content-md-end">от 70 000 до 100 000 руб. на руки</div>
      </div>
      <p class="mt-20-xs">Москва, Локомотив, Черкизовская<br><br>Мы — транспортная компания АКRО. Наша компания занимается перевозками грузов по Москве и области для юридических лиц. Как Менеджер по работе с клиентами вы должны иметь опыт работы в транспортной логистике и в общении с корпоративными клиентами.</p>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Обязанности:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Сопровождение действующих клиентов;</li>
              <li class="mb-10-xs">Развитие закреплённой базы клиентов;</li>
              <li class="mb-10-xs">Активно взаимодействие с клиентами и консультирование по всем вопросам;</li>
              <li class="mb-10-xs">Развитие прочных долгосрочных партнерских отношения с клиентами;</li>
              <li class="mb-10-xs">Повышение лояльности клиентов и улучшение качества сервиса;</li>
              <li>Взаимодействие со смежными подразделениями.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Требования:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Опыт работы с корпоративными клиентами от 3-х лет, предпочтительно в сфере грузоперевозок;</li>
              <li class="mb-10-xs">Грамотная устная и письменная речь;</li>
              <li class="mb-10-xs">Опыт работы с аналитическими отчетами;</li>
              <li class="mb-10-xs">Высокий уровень клиентоориентированности;</li>
              <li>Желание развиваться вместе с компанией.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Условия:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Оклад(обсудим индивидуально) + KPI, целевой доход от 70 000, без границ;</li>
              <li class="mb-10-xs">Выплаты заработной платы 2 раза в месяц на карту Альфабанка по зарплатному проекту;</li>
              <li class="mb-10-xs">Официальное оформление по ТК РФ с первого дня;</li>
              <li class="mb-10-xs">Офис в шаговой доступности от м.Черкизовская или от МЦК Локомотив;</li>
              <li class="mb-10-xs">Рабочий день с 9:00 до 19:00, график работы 5/2;</li>
              <li>Дружная атмосфера и молодой коллектив.</li>
            </ul>
          </div>
        </div>
      </div>
      <a (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.DRIVER_JOB)" role="button" class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs ng-star-inserted"> Откликнуться на вакансию </a>
    </div>
    <div class="col text-16-xs py-32-xs px-20-xs px-40-md cursor-pointer b-r-16-xs border-blue mt-32-xs"
         *ngIf="2 === this.tabActive">
      <div class="row flex-column flex-sm-row">
        <h3 class="col text-blue text-24-xs mb-20-xs">Водитель-экспедитор на авто компании Категории B</h3>
        <div class="col text-24-xs text-grey5 d-flex justify-content-start justify-content-md-end mb-20-xs">ООО ТК АКРО</div>
      </div>
      <div class="row flex-column flex-sm-row">
        <div class="col text-20-xs text-grey5 mb-20-xs">Требуемый опыт работы: 1–3 года Полная занятость, полный день</div>
        <div class="col text-24-xs d-flex justify-content-start justify-content-md-end ">от 50 000 до 120 000 руб. на руки</div>
      </div>
      <p class="mt-20-xs">Транспортной компании АКРО требуется водитель на грузовой̆ автомобиль компании Автомобиль будет закреплен только за Вами. Парковка домашняя</p>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Обязанности:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Транспортировка и сдача груза по Москве и МО на складах, в РЦ, магазинах и транспортных компаниях;</li>
              <li class="mb-10-xs">Работа с сопроводительными документами;</li>
              <li class="mb-10-xs">Сопровождение груза: приемка, экспедирование, сдача груза грузополучателю, оформление погрузо-загрузочных документов;</li>
              <li>Бережное отношение и уход за автомобилем.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Требования:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Опыт работы на грузовом автомобиле (не менее 3х лет);</li>
              <li class="mb-10-xs">Опыт вождения грузовых авто по Москве;</li>
              <li class="mb-10-xs">Хорошее знание Москвы и Московской области;</li>
              <li class="mb-10-xs">Наличие смартфона;</li>
              <li>Пунктуальность, внимательность, ответственность.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
        <span class="text-20-xs mb-16-xs p-0-xs">Условия:</span>
        <div class="row">
          <div class="col-12 col-lg pr-50-lg mt-20-xs">
            <ul class="text-18-xs text-grey9 dashed">
              <li class="mb-10-xs">Оклад +дополнительные выплаты + оплата переработок;</li>
              <li class="mb-10-xs">Стабильные выплаты 2 раза в месяц без задержек;</li>
              <li class="mb-10-xs">График работы 5/2, 8-ми часовой рабочий день;</li>
              <li>Заправка по топливной карте компании, мойка авто у наших партнеров, обслуживание авто у дилера ( все за счет компании).</li>
            </ul>
          </div>
        </div>
      </div>
      <a (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.DRIVER_JOB)" role="button" class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs ng-star-inserted"> Откликнуться на вакансию </a>
    </div>
    <div class="col text-16-xs py-32-xs px-20-xs px-40-md cursor-pointer b-r-16-xs border-blue mt-32-xs"
    *ngIf="3 === this.tabActive">
 <div class="row flex-column flex-sm-row">
   <h3 class="col text-blue text-24-xs mb-20-xs">Водитель с личным грузовым автомобилем</h3>
   <div class="col text-24-xs text-grey5 d-flex justify-content-start justify-content-md-end mb-20-xs">ООО ТК АКРО</div>
 </div>
 <div class="row flex-column flex-sm-row">
   <div class="col text-20-xs text-grey5 mb-20-xs">Транспортной̆ компании АКРО требуется водитель с личным грузовым автомобилем</div>
   <div class="col text-24-xs d-flex justify-content-start justify-content-md-end ">от 80 000 до 350 000 руб.</div>
 </div>
 <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
   <span class="text-20-xs mb-16-xs p-0-xs">Обязанности:</span>
   <div class="row">
     <div class="col-12 col-lg pr-50-lg mt-20-xs">
       <ul class="text-18-xs text-grey9 dashed">
         <li class="mb-10-xs">Доставка грузов по г. Москва и Московской области;</li>
         <li class="mb-10-xs">Пунктуальность;</li>
         <li class="mb-10-xs">Контроль погрузки и выгрузки перевозимого товара;</li>
         <li class="mb-10-xs">Работа с документами ТТН и ТН;</li>
         <li class="mb-10-xs">Уверенное вождение, спокойный стиль вождения;</li>
         <li class="mb-10-xs">Грамотная речь;</li>
         <li>Своевременная сдача документации.</li>
       </ul>
     </div>
   </div>
 </div>
 <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
   <span class="text-20-xs mb-16-xs p-0-xs">Требования:</span>
   <div class="row">
     <div class="col-12 col-lg pr-50-lg mt-20-xs">
       <ul class="text-18-xs text-grey9 dashed">
         <li class="mb-10-xs">Быть зарегистрированным как Индивидуальный предприниматель или самозанятый;</li>
         <li class="mb-10-xs">Наличие собственного грузового авто с грузоподьемностью от 500 кг до 20 тонн;</li>
         <li class="mb-10-xs">Объем грузового отсека не менее 2 м3;</li>
         <li class="mb-10-xs">Опыт работы на грузовом транспорте от 3 лет;</li>
         <li class="mb-10-xs">Хорошее знание Москвы и Московской области;</li>
         <li>Знание сопровождающих документов.</li>
       </ul>
     </div>
   </div>
 </div>
 <div class="pb-30-xs pb-30-lg pt-30-xs pt-30-lg pr-20-xs pr-0-lg pl-0-lg">
   <span class="text-20-xs mb-16-xs p-0-xs">Условия:</span>
   <div class="row">
     <div class="col-12 col-lg pr-50-lg mt-20-xs">
       <ul class="text-18-xs text-grey9 dashed">
         <li class="mb-10-xs">Работа по Москве и области;</li>
         <li class="mb-10-xs">Оплата почасовая в зависимости от категории авто;</li>
         <li>Еженедельные выплаты.</li>
       </ul>
     </div>
   </div>
 </div>
 <a (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.DRIVER_JOB)" role="button" class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs ng-star-inserted"> Откликнуться на вакансию </a>
</div>



  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [rowClass]="blockWithItems['rowClass']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row position-relative text-grey4">
      <div class="col-12 d-flex flex-column justify-content-between mb-16-xs">
        <div class="row p-20-xs bg-purple border-grey3 b-r-16-xs d-flex">
          <h3 class="col-12 mb-16-xs">Каких сотрудников мы ищем?</h3>
          <p class="col-12 mb-0">Если вы целеустремленный и ориентированный на результат человек, умеете правильно планировать свой день и расставлять задачи,
            а главное есть желание помогать и делать сервис лучше для наших клиентов - тогда работа в Акро даст вам множество возможностей
            для развития карьеры.</p>
        </div>
      </div>
      <div class="col-12 d-flex flex-column justify-content-between">
        <div class="row p-20-xs bg-purple border-grey3 b-r-16-xs d-flex">
          <h3 class="col-12 mb-16-xs">Как стать частью нашей команды?</h3>
          <p class="col-12 mb-0">Оставьте запрос на обратный звонок или подгрузите свое резюме у нас на сайте. Мы внимательно рассмотрим каждое резюме и свяжемся
            с вами в течение дня</p>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
  padding: 0 7px;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "—";
  text-indent: -5px;
  padding-right: 5px;
}
</style>

<app-popup-job></app-popup-job>
