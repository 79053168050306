import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SitemapPage {
}
