<div class="container pt-50-xs pt-60-lg pb-50-xs pb-60-lg">
  <div class="row text-lg-start pr-30-xs pr-0-lg pl-30-xs pl-0-lg px-15-lg">
    <span class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Личный кабинет: преимущества и возможности</span>
    <p class="mb-30-xs p-0">Для вашего удобства на сайте компании разработан личный кабинет клиента, в котором сохраняется история перевозок, возможно создание online-заявки, а также на основании предыдущих заказов всегда можно быстро и удобно создать новую заявку. Кроме того, индивидуальный подход к каждому клиенту позволяет нам обеспечивать наиболее выгодные для вас условия и долгосрочное сотрудничество.</p>
    <br><br>

    <mat-tab-group animationDuration="500ms" class="text-20-lg p-0-xs">
      <mat-tab label="Личный кабинет">
        <div class="row text-18-lg text-grey4 mt-30-xs m-0-xs">
          <div class="col-12 col-lg pr-50-lg p-0-xs">
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-1.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="устройства" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Вы можете оформить заявку в любое время и с любого устройства
              </p>
            </div>
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-2.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="История перевозок" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                История перевозок хранится на протяжении всего сотрудничества
              </p>
            </div>
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-3.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="Документы" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Все отгрузочные и сопроводительные документы доступны к скачиванию в течение 3 лет
              </p>
            </div>
          </div>
          <div class="col-12 col-lg p-0-xs">
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-4.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="Создание заявки" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Максимально быстрое создание новой заявки на основе сохраненных данных <br>
                Мы будем рады помочь Вам с переездом!                
              </p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="ЭДО">
        <div class="row text-18-lg text-grey4 mt-30-xs m-0-xs">
          <div class="col-12 col-lg pr-50-lg p-0-xs">
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-1.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="Устройства" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Оформление заявки в любое время и с любого устройства
              </p>
            </div>
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-2.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="История перевозок" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Вся история перевозок и маршрутов хранится на протяжении всего сотрудничества
              </p>
            </div>
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-3.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="Сканы" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Все сканы отгрузочных и сопроводительных документов подгружаются в ваш личный кабинет, и хранятся в нем 3 года. Вы можете их скачать, либо отправить на любую почту - например, вашему клиенту
              </p>
            </div>
          </div>
          <div class="col-12 col-lg p-0-xs">
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-4.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="Создание заявки" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                При повторном оформлении перевозки не потребуется ввод всех данных адреса загрузки или выгрузки, можно просто взять их из предыдущей перевозки
              </p>
            </div>
            <div class="row align-items-start pt-12-xs pb-12-xs pt-15-lg pb-15-lg m-0-xs">
              <img loading="lazy" src="assets/images/lk/lk-5.svg" class="col-auto w-36-xs p-0-xs pt-5-xs" alt="Подбор" />
              <p class="col p-0-xs pl-25-xs m-0-xs">
                Исключит ошибку при заказе транспорта, личный кабинет автоматически подберет Вам авто исходя из габаритов груза, учитывая все параметры.
              </p>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
