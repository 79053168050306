<app-banner
  [image]="'/assets/images/banner/banner-actions.svg'"
  [title]="'Акции и скидки'"
  [showButtonCall]="false"
  [showButtonKp]="false"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<app-actions-and-sales [items]="items"></app-actions-and-sales>
