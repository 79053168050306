<app-banner
  [image]="'/assets/images/banner/banner-trucking-up-to-twenty-tons.svg'"
  [title]="'Грузоперевозки <br>20 тонн'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page
  [description]="'Перевозка грузов фурами 20 тонн - одна из наиболее актуальных и востребованных услуг грузоперевозок на рынке автоперевозок. Транспортировка подобным видом автомобиля может осуществляться в междугородном сообщении по Москве, Московской области и другим регионам России.'"
>
</app-block-description-page>

  <div class="bg-purple b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

<app-tariff
  [carActive]="carActive"
  [tariffs]="tariffs"
  [typeActive]="typeActive"
>
</app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-30-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        При наличии ежедневных отгрузок или постоянных маршрутов, мы подготовим индивидуальный прайс со скидкой для вас.
        <br>
        <br>
        Подробную информацию можно получить по номеру телефона +7(499)350-90-02
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <p class="pr-20-xs pr-0-lg pl-20-xs pl-0-lg">Стоимость часа аренды фуры - 1 800 руб, при этом минимальная сумма - 14 400 руб. Грузоподъемность такого типа составляет до 20 тонн, а это значит, что если вам необходимо доставить объемный груз, фура станет отличным решением. Такая машина способна вместить до 32 европаллет (объем - до 82 м3, ширина и высота - 2,45 метров, длина 13 м). Важно помнить, что общая масса груза для доставки на европаллетах не должна превышать разрешенную массу.</p>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-xs text-grey4 mb-48-xs">Преимущества сотрудничества с нами</h2>
    <div class="row text-grey9 mb-40-xs">
      <div class="col-12 col-lg mb-20-xs mb-0-lg">
        <p class="text-16-xs">Наша компания в процессе своей работы успела зарекомендовать себя как честный партнер. При сотрудничестве с нами вы получаете:</p>
      </div>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-0-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Индивидуальный подход к стоимости и скидки</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img class="margin-auto-lg" loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-1.svg" alt="Индивидуальный подход">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Персонального менеджера, который всегда ответит на все ваши вопросы о цене, доставке и возьмет на себя в оформление всей сопроводительной документации.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img class="margin-auto-lg" loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-2.svg" alt="Персональный менеджер">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs">Удобный функционал, в котором вы легко можете создавать новые заявки на грузоперевозку и отслеживать текущие, хранить и скачивать документы. Также в нем есть калькулятор, которым можно воспользоваться для формирования стоимости перевозки за км.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img class="margin-auto-lg" loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-3.svg" alt="Личный кабинет">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Большое разнообразие транспорта для грузоперевозок с грузоподъемностью 500 кг, 1-3 тонны, 10-20 тонн. Вы можете выбрать тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши профессионалы всегда рядом.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img class="margin-auto-lg" loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-4.svg" alt="Автопарк">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Квалифицированные специалисты</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        Все рабочие процессы оперативно осуществляются опытными профессионалами с большим стажем. Наши сотрудники проходят тщательный отбор: каждый из них имеет определенный объем квалификаций и большой опыт по специфике. Ежедневно наши профессиональные водители доставляют до 800 тонн груза.
        <br><br>
        Работу водителей в процессе перевозки контролирует служба отдела качества. Перед началом перевозки вашего груза водитель в обязательном порядке проходит процедуру инструктажа, а также систематически - дополнительно  специальное обучение по грузоперевозкам. В нашей команде работают только профессионалы. Ответственность и высокое качество - девиз нашей команды!
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">У нас большой опыт работы в перевозках:</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        7 лет - средний стаж наших специалистов в логистике. Накопленный годами опыт позволяет не только проработать подходящий маршрут для любого направления, но и сэкономить ваши денежные и временные ресурсы. «АКРО» - идеальный выбор для представителей любых сфер малого, среднего и крупного бизнеса, которым нужны срочные грузоперевозки по Москве и Подмосковью. Наши заказчики всегда довольны и говорят нам “спасибо” - почитайте отзывы
        <br><br>
        Наша организация заботится о безопасности вашего груза: мы берем на себя заключение страхового договора, обеспечиваем самые выгодные условия страхования груза и несем полную ответственность за ваш груз на всех этапах перевозки.
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">С «AKRO» ваша логистика станет эффективнее</h2>

    <div class="row mb-30-xs">
      <div class="col-12 col-lg-6 pb-20-xs pb-0-lg">
        Менеджеры компании «АКРО» всегда готовы проконсультировать и помочь подобрать подходящую машину, отталкиваясь от типа груза.
      </div>
      <div class="col-12 col-lg-6">
        Если вы хотите заранее обсудить все детали и составить точный заказ, позвоните нам или воспользуйтесь функцией звонка от компании на сайте — мы свяжемся с вами в течение трех минут и расчитаем стоимость перевозки.
      </div>
    </div>

    <app-feedback
      [title]="'Заполните форму обратной связи и получите:'"
      [description]="'— Расчет стоимости доставки <br> — Ориентировочные сроки'"
    >
    </app-feedback>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-block-description-page
    [title]="'Нужен транспорт для грузоперевозки 20 тонн в Москве и Московской области?'"
    [description]="'«АКРО» - надежный и честный партнер! Оставьте заявку, и мы перезвоним вам в через<br> 2 минуты! <br><br>'"
    [image]="'assets/images/trucking-up-to-three-tons/trucking-up-to-three-tons.svg'"
    [leaveRequest]="true"
  >
  </app-block-description-page>

  <app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Ответы на часто задаваемые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>

</div>
