<app-banner
  [image]="'/assets/images/banner/banner-trucking-furies.svg'"
  [title]="'Грузоперевозки <br> Фуры'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-20-xs pb-20-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        При наличии ежедневных отгрузок или постоянных маршрутов, мы подготовим индивидуальный прайс со скидкой для вас.
        <br>
        <br>
        Подробную информацию можно получить по номеру телефона +7(499)350-90-02
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row">
      <div class="col-12 col-lg-5 pr-0-xs pr-50-lg mb-20-xs mb-0-lg">
        <h2 class="text-48-lg mb-32-xs text-grey4">Характеристики фуры</h2>
        <span class="text-18-xs">
          Грузоподъемность фуры составляет до 20 тонн, а это значит, что если вам нужно доставить объемный груз, то фура станет оптимальным решением.
          Кузов такой машины может вместить до 82 кубов.
          Важно помнить, что общая масса груза на европаллетах
          не должна превышать разрешенную
          массу автомобиля.
        </span>
      </div>
      <div class="col-12 col-lg-7">
        <img loading="lazy" src="assets/images/trucking-furies/trucking-furies-1.svg" class="mb-20-xs mb-0-lg" alt="Фура">
        <div class="row text-center">
          <div class="row col-12 col-lg bg-purple border-grey3 b-r-16-xs text-20-xs m-0-xs mb-20-xs mr-24-lg p-22-xs text-blue">
              <span class="p-0-xs align-self-center">до 32 европаллет</span>
          </div>
          <div class="row col-12 col-lg bg-purple border-grey3 b-r-16-xs text-20-xs m-0-xs mb-20-xs mr-24-lg p-22-xs text-blue">
              <span class="p-0-xs align-self-center">длина 13 м</span>
          </div>
          <div class="row col-12 col-lg bg-purple border-grey3 b-r-16-xs text-20-xs m-0-xs mb-20-xs p-22-xs text-blue">
              <span class="p-0-xs align-self-center">ширина <br> и высота 2,45 м</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
