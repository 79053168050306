import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-page-shipping-on-yandex-market',
  templateUrl: './shipping-on-yandex-market.component.html',
  styleUrls: ['./shipping-on-yandex-market.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ShippingOnYandexMarketPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/marketplejsy',
      linkTitle: 'Доставка до маркетплейсов'
    }, {
      link: '',
          linkTitle: 'Доставка на маркетплейс Яндекс Маркет'
        }]
  }

  description2 = 'Наряду с Wildberries и Ozon, Яндекс Маркет - один из крупнейших интернет-магазинов в России. Это площадка, которая объединяет свыше 25 000 продавцов, с ежедневной аудиторией более 6 млн человек. Реализовывать свой товар не только на вайлдберриз и озон, но и на Яндекс Маркет на сегодняшний день нужно каждому бизнесу, чтобы повысить свои продажи - для любого предложения здесь найдется свой покупатель. Услуги по доставке до распределительного центра Яндекс Маркет оказываются нами «под ключ», что подразумевает полное сопровождение и поддержку на всех этапах от оформления заказа до возврата загрузочных документов.'

  typeActive  =  0;
  typeTitle = 'Выберите количество паллет';
  header = [{
    title: 'Кол-во паллет',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  },{
    title: 'РЦ Софьино',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  },{
    title: 'РЦ Томилино',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  }
  // ,{
  //   title: '',
  //   class: 'col-12 col-md p-0-xs pr-15-xs'
  // }
  // ,{
  //   title: 'Подача за МКАД',
  //   class: 'col-12 col-md p-0-xs pr-15-xs'
  // },{
  //   title: 'Простой более 3-х<br>часов на маркетплейсе',
  //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md p-0-xs'
  // }
  ]

  description1 = 'Наш график работы позволяет не только принимать запросы на доставку на следующий день после оформления поставки, но и срочные заказы на текущую дату. Чтобы получить более подробную консультацию по доставке до маркетплейса Яндекс Маркет и тарифам, у вас есть возможность заполнить данные на доставку, и наши менеджеры свяжутся с вами в самое ближайшее время. Также ориентировочные сроки и стоимость доставки вы можете узнать по телефону 8 (499) 350-90-02.'

  tariffs = [{
    title: 'до 6 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_6.svg',
      alt: 'Паллета 6',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 1,5 тонн</span> <span class="d-block">До 6 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '9300 ₽',
        ndsPrice: '10700 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }, {
        price: '8100 ₽',
        ndsPrice: '9300 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // , {
      //   price: '-',
      //   ndsPrice: '-',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // }, {
      //   price: '22 ₽/км',
      //   ndsPrice: '25 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // }, {
      //   price: '770 ₽/ч',
      //   ndsPrice: '880 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]

    },{
    title: '7-8 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_7-8.svg',
      alt: 'Паллета 7-8',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 3 тонн</span><span class="d-block">7-8 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '10500 ₽',
        ndsPrice: '12100 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '9050 ₽',
        ndsPrice: '10400 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // ,{
      //   price: '-',
      //   ndsPrice: '-',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '26 ₽/км',
      //   ndsPrice: '30 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '850 ₽/ч',
      //   ndsPrice: '980 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  },{
    title: '9-10 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_9-10.svg',
      alt: 'Паллета 9-10',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 3 тонн</span><span class="d-block">9-10 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '12300 ₽',
        ndsPrice: '14200 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '10600 ₽',
        ndsPrice: '12200 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // ,{
      //   price: '-',
      //   ndsPrice: '-',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '26 ₽/км',
      //   ndsPrice: '30 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '850 ₽/ч',
      //   ndsPrice: '980 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  },{
    title: '11-14 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_11-14.svg',
      alt: 'Паллета 11-14',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 5 тонн</span><span class="d-block">11-14 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '13700 ₽',
        ndsPrice: '15800 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '12000 ₽',
        ndsPrice: '13800 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // ,{
      //   price: '-',
      //   ndsPrice: '-',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '26 ₽/км',
      //   ndsPrice: '30 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '850 ₽/ч',
      //   ndsPrice: '980 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  }, {
    title: '15-17 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_15-17.svg',
      alt: 'Паллета 15-17',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 10 тонн</span><span class="d-block">15-17 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '18100 ₽',
        ndsPrice: '20900 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '15600 ₽',
        ndsPrice: '18000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // ,{
      //   price: '-',
      //   ndsPrice: '-',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '48 ₽/км',
      //   ndsPrice: '55 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '1200 ₽/ч',
      //   ndsPrice: '1400 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  },{
    title: '18-32 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_18-32.svg',
      alt: 'Паллета 18-32',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 20 тонн</span><span class="d-block">18-32 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '25400 ₽',
        ndsPrice: '29200 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '22600 ₽',
        ndsPrice: '26000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // ,{
      //   price: '-',
      //   ndsPrice: '-',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '50 ₽/км',
      //   ndsPrice: '60 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },{
      //   price: '1750 ₽/ч',
      //   ndsPrice: '2000 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  }]

  description = 'С ТК «Акро» вы можете быть уверены, что доставка заказов на маркетплейсы озон, яндекс, вайлдберис будет осуществлена быстро и аккуратно. Нас выбирают владельцы малого и среднего бизнеса из таких мегаполисов как Москва, Санкт-Петербург и других городов России. Мы гарантируем отличный сервис и качество нашей работы, о чем свидетельствуют отзывы наших довольных клиентов! \n' +
    '\n' +
    '<br><br>Минимальный тариф на доставку до склада Яндекс Маркет составляет от 3660 ₽ без НДС (1-4 паллет, склад Софьино). Наши тарифы и другая полезная информация доступны в соответствующих разделах. Получить ответы на все вопросы вы также можете от специалиста. Ваша логистика - это наша забота.'

  blockWithItems = {
    title: 'Требования к упаковке',
    description: '' +
      '<div class="row">' +
        '<div class="col">Доставкой до складов Яндекс Маркет занимаются высококвалифицированные сотрудники, которые детально знакомы с регламентом работы всех площадок e-commerce. Перед тем, как оформить забор вашего груза или перед доставкой его на наш склад, обязательно проверьте нанесена ли маркировка:</div>' +
      '</div>',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'каждая единица при доставке должна быть упакована в чистую индивидуальную коробку без повреждений;',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'на каждом товаре необходим штрих-код;',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'дополнительные документы, которые прилагаются к товару, должны быть вложены в соответствующую коробку;',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'упаковка должна соответствовать типу и размерам;',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'на паллеты или коробки должны быть наклеены ярлыки, распечатанные из личного кабинета поставщика.',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems2 = {
    title: 'Какую документацию подготовить перед поставкой продукции на Яндекс Маркет',
    description: 'Помимо правильного оформления, требуется также подготовить и предоставить нашим сотрудникам сопроводительные документы:',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'акт приема -передачи (для транзитных складов в трех экземплярах, для обычных достаточно двух);',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'если ваша организация работает без печати, следует обеспечить предоставление копии соответствующего уведомления с подписью директора;',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'закажите пропуск для водителя на странице маркетплейса (не позднее, чем за 2 часа до момента поставки).',
      class: 'p-20-xs border-blue'
    }]
  }

  blockWithItems3 = {
    title: 'Оставьте заявку',
    description: '',
    class: 'col-12 col-md-6 col-lg-4 border-bottom',
    items: [{
      image: '',
      title: '',
      description: 'Маркетплейс Яндекс Маркет имеет несколько складов по всей России. Каждый пункт приема предъявляет свои требования к габаритам коробок и паллет, а также к транспорту.',
      class: ''
    },{
      image: '',
      title: '',
      description: 'При транспортировке груз должен быть максимально плотно и аккуратно уложен на паллете и не выступать за ее края. Вес каждого товара не может превышать 100 кг (на некоторых складах допускается вес до 250 кг).  Сдача на склад получателя производится нашими штатными экспедиторами, которые в полном объеме представляют ваши интересы. Также специалисты нашей компании полностью контролируют корректность и своевременность получения товарно-сопроводительной документации.',
      class: ''
    },{
      image: '',
      title: '',
      description: 'При размещении поставки обязательно учитывайте совместимость товаров: несовместимые должны быть изолированы друг от друга. Для того, чтобы при приемке вашей продукции в распределительном центре Яндекс Маркет не возникло проблем, перед отправкой мы еще раз тщательно проверяем правильность упаковки.',
      class: ''
    }]
  }

  blockWithItems4 = {
    title: 'Почему выбирают «АКРО»',
    description: 'Наша фирма в процессе своей работы успела зарекомендовать себя как надежный и честный партнер для ИП и юр лиц. Нас выбирают, потому что мы предлагаем:',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Индивидуальный подход к подсчету стоимости тарифа: специальные цены, акции и скидки постоянным клиентам (при регулярных поставках цены ниже). Мы контролируем все этапы доставки от погрузки до выгрузки на складах в Москве, Московской обл и других городах РФ',
      class: 'col-12'
    },{
      image: '',
      title: '',
      description: 'Помощь персонального специалиста, который даст ответы на все ваши вопросы о доставке, тарифах и построит оптимальный маршрут. Поддержка на всех этапах. Мы работаем по всей стране: забираем товары в Москве и области, отвозим на склады Озон по всей России.',
      class: 'col-12'
    },{
      image: '',
      title: '',
      description: 'Удобный личный кабинет, в котором вы легко можете создавать новые заявки на доставку и отследить текущие, хранить и скачивать документы (архив хранится в течение 3 лет). Также в нем есть калькулятор, которым можно воспользоваться для расчета стоимости перевозки за км.',
      class: 'col-12'
    },{
      image: '',
      title: '',
      description: 'Большое разнообразие транспортных средств для аренды: наши авто доставят моно- и микс-паллеты, малогабаритный и крупногабаритный груз от 1 кг до 20 тонн. Вы можете выбрать например тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши сотрудники всегда готовы помочь с выбором.',
      class: 'col-12'
    }]
  }

  tags = {
    title: 'Теги',
      items: [{
      class: '',
      title: '',
      children: [{
        title: 'Доставка груза на маркетплейсы',
        link: '/marketplejsy'
      },{
        title: 'Доставка груза на Ozon',
        link: '/marketplejsy/ozon/'
      },{
        title: 'Доставка груза на Wildberries',
        link: '/marketplejsy/wildberries/'
      },{
        title: 'Доставка груза для магазинов',
        link: ''
      },{
        title: 'Упаковка товаров',
        link: '/marketplejsy'
      },{
        title: 'Доставка 3 тонны',
        link: '/avtopark/gruzoperevozki-3tonny/'
      },{
        title: 'Доставка 5 тонн',
        link: '/avtopark/gruzoperevozki-5tonn/'
      },{
        title: 'Доставка 10 тонн',
        link: '/avtopark/gruzoperevozki-10tonn/'
      },{
        title: 'Доставка 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn/'
      }],
    }]
  };

  faq = [{
    title: "Как происходит доставка на Яндекс Маркет?",
    description: "Процесс доставки грузов на склады маркетплейса состоит из следующих этапов:<br><br> оформление на доставку клиентом (можно заказать обратный звонок или добавить заявку в личный кабинет); <br><br> согласование всех нюансов доставки (дата и дополнительные услуги); <br><br> менеджер озвучивает цены и заключает с вами договор; <br><br> подготовка груза к отправке на склад Яндекс Маркет; <br><br> забор со склада отправителя; <br><br> непосредственно доставка до пункта назначения."
  }, {
    title: "Как осуществляется доставка до склада Яндекс Маркет?",
    description: "Для аренды авто для доставки вам доступны несколько вариантов: <br><br>— вы можете оставить заказ, заполнив форму обратной связи (менеджер свяжется с вами с течение двух минут); <br>— создать новую заявку на доставку; <br>— позвонить нам по телефону 8 (499) 350-90-02; <br><br>написать на e-mail info@tk-akro.ru"
  }, {
    title: "Оказываете ли вы дополнительные услуги фулфилмента и маркировки партии?",
    description: "На данный момент этот вид услуг не входит в наш прайс-лист, но в ближайшей перспективе планируем внедрить эти направления, в том числе и начать хранение вашей продукции на нашем складе (складские услуги)."
  }, {
    title: "Как зарегистрироваться?",
    description: "Главная страница сайта <a href='https://tk-akro.ru/'>https://tk-akro.ru/</a> содержит ссылку «Личный кабинет», нажимая на которую, вы переходите в форму и заполняете обязательные поля, даете разрешение на хранение и обработку персональных данных и нажимаете кнопку «зарегистрироваться». После этого вы можете войти в сервис и вам будут доступны все его преимущества, в том числе бесплатное онлайн отслеживание груза при доставке и калькулятор, который может рассчитать стоимость тарифа. Обратите внимание, что заполняя онлайн -форму, вы принимаете нашу политику конфиденциальности и даете согласие на обработку ваших данных. Также у нас есть полезные новости о мире грузоперевозок, включая актуальные новости."
  }, {
    title: "Как осуществляется оплата?",
    description: "По персональному запросу возможна постоплата тарифа (перечисляется после оказания услуги) или предоплата тарифа (в этом случае будет действовать акция, и стоимость перевозки будет ниже). Также мы можем сделать вам скидку на первый заказ."
  }, {
    title: "В каких городах вы работаете?",
    description: "География нашей деятельности очень широка. Мы оказываем услуги дешево  во всех крупных городах России (Москве, Санкт-Петербурге, Казани,а также таких городах как Краснодар, Тверь)."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Офис ООО «ТК АКРО»  расположен по адресу: город Москва, ул.Щёлковское ш., дом 2а. Наше точное местоположение легко найти на карте, а также схема проезда есть на нашем сайте! Удобный график работы: с понедельника по пятницу с 9:00 до 18:00, выходные сб и вс.  Наши контакты: телефон 8 (499) 350-90-02, электронный адрес info@tk-akro.ru, на которую вы можете писать круглосуточно.  Если у вас остались вопросы, свяжитесь с нами удобным для вас способом!"
  }]

  constructor(private meta: Meta, private titleService:Title, public readonly popupService: PopupService) {
    this.meta.addTag({ property: 'og:title', content: 'Доставка на маркетплейс Яндекс Маркет Москва, СПБ, Россия' });
    this.meta.addTag({ property: 'og:description', content: 'Транспортная компания АКРО.✅ Доставка до Yandex Market.✅ Доставка до склада Яндекс Маркет.✅ Паллетная доставка ТК до склада Яндекс Маркет Москва, Россия' });
    this.meta.addTag({ name: 'description', content: 'Транспортная компания АКРО.✅ Доставка до Yandex Market.✅ Доставка до склада Яндекс Маркет.✅ Паллетная доставка ТК до склада Яндекс Маркет Москва, Россия' });
    this.titleService.setTitle('Доставка на маркетплейс Яндекс Маркет Москва, СПБ, Россия');
  }
}
