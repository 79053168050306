<app-banner
  [image]="'/assets/images/banner/banner-autopark.svg'"
  [title]="'Наш автопарк'"
  [description]="'Вы можете выбрать любой <br> автомобиль от каблучка до фуры'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>


    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-auto text-24-xs pb-30-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col text-16-xs">
        При наличии ежедневных отгрузок или постоянных маршрутов, мы подготовим индивидуальный прайс со скидкой для вас.
        <br>
        <br>
        Подробную информацию можно получить по номеру телефона +7(499)350-90-02
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>
</div>
