import { Injectable } from '@angular/core';
import { PopupEnum } from "./enums/popup.enum";

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  public readonly show: {[key in PopupEnum]: boolean} = {
    [PopupEnum.KP]: false,
    [PopupEnum.CALLBACK]: false,
    [PopupEnum.DRIVER_JOB]: false,
  }
  public PopupEnum = PopupEnum;

  public togglePopup(value: PopupEnum): void {
    for(const key of Object.keys(this.show)) {
      this.show[key as PopupEnum] = false;
    }
    this.show[value] = true;
  }

  public closePopups(): void {
    for(const key of Object.keys(this.show)) {
      this.show[key as PopupEnum] = false;
    }
  }
}
