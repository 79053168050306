<div class="container pt-20-xs pb-50-xs pb-60-lg">
  <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs pl-20-xs pr-20-xs pr-0-md pl-0-md">Услуги</h2>
  <div class="row text-lg-start pr-60-xs pr-0-lg pl-60-xs pl-0-lg px-15-lg">

    <div class="d-none d-lg-block">
      <div class="row text-grey3">

        <div *ngFor="let service of services"
          [class]="service.items.length > 1
            ? 'col mr-20-lg d-flex flex-column'
            : 'col bg-purple border-grey3 b-r-16-xs mr-20-lg d-flex flex-column'"
        >
          <a *ngFor="let item of service.items"
            href="{{item.link}}"
            [class]="service.items.length > 1
              ? 'row bg-purple border-grey3 b-r-16-xs mb-10-lg flex-fill align-items-end position-relative text-gray3 text-decoration-none ' + item.class
              : 'row flex-fill align-items-end position-relative text-gray3 text-decoration-none' "
          >
            <div class="p-20-xs">
              <p class="position-absolute text-16-lg pt-20-xs top-0" [innerHTML]="item.title"></p>
              <img loading="lazy" class="text-center mx-auto" src="{{item.image}}" [class]="item.imageClass" alt="{{item.alt}}">
            </div>
          </a>
        </div>
      </div>
    </div>

    <ngx-slick-carousel *ngIf="getMobileItems().length" class="carousel d-block d-lg-none pl-0-xs"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div *ngFor="let service of getMobileItems()" class="slide bg-purple border-grey3 b-r-16-xs text-gray3 ml-10-xs mr-10-xs"
           ngxSlickItem>
        <a href="{{service.link}}" class="text-decoration-none d-block height-to-full">
          <div class="p-20-xs">
            <p class="text-16-lg" [innerHTML]="service.title"></p>
            <img *ngIf="service.image" alt="{{service.alt}}" class="text-center mx-auto pt-40-xs" loading="lazy"
                 src="{{service.image}}">
          </div>
        </a>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
