import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent {
  @Input() style = ''
  @Input() show = false
  @Input() items?: {
    title?: string;
    link?: string;
    show?: boolean;
    items?: {
      title?: string;
      link?: string;
      show?: boolean;
    }[] | undefined
  }[] | undefined
}
