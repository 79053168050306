import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-up-to-five-tons',
  templateUrl: './trucking-up-to-five-tons.component.html',
  styleUrls: ['./trucking-up-to-five-tons.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingUpToFiveTonsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: 'avtopark',
      linkTitle: 'Наш автопарк'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки 5 тонн'
        }]
  }

  description2 = 'Грузоперевозки 5 тонн - актуальная услуга в Москве и Московской области, которая активно оказывается транспортной компанией «AKRO» уже более 5 лет. Наши клиенты нам доверяют и знают, что мы доставим груз грузоподъемностью от 5 тонн в целости и сохранности в кратчайшие сроки, а цена будет максимально выгодной. Нас рекомендуют знакомым и партнерам по бизнесу, а отзывы наших клиентов свидетельствуют о добросовестности ТК «АКРО».'

  carActive   =  0;
  typeActive  =  3;
  header = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3260 ₽',
          ndsPrice: '3750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '5868 ₽',
          ndsPrice: '6750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '20 ₽/км',
        ndsCheckout: '23 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '3475 ₽',
          ndsPrice: '4000 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6255 ₽',
          ndsPrice: '7200 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4590 ₽',
          ndsPrice: '5280 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6885 ₽',
          ndsPrice: '7920 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '5100 ₽',
          ndsPrice: '5880 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7650 ₽',
          ndsPrice: '8820 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '30 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '750 ₽/950 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '7000 ₽',
          ndsPrice: '8050 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1150 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '30 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '800 ₽/1000 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '8320 ₽',
          ndsPrice: '9600 ₽',
          advPrice: '<span class="d-block">1040 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '37 ₽/км',
        pass: '870 ₽/1050 ₽/1400 ₽',
        ndsPass: '1000 ₽/1200 ₽/1600 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '9760 ₽',
          ndsPrice: '11200 ₽',
          advPrice: '<span class="d-block">1220 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '48 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽ /1750 ₽ /2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1750 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '53 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4980 ₽',
          ndsPrice: '5700 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7470 ₽',
          ndsPrice: '8550 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '28 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: '6090 ₽',
          ndsPrice: '7000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7830 ₽',
          ndsPrice: '9000 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '35 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: '7350 ₽',
          ndsPrice: '8400 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '9450 ₽',
          ndsPrice: '10800 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '750 ₽/950 ₽'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: '12600 ₽',
          ndsPrice: '14400 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '50 ₽/км',
        pass: '870 ₽/1050 ₽/1400',
        ndsPass: '1000 ₽/1200 ₽/1600'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: '14130 ₽',
          ndsPrice: '16200 ₽',
          advPrice: '<span class="d-block">1570 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: '16650 ₽',
          ndsPrice: '18900 ₽',
          advPrice: '<span class="d-block">1850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '56 ₽/км',
        ndsCheckout: '65 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  }]

  description = 'Если вам необходимо осуществить квартирный или офисный переезд, транспортировку груза определенного типа на склад или до терминала маркетплейса, грузовые перевозки в Москве и области — то что вам нужно. ТК «АКРО» предоставит на выбор автомобиль для перевозки нужного вида с грузоподъемностью от 5 тонн. Доступная стоимость транспортировки от ТТК на любые расстояния, ответственные логисты, быстрая поставка на адрес и гарантия 100% безопасности груза - это те причины, по которым выбирают нас.'

  blockWithItems = {
    title: 'Транспортировка грузов до 5 тонн может осуществляться следующими видами автотранспорта:',
    description: '',
    class: 'col-12 col-md-6 col-lg-4 text-center',
    items: [{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-1.svg',
      alt: 'Пятитонник',
      title: '',
      description: 'Специальными пятитонниками',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-2.svg',
      alt: 'Тент',
      title: '',
      description: 'Газелями и прочими авто с тентами',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-3.svg',
      alt: 'Фургон',
      title: '',
      description: 'Фургонами как классическими, так и с гидробортами',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-4.svg',
      alt: 'Борт',
      title: '',
      description: 'Бортовыми автомобилями',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-5.svg',
      alt: 'Рефрижератор',
      title: '',
      description: 'Авторефрижераторами',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Каждая разновидность транспорта позволяет перевозить определенные грузы грузоподъемностью от 5 тонн исходя из целей вашего бизнеса. Это могут быть различные строительные предметы, техника, мебель при переезде, негабаритные вещи, продукция для магазинов и складов и многое другое.',
      class: 'p-20-xs text-10-xs border-blue'
    }]
  }

  blockWithItems2 = {
    title: 'От чего зависит стоимость перевозки?',
    description: '',
    class: 'col-12 col-md-6 col-lg-4 text-center',
    items: [{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-6.svg',
      alt: 'Тип авто',
      title: '',
      description: 'тип выбранного автомобиля',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-7.svg',
      alt: 'Маршрут',
      title: '',
      description: 'протяженность и сложность направления',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-8.svg',
      alt: 'Загрузка',
      title: '',
      description: 'загрузка машины',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  tags = {
    title: 'Автомобильные грузоперевозки',
    class: '',
    items: [{
      class: '',
      title: '',
      children: [{
        title: 'Груз 3 тонны',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Грузоперевозки 10 тонн',
        link: '/avtopark/tent-furgon/tent-10-tonn'
      },{
        title: 'Дешевая Перевозка 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn'
      },{
        title: 'Перевозка товара до склада рефрижератором',
        link: '/avtopark/refrizheratornye-perevozki'
      },{
        title: 'Грузоперевозки от 500 кг до 1 т',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Срочная грузоперевозка газель',
        link: '/avtopark/gruzoperevozki-na-gazeli'
      },{
        title: 'Грузоперевозки тент фургон',
        link: '/avtopark/tent-furgon'
      },{
        title: 'Помощь грузчиков',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Грузоперевозки фурой',
        link: 'avtopark/gruzoperevozki-20tonn'
      },{
        title: 'Грузоперевозки в пределах мкад',
        link: 'gruzoperevozki-po-moskve-i-mo'
      },{
        title: 'Транспортировка и догруз груза',
        link: ''
      },{
        title: 'Переезд в пределах кольца',
        link: 'gruzoperevozki-po-moskve-i-mo'
      },{
        title: 'Транспорт грузоподъемностью до 5 тонн',
        link: 'avtopark/gruzoperevozki-5tonn'
      },{
        title: 'Переезд складских помещений',
        link: '/gruzoperevozki-dlya-yur-lic'
      },{
        title: 'Автомобили любого размера для грузоперевозки',
        link: 'avtopark'
      },{
        title: 'Условия перевозок',
        link: '/'
      }]
    }]
  };

  faq = [{
    title: "Какие грузы можно перевозить на наших автомобилях?",
    description: "Наши грузовые фургоны осуществляют перевозку стройматериалов и жби изделий до адресата или на строительные площадки, осуществляют автоперевозку торгового и медицинского оборудования, мебели при переезде, негабаритных вещей. По индивидуальным заказам мы занимаемся поставкой габаритных музыкальных инструментов различного веса (пианино, рояли, фортепиано) с гидролифтом.<br><br> Также это могут быть такие грузы как: <br> - разные строительные материалы; <br> - торговое и офисное оборудование при переезде; <br> - металлоконструкции; <br> - продукты питания; <br> - промышленные товары и прочее."
  }, {
    title: "Какие габариты у автомобилей для перевозки грузов 5 тонн?",
    description: "<ul>" +
    "<li>Автомобили, использующиеся для перевозки 5 тонн, имеют габариты, полностью соответствующими техническому регламенту:</li>" +
    "<li>Длина отсека машины от 3,5 до 6 метров.</li>" +
    "<li>Объем кузова машины от 20 до 35 кв. м.</li>" +
    "<li>Полезная ширина от 2 до 2,3 м.</li>" +
    "<li>Полезная высота 2,45 м.</li>" +
    "</ul>"
  }, {
    title: "Где можно посмотреть отзывы и вакансии вашей компании?",
    description: "Все открытые вакансии размещены на сайте hh.ru. С отзывами можно ознакомиться на Яндекс Картах."
  }, {
    title: "Каким образом оформляется аренда машины и отправка нашего груза?",
    description: "Чтобы поставить грузы на следующий день после оформления заказа, вам нужно бесплатно оставить заявку на услугу до 18:00 часов. Заполняя онлайн заявку на грузоперевозку и нажимая кнопку, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных. Наш специалист в течение 2 минут свяжется с вами, подготовит договор, а далее вы самостоятельно подбираете способы оплаты."
  }, {
    title: "Предоставляете ли вы дополнительные услуги грузчиков?",
    description: "Да, при необходимости, помимо доставки, вы можете заказать эту услугу. Цена будет зависеть от того, сколько весит ваш груз, и его габаритов. В зависимости от этих параметров для погрузки и разгрузки может быть привлечен водитель или бригада грузчиков, которые разгрузят машину."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Наш адрес: город Москва, ул.Щёлковское ш., дом 2а. Наше точное местоположение легко найти на карте, а также схема проезда есть на нашем сайте! Удобный график: с понедельника по пятницу с 9:00 до 18:00, выходной сб и вс.  Наши контакты: телефон 8 (499) 350-90-02, круглосуточно можно писать на электронную почту  info@tk-akro.ru - ни одно письмо не останется без внимания. Свяжитесь с нами любым способом!"
  }, {
    title: "Как зарегистрироваться в личном кабинете?",
    description: "Главная страница <a href='https://tk-akro.ru/'>сайта</a> содержит ссылку «Личный кабинет», нажимая на которую, вы переходите в форму и заполняете обязательные поля, даете разрешение на хранение и обработку персональных данных и нажимаете кнопку «зарегистрироваться». После этого вы можете войти в сервис и вам будут доступны все его преимущества, в том числе загрузка документов, бесплатное онлайн отслеживание груза и калькулятор, с помощью которого вы можете рассчитать стоимость."
  }, {
    title: "Какова цена перевозки?",
    description: "Вы можете заказать грузоперевозку на авто из нашего обновленного автопарка по очень выгодной цене. Стоимость за 1 час составляет 1150 руб, при этом сюда входит подача авто. Дополнительно оплачиваются выезд за МКАД (31 руб/км) и пропуск ТТК / садовое кольцо (850/1020 руб)."
  }]

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Грузоперевозки 5 тонн Москва - цены перевозки грузов снижены' });
    this.meta.addTag({ property: 'og:description', content: '✅ Услуги грузоперевозки 5 тонн Москва от 550 руб ✅ Подача автомобиля в день обращения ✅ Закажите 5 тонник сейчас! Звоните +7 (499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: '✅ Услуги грузоперевозки 5 тонн Москва от 550 руб ✅ Подача автомобиля в день обращения ✅ Закажите 5 тонник сейчас! Звоните +7 (499) 350-90-02' });
    this.titleService.setTitle('Грузоперевозки 5 тонн Москва - цены перевозки грузов снижены');
  }
}
