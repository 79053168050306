import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-collaboration',
  templateUrl: './trucking-collaboration.component.html',
  styleUrls: ['./trucking-collaboration.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingCollaborationPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки сотрудничество'
    }]
  }

  description = 'Содержать собственный автопарк, нанимать профессиональных водителей, создавать логистический отдел с отдельной бухгалтерией, самостоятельно разрабатывать маршрут — это задача, требующая дополнительных расходов и времени. Особенно это невыгодно, если у вас нерегулярные перевозки. \n' +
    'В этом случае сотрудничество с грузоперевозчиком — оптимальное решение. Вы экономите на содержании штатных сотрудников и автопарка, \n' +
    'а также получаете качественные услуги.'

  blockWithItems = {
    title: 'Какие услуги оказываем',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Товары необходимо аккуратно сложить на поддон (допускается укладка в транспортную коробку), при этом на каждой из паллет обязательно наличие информационного ярлыка, распечатанного из личного кабинета.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Чтобы получить более подробную консультацию по доставке товаров до распределительных центров OZON, вы можете заполнить заявку на сайте, и наши менеджеры свяжутся с вами в самое ближайшее время. Также ориентировочные сроки и стоимость доставки вы можете узнать по телефону 8 (499) 350-90-02.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Мы берем на себя полную ответственность и контролируем все этапы от загрузки до разгрузки на складе озон. Гарантируем отличный сервис и высокое качество услуг, о чем свидетельствуют отзывы наших довольных клиентов!',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems2 = {
    title: 'Что доставляем',
    description: '',
    class: 'col-12 col-md-6 col-lg',
    items: [{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-21.svg',
      alt: 'Промышленные',
      title: '',
      description: 'Промышленные товары',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-20.svg',
      alt: 'Продукты',
      title: '',
      description: 'Продукты питания',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-19.svg',
      alt: 'Стройматериалы',
      title: '',
      description: 'Стройматериалы',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-18.svg',
      alt: 'Оборудование',
      title: '',
      description: 'Оборудование',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-17.svg',
      alt: 'Мебель',
      title: '',
      description: 'Мебель',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-16.svg',
      alt: 'Другие грузы',
      title: '',
      description: 'Другие грузы',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems3 = {
    title: 'С кем работаем',
    description: '',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-12.svg',
      alt: 'Интернет',
      imageClass: 'd-flex mx-auto mb-20-xs',
      title: 'Интернет-магазины',
      description: 'Наша компания предлагает доставку продукции со склада поставщика до распределительных центров, пунктов выдачи маркетплейсов, розничных точек',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-14.svg',
      alt: 'Опт',
      imageClass: 'd-flex mx-auto mb-20-xs',
      title: 'Оптовые сети',
      description: 'Доставляем продукты питания и промышленные товары до розничных магазинов. Оформите регулярные поставки, станьте нашим постоянным клиентом и получите скидку',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-13.svg',
      alt: 'Организации',
      imageClass: 'd-flex mx-auto mb-20-xs',
      title: 'Организации, производственные предприятия',
      description: 'Мы обеспечим крупные предприятия и мелкие организации технически исправным транспортом. Доставим сырье, стройматериалы, оборудование и другие грузы',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-15.svg',
      alt: 'Грузоперевозчики',
      imageClass: 'd-flex mx-auto mb-20-xs',
      title: 'Грузоперевозчики',
      description: 'Если ваша компания начинает свою работу в сфере грузоперевозок и у нее нет большого автопарка, \n' +
        'чтобы обеспечить высокое качество услуг, мы предоставим транспорт на выгодных условиях',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems4 = {
    title: 'Требования к грузу',
    description: 'Чтобы доставка на склад маркетплейса прошла без задержек и проблем, заказчику необходимо четко им следовать:',
    class: 'col-12 col-md-6 col-lg text-center',
    items: [{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-3.svg',
      alt: '20кг',
      title: '',
      description: 'Максимальный вес одной коробки не должен превышать 20 кг',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-4.svg',
      alt: '1 куб',
      title: '',
      description: 'При объеме больше кубометра коробки упаковываются в паллеты',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-5.svg',
      alt: '180см',
      title: '',
      description: 'Высота паллеты не должна превышать 180 см',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-collaboration/trucking-collaboration-6.svg',
      title: 'Повреждения',
      description: 'На упаковке не должно быть повреждений',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  faq = [{
    title: "Что вы получаете, работая с «АКРО»?",
    description: "• оперативную перевозку юридическим лицам; <br>• гибкое ценообразование;<br>• персонального менеджера;<br>• полный пакет закрывающей документации;<br>• быструю подачу автомобиля.<br>У нас работают вежливые внимательные сотрудники. Наши менеджеры по корпоративным клиентам подробно расскажут про грузоперевозки для юридических лиц.<br>Чтобы заказать доставку, позвоните нам или оставьте заявку на сайте. Опытная команда наших менеджеров обеспечит оперативную доставку в самые сжатые сроки и организует вам качественную перевозку.<br>Оставьте заявку сегодня до 17:00, и уже завтра ваш груз отправится получателю. Также по желанию клиента мы предоставляем транспорт день в день."
  }, {
    title: "Что еще вы доставляете?",
    description: "Мы доставляем разные, в том числе скоропортящиеся, грузы, требующие соблюдения температурного режима, весом от 500 кг до 20 тонн. Не перевозим опасные или нарушающие требования закона заказы. Транспортировка хрупкого, негабаритного товара обсуждается отдельно. Для грузов, доставляемых на маркетплейсы, существуют отдельные требования, с которыми вы можете ознакомиться в соответствующих разделах сайта. "
  }, {
    title: "Какие к вас доступные варианты оплаты?",
    description: "Мы предлагаем выгодное сотрудничество в сфере грузоперевозок. Для оплаты услуг можно использовать наличный или безналичный расчет с учетом НДС. После доставки выдаем все закрывающие документы для бухгалтерии."
  }]

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'AKRO - транспортная компания сотрудничество. Сотрудничество грузоперевозки Москва, Россия' });
    this.meta.addTag({ property: 'og:description', content: 'АКРО ✅ Сотрудничество по грузоперевозкам ✅ Грузоперевозки Москва сотрудничество ✅ Сотрудничество грузоперевозки ГАЗель ✅ Грузоперевозки сотрудничество с компаниями' });
    this.meta.addTag({ name: 'description', content: 'АКРО ✅ Сотрудничество по грузоперевозкам ✅ Грузоперевозки Москва сотрудничество ✅ Сотрудничество грузоперевозки ГАЗель ✅ Грузоперевозки сотрудничество с компаниями' });
    this.titleService.setTitle('AKRO - транспортная компания сотрудничество. Сотрудничество грузоперевозки Москва, Россия');
  }
}
