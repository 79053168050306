import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ClientApiBaseService {
  protected readonly apiUrl: string = environment.apiUrl;
  protected readonly defaultHeaders = { 'Content-Type': 'application/json' };
  constructor(protected readonly http: HttpClient) {}
}
