<app-banner
  [image]="'/assets/images/banner/banner-trucking-up-to-three-tons.svg'"
  [title]="'Грузоперевозки <br> 3 тонны'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <app-block-description-page
  [description]="description2">
</app-block-description-page>

  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs pb-30-xs">
      <div class="col-12 col-md-auto text-24-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        При наличии ежедневных отгрузок или постоянных маршрутов, мы подготовим индивидуальный прайс со скидкой для вас.
        <br>
        <br>
        Подробную информацию можно получить по номеру телефона +7(499)350-90-02
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Преимущества работы с нами</h2>
    <div class="row">
      <div class="col pl-0-xs pl-10-xs pr-0-xs pr-50-lg">
        <div class="row mb-30-xs">
          <div class="col pl-20-xs text-20-xs">Грузоперевозки в «АКРО» проводятся лучшими водителями и грузчиками, с большим опытом и соответствующими квалификациями. Каждый бортовой автотранспорт проходит своевременный техосмотр и всегда находится в исправном состоянии. Если вы не знаете, что выбрать - фуру, бычок или грузовик - мы подберем для вас машину, подходящую по ширине и высоте, отталкиваясь от типа перевозимого товара и требований к грузоперевозке. Мы поможем рассчитать точную стоимость грузоперевозок по Москве.</div>
        </div>
        <div class="row mb-30-xs">
          <div class="col pl-20-xs text-20-xs">Стоимость зависит от характеристик (до 3 или 5 тонн), маршрута и условий доставки. Вы можете быть уверены в индивидуальном подходе к расчету, а также в доступности цен — они всегда остаются ниже, чем у конкурентов. Для сотрудничества на постоянной основе мы предлагаем выгодные и гибкие тарифы на грузоперевозки, проработанные индивидуально под вашу организацию.</div>
        </div>
      </div>
      <div class="col">
        <div class="border-blue b-r-16-xs p-30-xs">
          <p class="text-16-xs">Наш менеджер свяжется с вами в ближайшее время и ответит на все вопросы, а также поможет сделать заказ машины для грузоперевозок от 1 до 20 тонн. Оказание услуг по грузоперевозке осуществляется по договору, в котором предусмотрены все удобные способы оплаты. Для того, чтобы узнать больше информации по услугам, закажите звонок, заполнив форму обратной связи.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <app-feedback
      [title]="'Заполните форму обратной связи и получите:'"
      [description]="'— Расчет стоимости доставки <br> — Ориентировочные сроки'"
    >
    </app-feedback>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>
  <p>Категории кузова до 3000 кг: бортовой, тент, изотермический, рефрижератор. Благодаря этим особенностям, зил бычок является наиболее качественным вариантом, когда требуется перевезти маленькие по массе партии, имеющие небольшой объем.</p>

  <app-block-with-items
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <app-block-description-page
    [title]="'Нужен надежный автомобиль и грузоперевозки 3 тонны в Москве и Московской области?'"
    [description]="'Теперь вы знаете, где можно найти их по самой доступной цене'"
    [image]="'assets/images/trucking-up-to-three-tons/trucking-up-to-three-tons.svg'"
    [leaveRequest]="true"
  >
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
