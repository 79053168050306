<div class="row text-16-xs mb-24-xs pb-40-xs border-bottom m-0-xs">
  <div class="col-12 col-md-6 col-lg-4 text-grey4">
    <div class="row text-24-xs mb-16-xs" [innerHTML]="title"></div>
    <div class="row text-16-xs" [innerHTML]="description"></div>
  </div>
  <div class="col-12 col-md-6 col-lg-8 mt-20-xs mt-0-lg">
    <form [formGroup]="form" class="row" ngSubmit="javascript:void(0)">
      <div class="col-12 col-lg-6 pr-0-xs pr-10-lg pl-0-xs pl-10-lg">
        <input id="count-box-pallet"
               type="text"
               placeholder="Количество коробок или паллет"
               formControlName="count-box-pallet"
               class="border-grey2 text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">

        <input id="date-delivery-on-stock"
               type="text"
               placeholder="Дата поставки груза на склад "
               mask="+7 (000) 000-0000"
               formControlName="date-delivery-on-stock"
               class="border-grey2 text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">

        <input id="total-cargo-weight"
               type="text"
               placeholder="Общая масса груза (кг)"
               formControlName="total-cargo-weight"
               class="border-grey2 text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">

      </div>
      <div class="col-12 col-lg-6 pr-0-xs pr-10-lg pl-0-xs pl-10-lg">
        <input id="address"
               type="text"
               placeholder="Ваш адрес"
               mask="+7 (000) 000-0000"
               formControlName="address"
               class="border-grey2 text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">

        <input id="date-time-take-weight"
               type="text"
               placeholder="Дата и время забора груза"
               formControlName="date-time-take-weight"
               class="border-grey2 text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">

        <input id="address-delivery-stock"
               type="text"
               placeholder="Адрес склада поставки"
               mask="+7 (000) 000-0000"
               formControlName="address-delivery-stock"
               class="border-grey2 text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
        <button type="submit" [disabled]="!form.valid" class="bg-blue text-white b-r-8-xs text-16-xs p-10-xs border-0">Оставить заявку</button>
      </div>
    </form>
  </div>
</div>

<div class="row text-grey4">
  <div class="col-12 col-lg-4 text-24-xs">
    Или получите подробную консультацию
  </div>
  <div class="col-12 col-lg-4 text-16-xs mt-20-xs mt-0-lg">
    По телефону 8 (499) 350-90-02:
    <br>
    наш менеджер подберет для вас оптимальный тариф
    <br>
    и ответит на все интересующие вас вопросы.
  </div>
  <div class="col-12 col-lg-4 mt-20-xs mt-0-lg">
    <a href=""
       role="button"
       (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)"
       class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
      Заказать звонок
    </a>
  </div>
</div>
