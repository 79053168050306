<ul *ngIf="items" [class.active]="show" class="position-absolute-lg pt-10-xs pt-20-lg pb-10-xs pb-20-lg pr-10-xs pr-30-lg pl-10-xs pl-30-lg bg-white-lg border-grey3-lg b-r-16-xs list-unstyled"
  [style]="style">
  <li *ngFor="let item of items" class="white-space-pre position-relative">
    <span
      class="row justify-content-between align-items-center text-14-xs text-purple pt-8-xs pt-6-lg pb-8-xs pb-6-lg m-0-xs position-relative cursor-pointer"
      aria-current="page"
      *ngIf="item['items']"
      (click)="item['show'] = !item['show']"
    >
      <span class="col p-0-xs">{{item.title}}</span>
      <span class="col-auto p-0-xs">
        <img loading="lazy" src="assets/images/icon/menu-arrow-right.svg">
      </span>
    </span>
    <a href="{{item.link}}"
       class="row text-14-xs text-purple d-block text-decoration-none pt-8-xs pt-6-lg pb-8-xs pb-6-lg m-0-xs"
       itemprop="url"
       aria-current="page"
       *ngIf="!item['items']"
    >
      <span class="col p-0-xs">{{item.title}}</span>
    </a>

    <app-menu *ngIf="!!item['items']" [show]="!!item['show']" [items]="item['items']" [style]="'left: calc(100% + 20px);'"></app-menu>
  </li>
</ul>
