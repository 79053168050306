import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-page-manipulator-transports',
  templateUrl: './manipulator-transports.component.html',
  styleUrls: ['./manipulator-transports.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ManipulatorTransportsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Перевозки манипуляторами'
    }]
  }
   description = 'Вот уже более 5 лет транспортная компания «Акро» оказывает полный спектр услуг по перевозке различных видов грузов. В нашу географию входит не только Москва и Московская область, но и другие регионы России, в зависимости от выбранных вами услуг. В определенных случаях нашим клиентам требуются грузоперевозки краном-манипулятором. Услуги манипуляторов в Москве и области –\n' +
    '      это одно из приоритетных направлений в деятельности нашей компании.\n' +
    '\n' +
    '      Манипуляторы — это многофункциональная техника, которая представляет собой подъемный механизм, установленный на борт грузовой машины, который также называют краново-манипуляторной установкой или КМУ. Этот тип техники позволяет решать разнообразные задачи в строительной, промышленной, лесозаготовительной или транспортной сфере. Благодаря манипуляторам, вы можете\n' +
    '      не только перевозить свой груз, но осуществить качественные погрузочно-разгрузочные манипуляции.\n' +
    '\n' +
    '      Мы помогаем значительно упростить и снизить стоимость погрузо-разгрузочных работ и перевозки грузов, предлагая вам услугу 2 в 1. Поскольку кран-манипулятор – это и подъемный механизм, и грузовой транспорт, вы оплачиваете только одну единицу техники для своих целей, что экономит ваши финансовые и временные ресурсы.';

  faq = [{
    image: '',
    title: "Даете ли вы гарантию на сохранность груза?",
    description: "Да, мы на 100% гарантируем сохранность вашего груза при аренде нашего транспорта. Мы берем на себя полную ответственность и контролируем все этапы \n" +
      "от загрузки до разгрузки. Гарантируем отличный сервис и высокое качество услуг, о чем свидетельствуют отзывы наших довольных клиентов!"
  }, {
    image: '',
    title: "Сколько будет стоить арендовать манипулятор?",
    description: "Цена на услуги кранов-манипуляторов напрямую зависит от типа и грузоподъемности техники. Например, манипулятор с грузоподъемностью 3 тонны можно заказать по цене от 5250 рублей (с НДС). Узнать цены на наши услуги, арендовать манипулятор в Москве или Московской области, а также получить индивидуальное коммерческое предложение вы можете по телефону 8 (499) 350-90-02."
  }, {
    image: '',
    title: "Какие виды манипуляторов есть в вашем автопарке?",
    description: "Для наших клиентов доступна аренда манипуляторов с грузоподъемностью 3 тонны, 5 тонн, 7 тонн и 7 тонн (вездеход)."
  }, {
    image: '',
    title: "Как осуществляется аренда спецтехники у вашей компании?",
    description: "Если вам нужна перевозка особых видов грузов с помощью манипулятора, у вас есть возможность оставить заявку на нашем сайте, заполнив форму обратной связи, или через личный кабинет.  Не забудьте указать ваши контакты – номер телефона и электронную почту, а также адрес, по которому нужно забрать и доставить груз. Заполняя онлайн заявку на сайте, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных."
  }, {
    image: '',
    title: "Как осуществляется оплата?",
    description: "Чаще всего наши клиенты выбирают постоплату, которая производится на наш расчетный счет после оказания услуги. Также возможен расчет по предоплате \n" +
      "(в этом случае будет действовать акция и стоимость аренды и перевозки будет ниже)."
  }];

  blockWithItems = {
    title: 'Наш автопарк',
    description: 'Все наши автомобили находятся в отличном техническом состоянии, регулярно проходят техосмотры, тщательно и своевременно обслуживаются и поставляются полностью готовыми к работе. ',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: '',
      title: 'от 5 до 10 тонн',
      description: 'Грузоподъемность борта у манипуляторов',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: 'от 3 до 7 тонн',
      description: 'Грузоподъемность стрелы',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: 'до 22 метров',
      description: 'Максимальный вылет стрелы',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: 'от 4 до 15 штук',
      description: 'Вместительность европалет',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }
}
