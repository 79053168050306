<div *ngIf="item" class="text-start text-grey4">
  <p class="mb-0 pr-30-xs">
    <button
      type="button"
      class="col-12 p-0-lg pt-20-xs pb-6-xs pt-28-lg pb-28-lg text-start position-relative-lg text-grey4"
      [class.active]="!isCollapsed"
      (click)="collapse.toggle()"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample"
    >
      {{item['title']}}
    </button>
  </p>
  <div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="p-20-xs text-grey4">
        <div [innerHTML]="item['description']"></div>
      </div>
    </div>
  </div>
</div>
