import {Component} from '@angular/core';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.less']
})
export class OurClientsComponent {
  slidesTop = [{
    title: "1",
    image: "/assets/images/our-client/our_client1.svg",
    link: "",
    alt: "Пятерочка лого"
  }, {
    title: "2",
    image: "/assets/images/our-client/our_client2.svg",
    link: "",
    alt: "Касторама лого"
  },{
    title: "3",
    image: "/assets/images/our-client/our_client3.svg",
    link: "",
    alt: "Перекресток лого"
  },{
    title: "4",
    image: "/assets/images/our-client/our_client4.svg",
    link: "",
    alt: "Хофф лого"
  },{
    title: "5",
    image: "/assets/images/our-client/our_client5.svg",
    link: "",
    alt: "Метро лого"
  },{
    title: "5",
    image: "/assets/images/our-client/our_client6.svg",
    link: "",
    alt: "Леруа мерлен лого"
  },{
    title: "5",
    image: "/assets/images/our-client/our_client7.svg",
    link: "",
    alt: "Азбука вкуса лого"
  },{
    title: "5",
    image: "/assets/images/our-client/our_client8.svg",
    link: "",
    alt: "Ашан лого"
  }]

  slidesBottom = [{
    title: "4",
    image: "/assets/images/our-client/our_client9.svg",
    link: "",
    alt: "Магнолия лого"
  },{
    title: "5",
    image: "/assets/images/our-client/our_client10.svg",
    link: "",
    alt: "ДНС лого"
  },{
    title: "1",
    image: "/assets/images/our-client/our_client11.svg",
    link: "",
    alt: "ОКЕЙ лого"
  },{
    title: "2",
    image: "/assets/images/our-client/our_client12.svg",
    link: "",
    alt: "Озон лого"
  },{
    title: "3",
    image: "/assets/images/our-client/our_client13.svg",
    link: "",
    alt: "Фикс-прайс лого"
  },{
    title: "2",
    image: "/assets/images/our-client/our_client14.svg",
    link: "",
    alt: "Дикси лого"
  }]

  slideConfigTop = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    variableWidth: true,
    centerPadding: '10px'
  };

  slideConfigBottom = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    variableWidth: true,
    centerPadding: '10px',
    rtl: true
  };

}
