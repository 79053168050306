import {Component} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {
  public menu = [{
    title: 'Услуги',
    link: '/services',
    items: [{
      title: 'Грузоперевозки по Москве и МО',
      link: '/gruzoperevozki-po-moskve-i-mo',
      show: false
    }, {
      title: 'Доставка на маркетплейсы',
      link: '/marketplejsy',
      show: false,
      items: [{
        title: 'Wildberries',
        link: '/marketplejsy/wildberries',
      },{
        title: 'Ozon',
        link: '/marketplejsy/ozon',
      },{
        title: 'Яндекс.Маркет',
        link: '/marketplejsy/yandex-market',
      },{
        title: 'Доставка на маркетплейсы',
        link: '/marketplejsy',
      }]
    },{
      title: 'Страхование груза',
      link: '/strahovanie-gruza',
    },{
      title: 'Грузоперевозки по России',
      link: '/perevozki-po-rossii',
    },{
      title: 'Рефрижераторные перевозки',
      link: 'avtopark/refrizheratornye-perevozki',
    },{
      title: 'Доставка для интернет магазинов ',
      link: '/#ecommerce',
    }]
  },{
    title: 'Акции',
    link: '/akcii'
  },{
    title: 'Автопарк',
    link: '/avtopark',
    show: false,
  },{
    title: 'Вакансии',
    link: '/jobs'
  },{
    title: 'Контакты',
    link: '/kontakty'
  }]

  toggleMenu(menuItem: {title: string, link: string, show?: boolean, items?: {title: string, link: string}[]}) {
    this.menu.forEach((item, index) => {
      if (menuItem['title'] == item['title']) {
        item['show'] = !item['show'];
      } else {
        item['show'] = false;
      }
    })
  }
}
