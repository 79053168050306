<app-banner
  [image]="'/assets/images/banner/banner-trucking-by-gazelle.svg'"
  [title]="'Грузоперевозки <Br> Газель'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page
  [description]="description1">
</app-block-description-page>

  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 mb-20-xs mb-0-md fw-semibold">
        У нас самые выгодные тарифы на перевозку газелью: <br><br> всего 3350 руб при минимальном заказе 4 часа (каждый дополнительный час оплачивается отдельно) и загруженности авто 1 паллета (длина 1.5 х ширина 1 х высота до 1 метра). В стоимость входит подача машины под загрузку и 70 км по Москве.<br><br>
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя или грузчиков <br> при погрузке/выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование груза <br> при перевозке
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке/выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-30-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        Мы гарантируем индивидуальные условия постоянным клиентам
        <br>
        При наличии ежедневных отгрузок или постоянных маршрутов мы подготовим для вас индивидуальный прайс со скидкой.
        <br><br>
        Подробную информацию о перевозках в Москве и ценах можно получить по номеру телефона
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        <h2 class="text-48-lg mb-32-xs text-grey4">Как оформить заявку на грузоперевозки по России</h2>
        <span class="text-18-xs">
          Для создания заявки на грузоперевозку, получения расчета стоимости или индивидуального коммерческого предложения вы можете поступить таким образом:
        </span>

      </div>
      <div class="col-12 col-lg mt-20-xs mt-0-lg ">
        <img loading="lazy" src="assets/images/trucking-by-gazelle/trucking-by-gazelle-1.svg" alt="Газель">
      </div>
    </div>
    <div class="row text-center m-0-xs mt-48-xs">
      <div class="row col border-blue b-r-16-xs text-20-xs p-22-xs m-0-xs">
        <span class="p-0-xs align-self-center">оставить заказ на перевозку, заполнив форму обратной связи - мы перезвоним вам через 2 минуты. Чтобы мы могли рассчитать стоимость перевозки, введите следующую информацию: ваше имя, количество коробок или количество паллет, общая масса груза (кг) и объем (м3). Заполните дату забора и время, в которое мы можем приехать и забрать ваш груз, дату отправки, а также ваш адрес, и конечную точку доставки.</span>
      </div>
      <div class="row col border-blue b-r-16-xs text-20-xs m-0-xs mt-20-xs mt-0-lg ml-0-xs mr-0-xs ml-20-lg mr-20-lg p-22-xs">
        <span class="p-0-xs align-self-center">заявка может быть создана вами самостоятельно через личный кабинет. Вам нужно просто указать данные по заказу, заполнить характеристики груза, выбрать желаемый тип транспорта, который вам подходит, и маршрут, а также какие услуги вам могут потребоваться дополнительно (например, грузчики при переезде). На сайте вы можете отслеживать ваши отправления, там же будут доступны копии всех закрывающих документов.</span>
      </div>
      <div class="row col border-blue b-r-16-xs text-20-xs m-0-xs mt-20-xs mt-0-lg p-22-xs">
        <span class="p-0-xs align-self-center">если вы хотите получить предварительную консультацию, расчет перевозки или узнать подробнее о нашей работе, звоните нам по номеру телефона  8 (499) 350-90-02: наш сотрудник сможет предложить вам лучший тариф в зависимости от ситуации и ответит на все интересующие вас вопросы.</span>
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>
  <p>Для заказа газели нужного типа вы можете заполнить заявку либо воспользоваться кнопкой обратный звонок. В течение 2 минут с вами свяжутся наши специалисты и расскажут, как оформить заказ, рассчитать стоимость перевозки и выбрать газель для автоперевозки. Подача транспорта происходит в этот же день.<br><br>Цены на доставку перевозимых грузов на газели в «АКРО» всегда остаются конкурентоспособными на рынке транспортных услуг.  Узнать точно, сколько стоит заказать газель для перевозки по Москве и Московской области, можно в прайс-листе или предварительно рассчитать с нашим менеджером. При регулярных отгрузках, наши работы обойдутся дешевле.</p>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-lg mb-32-xs text-grey4">С «AKRO» ваша логистика станет эффективнее</h2>

    <app-feedback
      [title]="'Как заказать газель недорого нужного типа?'"
      [description]="'— На сайте — Заказать обратный звонок\n'+
        'С вами свяжутся и помогут оформить заказ, рассчитать цену и подобрать газель для автоперевозки. ' +
         'Подача транспорта осуществляется в этот же день.'"
    >
    </app-feedback>
  </div>

  <app-block-with-items
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <app-block-description-page
    [title]="'Нужен надежный автомобиль и грузоперевозки  в Москве и Московской области?'"
    [description]="'Теперь вы знаете, где можно найти их по самой доступной цене'"
    [image]="'assets/images/trucking-up-to-three-tons/trucking-up-to-three-tons.svg'"
    [leaveRequest]="true"
  >
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Ответы на часто задаваемые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>

</div>
