<app-banner
    [image]="'/assets/images/trucking-for-individuals/trucking-for-individuals1.svg'"
    [title]="'Грузоперевозки для физических лиц'"
    [description]="'Оказываем полный спектр услуг по перевозке грузов для<br> частных клиентов'"
    [showButtonCall]="true"
    [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<app-autopark-two
    [columns]="autoparkTwo"
></app-autopark-two>

<app-working-costs
    [title]="workingCosts['title']"
    [description]="workingCosts['description']"
    [workingCosts]="workingCosts['items']"
></app-working-costs>

<div class="container">
    <app-box-image-right class="point-blue"
        [image]="'/assets/images/trucking-for-individuals/trucking-for-individuals2.svg'"
        [alt]="'манипулятор'"
        [title]="'В каких случаях могут потребоваться <br>услуги манипуляторов'"
        [description]="'Минимальное время работы – 5 часов. При выполнении работ сверх установленного минимального лимита времени взымается дополнительная плата в размере часовой ставки, согласно таблице, за каждый час превышения:
                        <ul class=p-0>
                            <li class=my-20-xs>Если адрес подачи грузчиков за пределами МКАД, то дополнительно оплачивается 1 час.</li>
                            <li class=my-20-xs>При удаленности от МКАД больше 30 километров — 2 часа.</li>
                            <li class=my-20-xs>Свыше 50 километров — обговаривается индивидуально.</li>
                        </ul>'"
        [showButtonCall]="true"
        [imageClass]="'col-12 col-lg-6 mt-50-xs mt-0-lg'"
    >
    </app-box-image-right>
</div>