import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { BannerComponent } from './components/banner/banner.component';
import { ServiceComponent } from './components/service/service.component';
import { WhatChooseUsComponent } from './components/what-choose-us/what-choose-us.component';
import { AutoparkComponent } from './components/autopark/autopark.component';
import { HowToBookShippingComponent } from './components/how-to-book-shipping/how-to-book-shipping.component';
import { OurClientsComponent } from './components/our-clients/our-clients.component';
import { ActionsAndSalesComponent } from './components/actions-and-sales/actions-and-sales.component';
import { FaqComponent } from './components/faq/faq.component';
import { FaqItemComponent } from './components/faq-item/faq-item.component';
import { TagsComponent } from './components/tags/tags.component';
import { LkComponent } from './components/lk/lk.component';
import { PopupCallComponent } from './components/popup-call/popup-call.component';
import { PopupKpComponent } from './components/popup-kp/popup-kp.component';
import { TariffComponent } from './components/tariff/tariff.component';
import { TariffShortComponent } from './components/tariff-short/tariff-short.component';
import { BlockDescriptionPageComponent } from './components/block-description-page/block-description-page.component';
import { BlockWithItemsComponent } from './components/block-with-items/block-with-items.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MdbCollapseModule } from "mdb-angular-ui-kit/collapse";
import { HomePage } from './pages/home/home.component';
import { ServicesPage } from './pages/services/services.component';
import { AutoparkPage } from './pages/autopark/autopark.component';
import { ActionsPage } from './pages/actions/actions.component';
import { ContactsPage } from './pages/contacts/contacts.component';
import { DeliveryToMarketplacesPage } from './pages/delivery-to-marketplaces/delivery-to-marketplaces.component';
import { NewsPage } from './pages/news/news.component';
import { OutsourcingTransportationServicesPage } from './pages/outsourcing-transportation-services/outsourcing-transportation-services.component';
import { RefrigeratedTruckTransportationPage } from './pages/refrigerated-truck-transportation/refrigerated-truck-transportation.component';
import { ServicesSpecialVehiclesPage } from './pages/services-special-vehicles/services-special-vehicles.component';
import { ShippingByRussiaPage } from './pages/shipping-by-russia/shipping-by-russia.component';
import { ShippingOnOzonPage } from './pages/shipping-on-ozon/shipping-on-ozon.component';
import { ShippingOnWildberriesPage } from './pages/shipping-on-wildberries/shipping-on-wildberries.component';
import { ShippingOnYandexMarketPage } from './pages/shipping-on-yandex-market/shipping-on-yandex-market.component';
import { TruckingBullockPage } from './pages/trucking-bullock/trucking-bullock.component';
import { TruckingByGazellePage } from './pages/trucking-by-gazelle/trucking-by-gazelle.component';
import { TruckingCollaborationPage } from './pages/trucking-collaboration/trucking-collaboration.component';
import { TruckingFuriesPage } from './pages/trucking-furies/trucking-furies.component';
import { TruckingHeelPage } from './pages/trucking-heel/trucking-heel.component';
import { TruckingNonCashPaymentPage } from './pages/trucking-non-cash-payment/trucking-non-cash-payment.component';
import { TruckingUpToFiveTonsPage } from './pages/trucking-up-to-five-tons/trucking-up-to-five-tons.component';
import { TruckingUpToThreeTonsPage } from './pages/trucking-up-to-three-tons/trucking-up-to-three-tons.component';
import { TruckingUpToTenTonsPage } from './pages/trucking-up-to-ten-tons/trucking-up-to-ten-tons.component';
import { TruckingUpToTwentyTonsPage } from './pages/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons.component';
import { ManipulatorTransportsPage } from './pages/manipulator-transports/manipulator-transports.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from "@angular/material/tabs";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { HttpClientModule } from '@angular/common/http';
import { FlatbedTrucksPage } from './pages/flatbed-trucks/flatbed-trucks.component';
import { TruckingForIndividualsPage } from './pages/trucking-for-individuals/trucking-for-individuals.component';
import { AutoparkTwoComponent } from './components/autopark-two/autopark-two.component';
import { WorkingCostsComponent } from './components/working-costs/working-costs.component';
import { BoxImageRightComponent } from './components/box-image-right/box-image-right.component';
import { TransportationForBusinessesPage } from './pages/transportation-for-businesses/transportation-for-businesses.component';
import { FreightInsurancePage } from './pages/freight-insurance/freight-insurance.component';
import { TransportationOnRussiaPage } from './pages/transportation-on-russia/transportation-on-russia.component';
import { TentFurgonPage } from './pages/tent-furgon/tent-furgon.component';
import { BlockWithItemsAdvancedComponent } from './components/block-with-items-advanced/block-with-items-advanced.component';
import { TransportationCompanyPage } from './pages/transportation-company/transportation-company.component';
import { JobsPage } from './pages/jobs/jobs.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SitemapPage } from './pages/sitemap/sitemap.component';
import {IConfig, NgxMaskModule} from "ngx-mask";
import {ClientApiModule} from "./api/client/client.api.module";
import {LogoComponent} from "./images/logo/logo.component";
import {PopupJobComponent} from "./components/popup-job/popup-job.component";
import {MskSpbPage} from './pages/msk-spb/msk-spb.component';
import {SpbMskPage} from './pages/spb-msk/spb-msk.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    BannerComponent,
    ServiceComponent,
    WhatChooseUsComponent,
    AutoparkComponent,
    HowToBookShippingComponent,
    OurClientsComponent,
    ActionsAndSalesComponent,
    FaqComponent,
    FaqItemComponent,
    TagsComponent,
    LkComponent,
    BlockDescriptionPageComponent,
    BlockWithItemsComponent,
    FeedbackComponent,
    HomePage,
    ServicesPage,
    AutoparkPage,
    ActionsPage,
    ContactsPage,
    FlatbedTrucksPage,
    NewsPage,
    DeliveryToMarketplacesPage,
    OutsourcingTransportationServicesPage,
    RefrigeratedTruckTransportationPage,
    ServicesSpecialVehiclesPage,
    ShippingByRussiaPage,
    ShippingOnOzonPage,
    ShippingOnWildberriesPage,
    ShippingOnYandexMarketPage,
    TruckingBullockPage,
    TruckingByGazellePage,
    TruckingCollaborationPage,
    TruckingFuriesPage,
    TruckingHeelPage,
    TruckingNonCashPaymentPage,
    TruckingUpToFiveTonsPage,
    TruckingUpToThreeTonsPage,
    TruckingUpToTenTonsPage,
    TruckingUpToTwentyTonsPage,
    ManipulatorTransportsPage,
    PopupCallComponent,
    PopupKpComponent,
    PopupJobComponent,
    TariffComponent,
    TruckingForIndividualsPage,
    AutoparkTwoComponent,
    WorkingCostsComponent,
    BoxImageRightComponent,
    TransportationForBusinessesPage,
    FreightInsurancePage,
    TransportationOnRussiaPage,
    TentFurgonPage,
    BlockWithItemsAdvancedComponent,
    TransportationCompanyPage,
    JobsPage,
    BreadcrumbsComponent,
    SitemapPage,
    MskSpbPage,
    SpbMskPage,
    TariffShortComponent
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        NgbModule,
        MdbCollapseModule,
        BrowserAnimationsModule,
        MatTabsModule,
        SlickCarouselModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgxMaskModule.forRoot(maskConfig),
        ClientApiModule,
        LogoComponent,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
