<app-banner [image]="'/assets/images/freight-insurance/freight-insurance7.svg'" [title]="'Страхование груза'"
  [description]="'Максимальная защита грузов для наших клиентов.'" [showButtonCall]="true" [showButtonKp]="true">
</app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Почему мы?</h2>

    <div class="col-12 col-lg-9 mb-24-xs mb-40-md">
      Транспортная компания «Акро» успешно оказывает полный спектр услуг по перевозке различных видов грузов с 2016
      года.
      В нашу географию входит не только Москва и Московская область,
      но и другие регионы России. Наш автопарк включает в себя большой выбор грузовых автомобилей – от каблука и газели
      до
      рефрижератора и фуры.
    </div>

    <div class="row text-grey4">
      <div class="mb-20-xs col-12 col-md-4 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative border-blue p-20-xs d-flex flex-column justify-content-between align-items-center">
          <img src="/assets/images/freight-insurance/freight-insurance8.svg" class="mb-0-lg ml-10-lg" alt="Стоимость">
          <p class="text-16-xs p-0-xs m-0-xs text-center">Фактическая стоимость груза — должна быть подтверждена
            документально</p>
        </div>
      </div>

      <div class="mb-20-xs col-12 col-md-4 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative border-blue p-20-xs d-flex flex-column justify-content-between align-items-center">
          <img src="/assets/images/freight-insurance/freight-insurance2.svg" class="mb-0-lg ml-10-lg" alt="Характеристика">
          <p class="text-16-xs p-0-xs m-0-xs text-center">Характеристика и индивидуальные особенности вида груза</p>
        </div>
      </div>

      <div class="mb-20-xs col-12 col-md-4 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative border-blue p-20-xs d-flex flex-column justify-content-between align-items-center">
          <img src="/assets/images/freight-insurance/freight-insurance3.svg" class="mb-0-lg ml-10-lg" alt="Сложности">
          <p class="text-16-xs p-0-xs m-0-xs text-center">Сложности и удаленности маршрута перевозки</p>
        </div>
      </div>

      <div class="mb-20-xs col-12">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-blue p-20-xs d-flex justify-content-between align-items-center mt-20-lg flex-column flex-lg-row">
          <div class="col-12 col-lg-9">
            <p class="text-16-xs p-0-xs m-0-xs">С транспортной компанией «АКРО» вы можете быть уверены, что перевозки
              грузов на автомобиле средней тоннажности будут осуществлены быстро и аккуратно. Нас выбирают владельцы
              малого и среднего бизнеса из Москвы, Московской области, Санкт-Петербурга и других городов России.</p>
          </div>
          <div class="col-12 col-md-12 col-lg-3 mt-20-xs mt-0-lg text-center">
            <a href="" role="button"
              class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
              Заказть звонок
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Преимущества страхования</h2>

    <div class="row">
      <div class="col-12 col-lg-6 mb-24-xs">
        <img src="/assets/images/freight-insurance/freight-insurance1.svg" class="mb-0-lg ml-10-lg" alt="Паллета">
      </div>
      <div class="col-12 col-lg-6 mb-24-xs">
        <div class="row">

          <div class="col-12 col-lg d-flex flex-column mr-20-lg">
            <div
              class="align-items-end b-r-16-xs bg-purple border-grey3 flex-fill mt-20-xs mt-0-lg mb-10-lg position-relative row text-decoration-none text-gray3">
              <div class="p-20-xs">
                <p class="text-16-lg pt-20-xs top-0">Избежать возможных убытков</p>
              </div>
            </div>

            <div
              class="align-items-end b-r-16-xs bg-purple border-grey3 flex-fill mt-20-xs mt-0-lg mb-10-lg mt-10-lg position-relative row text-decoration-none text-gray3">
              <div class="p-20-xs">
                <p class="text-16-lg pt-20-xs top-0">Получить гарантию возмещения потенциального ущерба</p>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg d-flex flex-column mr-20-lg">
            <div
              class="align-items-end b-r-16-xs bg-purple border-grey3 flex-fill mt-20-xs mt-0-lg mb-10-lg position-relative row text-decoration-none text-gray3">
              <div class="p-20-xs">
                <p class="text-16-lg pt-20-xs top-0">Страхование распространется на весь срок доставки</p>
              </div>
            </div>
            <div
              class="align-items-end b-r-16-xs bg-purple border-grey3 flex-fill mt-20-xs mt-0-lg mb-10-lg mt-10-lg position-relative row text-decoration-none text-gray3">
              <div class="p-20-xs">
                <p class="text-16-lg pt-20-xs top-0">Выгодные условия страхования</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mt-24-xs mt-24-xs">
            При возникновении страхового случая, вам не придется самостоятельно обращаться в страховую компанию и
            заниматься подписанием лишних документов — данный этап полностью находится в ведении менеджеров компании.
          </div>
        </div>
      </div>

      <div class="col-12">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative border-blue p-20-xs d-flex justify-content-between align-items-center">
          <p class="text-16-xs p-0-xs m-0-xs">Ответственность компании-перевозчика за сохранность груза определена
            действующим законодательством, а также все условия прописаны в договоре страхования. Однако, в том случае,
            если утрата или порча груза произошла по вине третьего лица либо вследствие непреодолимых обстоятельств,
            ответственность компании может быть ограничена.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-48-lg mb-32-xs text-grey4">Формирование<br> стоимости услуги</h2>
    <div class="row m-0-xs mt-48-xs position-relative">
      <div class="mb-20-xs col-12 col-md-8 col-lg-4 p-0">
        <span class="p-0-xs align-self-center text-16-xs text-grey6">Стоимость услуги страхования зависит от многих
          факторов, таких как:</span>
      </div>
    </div>

    <div class="row">
      <div class="mb-20-xs col-12 col-md-6 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Фактическая стоимость груза подтверждена
            документально</p>
          <img src="/assets/images/freight-insurance/freight-insurance9.svg" class="mb-0-lg ml-10-lg" alt="Фактическая стоимость">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-md-6 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Характеристика и особенности вида груза</p>
          <img src="/assets/images/freight-insurance/freight-insurance10.svg" class="mb-0-lg ml-10-lg"  alt="Характеристика">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-md-6 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Сложности и удаленности маршрута перевозки</p>
          <img src="/assets/images/freight-insurance/freight-insurance11.svg" class="mb-0-lg ml-10-lg"  alt="Сложности">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-20-xs col-12 col-md-8 col-lg-6">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative d-flex flex-column justify-content-between align-items-start">
          <p class="p-0-xs align-self-center text-16-xs text-grey6 mb-0">Важным фактором является наличие или отсутствие
            документов, подтверждающих стоимость перевозимых товарно-материальных ценностей:</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-20-xs mb-0-lg col-12 col-md-6 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Для грузов с объявленной стоимостью тариф
            будет ниже</p>
          <img src="/assets/images/freight-insurance/freight-insurance12.svg" class="mb-0-lg ml-10-lg" alt="Тарифы">
        </div>
      </div>
      <div class="mb-20-xs mb-0-lg col-12 col-md-6 col-lg-8">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Если у вас нет документов, подтверждающих
            стоимость груза, то вы вправе объявить его ценность до 50 000 рублей за одно грузовое место.</p>
          <img src="/assets/images/freight-insurance/freight-insurance13.svg" class="mb-0-lg ml-10-lg" alt="Документы">
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-48-lg mb-32-xs text-grey4">Не подлежат страхованию</h2>
    <div class="row m-0-xs my-30-xs mt-0-md position-relative">
      <div class="mb-20-xs col-12 col-lg-6 p-0">
        <span class="p-0-xs align-self-center text-16-xs text-grey6">Или других вещей, которые нельзя перевезти обычным
          легковым<br> автомобилем, но при этом не требуется большой авто типа газели</span>
      </div>
    </div>

    <div class="row">
      <div class="mb-20-xs col-12 col-lg-3">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-9">Денежные знаки, монеты</p>
          <img src="/assets/images/freight-insurance/freight-insurance20.svg" class="mb-0-lg ml-10-lg" alt="Денежные знаки">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-lg-3">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-8">Оружие и боеприпасы</p>
          <img src="/assets/images/freight-insurance/freight-insurance14.svg" class="mb-0-lg ml-10-lg" alt="Оружие">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-lg-4">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Драгоценные металлы, камни и изделия из них
          </p>
          <img src="/assets/images/freight-insurance/freight-insurance15.svg" class="mb-0-lg ml-10-lg" alt="Драгоценности">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-lg-2">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col">Мех</p>
          <img src="/assets/images/freight-insurance/freight-insurance16.svg" class="mb-0-lg ml-10-lg" alt="Мех">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-20-xs col-12 col-lg-3">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Ювелирные изделия</p>
          <img src="/assets/images/freight-insurance/freight-insurance17.svg" class="mb-0-lg ml-10-lg" alt="Ювелирные изделия">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-lg-3">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Наркотические и психотропные вещества</p>
          <img src="/assets/images/freight-insurance/freight-insurance18.svg" class="mb-0-lg ml-10-lg"  alt="Наркотические вещества">
        </div>
      </div>
      <div class="mb-20-xs col-12 col-lg-6">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative bg-purple border-grey3 p-20-xs d-flex justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4 col-10 col-lg-10">Произведения искусства и различные коллекции
          </p>
          <img src="/assets/images/freight-insurance/freight-insurance19.svg" class="mb-0-lg ml-10-lg" alt="Искусство">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-20-xs mb-0-md col-12">
        <div
          class="b-r-16-xs height-to-full align-items-center position-relative d-flex flex-column justify-content-between align-items-start">
          <p class="text-16-xs p-0-xs m-0-xs text-grey4">Для рассчета окончательной цены на перевозку вашего груза с
            учетом услуги страхования, вы можете обратиться к своему персональному менеджеру, либо воспользоваться
            калькулятором, доступным на нашем сайте.</p>
        </div>
      </div>
    </div>

  </div>

  <app-block-with-items [title]="blockWithItems['title']" [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

</div>
