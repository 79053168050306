<app-banner
  [image]="'/assets/images/transportation-on-russia/transportation-on-russia5.svg'"
  [title]="'Грузоперевозки <br>по России'"
  [description]="'Гибкая система скидок и индивидуальные условия'"
  [showButtonCall]="true">
</app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page [description]="descriptionCompany"></app-block-description-page>

  <div class="pb-0-xs pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg text-grey4">
    <div class="row position-relative pb-50-lg pb-25-xs">
      <div class="col col-lg-6 order-lg-2">
        <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Услуги, которые мы оказываем по России</h2>
        <h3>Доставим грузы в торговые сети или до терминалов маркетплейсов.</h3>
        <p>Маркетплейсы (Вайлдберриз, Озон, Яндекс Маркет) являются одним из самых эффективных каналов продаж. Именно поэтому перевозка грузов на склады маркетплейсов - это востребованная услуга, за которой вы можете обратиться в транспортную компанию «Акро». Мы выполняем полный комплекс работ по доставке продукции от адреса вашего склада в крупнейшие интернет-магазины, включая помощь в сборке.</p>
      </div>
      <div class="col-12 col-lg p-0-xs d-flex flex-column justify-content-center order-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia2.svg"
          class="position-absolute-lg bottom-0-lg" alt="Маркетплейсы">
      </div>
    </div>
    <div class="row position-relative pt-80-lg pb-50-lg pb-25-xs pt-25-xs">
      <div class="col-12 col-lg p-0-xs bottom-0-lg d-flex flex-column justify-content-center order-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia3.svg"
          class="position-absolute-lg"  alt="Рефрижераторные перевозки">
      </div>
      <div class="col col-lg-6 order-lg-2">
        <h3>Рефрижераторные перевозки.</h3>
        <p>Определенные группы товаров отличаются малым сроком хранения, и если не соблюдать условия их транспортировки, возможна порча такой продукции или потеря их качеств или товарного вида. Специализированный транспорт требуется для перевозки мясных и колбасных изделий; продуктов из молока и кисломолочной продукции (молоко,мороженое, сметана, кефир, сыр и т.д.); морепродуктов и рыбы (охлажденных или замороженных); свежих фруктов, овощей и ягод; скоропортящихся продуктов питания и кондитерских изделий; лекарств; живых цветов, саженцев деревьев и рассады.</p>
      </div>
    </div>
    <div class="row position-relative pt-70-lg pb-50-lg pb-25-xs pt-25-xs">
      <div class="col col-lg-6">
        <h3>Грузоперевозка с манипулятором.</h3>
        <p>Этот вид спецтехники подходит для решения разнообразных задач в строительной, промышленной, лесозаготовительной или транспортной сфере. Благодаря манипуляторам вы можете не только перевезти свой груз, но и осуществить качественные погрузочно-разгрузочные манипуляции.</p>
      </div>
      <div class="col-12 col-lg p-0-xs d-flex flex-column justify-content-center">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia1.svg"
          class="position-absolute-lg" alt="Манипулятор">
      </div>
    </div>
    <div class="row position-relative pt-80-lg pb-50-lg pb-25-xs pt-25-xs">
      <div class="col-12 col-lg p-0-xs bottom-0-lg d-flex flex-column justify-content-center order-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia3.svg"
          class="position-absolute-lg" alt="Грузовое такси">
      </div>
      <div class="col col-lg-6 order-lg-2">
        <h3>Услуги для частных лиц.</h3>
        <p>Грузовое такси по городу от центра до периферии на авто с малым, средним или большим кузовом, услуги
          спецтехники, услуги грузчиков (в зависимости от габаритов груза, его веса и объёма погрузку, разгрузку и
          доставку до двери может осуществлять водитель-экспедитор или бригада грузчиков).</p>
      </div>
    </div>

  </div>

  <app-block-description-page [description]="descriptionTransportation"></app-block-description-page>

  <app-block-with-items
    [class]="blockWithItems['class']"
    [description]="blockWithItems['description']"
    [title]="blockWithItems['title']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <app-block-with-items [title]="blockWithItems2['title']" [description]="blockWithItems2['description']"
                        [class]="blockWithItems2['class']" [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-autopark></app-autopark>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как оформить заявку на грузоперевозки по России</h2>
    <div class="row point-blue text-grey4">
      <div class="col-12">
        <p>Для создания заявки на грузоперевозку, получения расчета стоимости или индивидуального коммерческого предложения вы можете поступить следующим образом:</p>
      </div>
      <div class="col-12 col-md-6">
        <ul class="p-0-xs">
          <li class="my-20-xs">оставить заказ, заполнив форму обратной связи - мы перезвоним вам через 2 минуты. Чтобы мы могли рассчитать стоимость перевозки, введите следующую информацию: ваше имя, количество коробок или количество паллет, общая масса груза (кг) и количество кубов (м3). Укажите дату забора и время, в которое мы можем приехать и забрать ваш груз, дату отправки,  а также ваш адрес, и конечную точку доставки.</li>
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="p-0-xs">
          <li class="my-20-xs">заявка может быть создана вами самостоятельно через удобный личный кабинет. Вам нужно просто указать данные по новому заказу, заполнить характеристики груза, выбрать желаемый тип транспорта и маршрут, а также какие услуги вам могут потребоваться дополнительно. В личном кабинете вы можете отслеживать ваши отправления, там же будут доступны копии всех закрывающих документов.</li>
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="p-0-xs">
          <li class="my-20-xs">если вы хотите получить предварительную консультацию, расчет или узнать подробнее о нашей работе, наберите номер телефона  8 (499) 350-90-02: наш менеджер сможет предложить вам оптимальный тариф в зависимости от ситуации и ответит на все интересующие вас вопросы.</li>
        </ul>
      </div>
      <div class="col-12 col-md-6 d-flex flex-md-column align-items-md-start align-self-sm-center"><a href=""
                                                                                                      class="btn bg-blue border-blue-hover text-16-lg text-white text-blue-hover col-12 col-md-auto mb-16-xs mb-0-md mr-16-md pt-12-xs pb-12-xs pr-22-xs pl-22-xs"
                                                                                                      role="button">Заказать
        звонок</a>
      </div>
    </div>
  </div>

  <app-block-with-items
    [class]="blockWithItems3['class']"
    [description]="blockWithItems3['description']"
    [title]="blockWithItems3['title']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row position-relative text-grey4">
      <div class="col-12">
        <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Недорогие грузоперевозки по России под
          ключ</h2>
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-between">
        <div class="p-20-xs bg-purple border-grey3 b-r-16-xs d-flex mb-20-xs mb-0-lg">
          <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia9.svg" class="" alt="Выбор">
          <p class="mb-0 ml-20-xs">Идеальный выбор для представителей любых сфер малого, среднего и крупного бизнеса, которым время от времени нужны срочные грузоперевозки по стране</p>
        </div>
        <div class="p-20-xs bg-purple border-grey3 b-r-16-xs d-flex mb-20-xs mb-0-lg">
          <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia10.svg" class="" alt="Квалифицированные сотрудники">
          <p class="mb-0 ml-20-xs">Только квалифицированные сотрудники с опытом работы в логистике от 7 лет</p>
        </div>
        <div class="p-20-xs bg-purple border-grey3 b-r-16-xs d-flex mb-20-xs mb-0-lg">
          <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia11.svg" class="" alt="Контроль">
          <p class="mb-0 ml-20-xs">Контроль перевозки на всех этапах: от подачи заявки до момента выставления счета</p>
        </div>
      </div>
      <div class="col-12 col-lg-6 text-16-xs b-r-16-xs p-24-xs border-blue text-grey6">
        Компания «Акро» - одна из лучших компаний перевозчиков в сегменте транспортных услуг  Москвы, Московской области и других регионов России с опытом успешной работы более 5 лет. Большой автопарк включает в себя разнообразные типы грузовых автомобилей, таких как газель, каблук, фура, тентованные и бортовые грузовики, тралы, изотермы, грузовики с различными видами кузова, что позволяет решать разнообразные логистические задачи. Каждая машина своевременно проходит техническое обслуживание и все необходимые проверки. Если вы планируете переезд, вам нужна перевозка хрупких или негабаритных вещей, опасных и крупногабаритных грузов - обращайтесь к нам! Выгодные тарифы, широкий спектр услуг и индивидуальный подход к каждому клиенту позволяет не только проработать оптимальный маршрут, но и сэкономить ваши денежные и временные ресурсы. «Акро» - надежный и честный партнер. Наши клиенты всегда довольны и  говорят нам “спасибо” - рекомендуем посмотреть отзывы, которые они оставляют
      </div>
    </div>
  </div>
</div>

<app-box-image-right class="point-blue text-grey4"
                     [alt]="'гарантии'"
                     [description]="'Имея достаточный опыт в сфере грузоперевозок, компания «АКРО» осуществит доставку любых видов грузов и для абсолютно любого бизнеса с разной формой собственности (юридических лиц, ооо или индивидуальных предпринимателей).
  Мы на 100% гарантируем безопасность вашего товара и полностью берем на себя его страхование. На всех этапах перевозки мы несем полную ответственность за ваш груз: он всегда будет надежно закреплен, бережно погружен и в целости разгружен. В случае причинения ущерба или при наличии повреждений, появившихся по нашей вине, мы компенсируем ваши затраты в полном размере.С транспортной компанией «Акро» вы можете быть уверены, что доставка вашего груза по России осуществляется быстро и аккуратно. Нас выбирают владельцы малого и среднего бизнеса из таких мегаполисов как Москва, Санкт-Петербург и других городов России. Мы гарантируем отличный сервис и качество нашей работы, о чем свидетельствуют отзывы наших довольных клиентов!'"
                     [imageClass]="'col-12 col-lg-6 p-0-xs text-center'" [image]="'/assets/images/transportation-on-russia/transportation-on-russia12.svg'"
                     [title]="'Гарантии'">
</app-box-image-right>

<div class="pb-50-xs pt-50-xs pb-60-lg pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg container">
  <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как мы работаем</h2>
  <p class="mb-30-xs text-24-xs">Оформите заявку одним из удобных для вас способов:</p>
  <div class="row m-0-xs">
    <div class="col-12 border-blue b-r-16-xs">
      <div class="row p-20-xs p-40-lg">
        <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">01</div>
        <div class="col-12 col-lg">
          <h3 class="text-24-xs mb-16-xs"></h3>
          <p class="text-20-xs">Оставьте запрос на обратный звонок, заполнив соответствующую форму на сайте (укажите ваш номер телефона и e-mail), - наш менеджер перезвонит вам в ближайшее время;</p>
        </div>
        <div class="col-12 col-lg-auto d-flex">
          <img src="/assets/images/transportation-on-russia/transportation-on-russia16.svg" class="margin-auto-lg pt-20-xs pb-30-xs" alt="Запрос на звонок">
        </div>
      </div>
      <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
        <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">02</div>
        <div class="col-12 col-lg">
          <h3 class="text-24-xs mb-16-xs"></h3>
          <p class="text-20-xs">Создайте новую заявку в личном кабинете, заполнив всю необходимую информацию;</p>
        </div>
        <div class="col-12 col-lg-auto d-flex">
          <img src="/assets/images/transportation-on-russia/transportation-on-russia17.svg" class="margin-auto-lg pt-20-xs pb-30-xs" alt="Создание заявки">
        </div>
      </div>
      <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
        <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">03</div>
        <div class="col-12 col-lg">
          <h3 class="text-24-xs mb-16-xs"></h3>
          <p class="text-20-xs">Позвоните нам сами: номер телефона 8 (499) 350-90-02 -  наш менеджер подберет для вас оптимальный тариф и ответит на все интересующие вас вопросы.</p>
        </div>
        <div class="col-12 col-lg-auto d-flex">
          <img src="/assets/images/transportation-on-russia/transportation-on-russia13.svg" class="margin-auto-lg pt-20-xs pb-30-xs" alt="Звонок">
        </div>
      </div>
    </div>
    <p class="mt-40-xs text-18-xs">Персональный менеджер нашей компании, закрепленный за вашей организацией, сделает просчет стоимости перевозки с учетом всех деталей, подберет для вас оптимальный маршрут до места, подготовит и обеспечит учет всех необходимых документов, решит любой вопрос, связанный с вашей заявкой. Сопровождение заявки менеджером осуществляется на всех стадиях - от создания онлайн-заявки до выставления счета. При необходимости мы обеспечим упаковку и хранение вашего груза или товара, вынос и погрузку вещей в машину на основании приложения к договору.
      <br><br>
      Качество нашей работы обеспечивают проверенные высококвалифицированные сотрудники. Опытные водители нашей организации ежедневно доставляют до 800 т груза, а сотрудники отдела качества контролируют работу водителя в процессе перевозки. Вы можете не переживать за своевременность доставки груза и безопасность транспортировки!</p>
  </div>
</div>

<app-tags [title]="tags['title']" [items]="tags['items']" class=""></app-tags>

<app-tags [title]="tags2['title']" [items]="tags2['items']" class=""></app-tags>

<div class="container">
  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
