import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-autopark-two',
  templateUrl: './autopark-two.component.html',
  styleUrls: ['./autopark-two.component.less']
})
export class AutoparkTwoComponent {

  @Input() columns: {
    title: string;
    price: string;
    extra_hour: string;
    include: string;
    overrun: string;
    driver_assistance: string;
    number_europallets: string;
    image: string;
    alt: string;
    link: string;
  } [] | undefined
}
