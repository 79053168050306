<div class="container pt-50-xs pt-36-lg pb-50-xs pb-60-lg">
  <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs pl-20-xs pr-20-xs pl-0-lg pr-0-lg">Автопарк</h2>
  <div class="row text-lg-start pr-50-xs pr-0-lg pl-50-xs px-15-lg justify-content-center">

    <ngx-slick-carousel class="carousel p-0-xs"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="slide pr-10-xs pl-10-xs">
        <div class="bg-purple p-20-xs pr-20-lg pl-20-lg pt-10-lg pb-10-lg b-r-16-xs border-grey3">
          <div
            class="row justify-content-lg-between justify-content-center flex-lg-nowrap border-bottom pb-8-lg mb-16-lg py-20-xs flex-column flex-md-row">
            <div class="mb-25-lg col-auto align-self-center">
              <h3 class="text-grey4 text-24-lg mb-0-lg mb-30-xs">{{slide['title']}}</h3>
            </div>
            <div class="mb-25-lg col-auto align-self-center mb-20-xs mb-0-lg">
              <img [src]="slide['image']" alt="{{slide['alt']}}" loading="lazy"/>
            </div>
          </div>
          <div class="row justify-content-md-between justify-content-center mb-10-lg">
            <div class="col-auto pb-10-xs pt-30-xs pt-0-lg pb-0-lg text-center-lg">
              <span class="text-grey5 text-16-lg">Стоимость часа</span>
              <br>
              <span class="text-18-lg">{{slide['price']}}</span>
            </div>
            <div class="col-auto py-10-xs py-0-lg text-center-lg">
              <span class="text-grey5 text-16-lg">Кол-во европаллет</span>
              <br>
              <span class="text-18-lg text-center d-block">{{slide['number_europallets']}}</span>
            </div>
          </div>
          <div class="row justify-content-md-between justify-content-center">
            <div class="col-auto py-10-xs py-0-lg text-center-lg">
              <span class="text-grey5 text-16-lg">Мин. стоимость</span>
              <br>
              <span class="text-18-lg">{{slide['min_price']}} / {{slide['hours']}}</span>
            </div>
            <div class="col-auto align-self-center py-10-xs py-0-lg">
              <a href="{{slide['link']}}" class="d-block border-blue bg-blue-hover b-r-8-xs text-16-lg text-white-hover text-decoration-none mt-8-xs pt-4-xs pb-4-xs pr-28-xs pl-28-xs">Подробнее</a>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>

    <div class="row justify-content-center">
      <a href="/avtopark" class="col-auto text-decoration-none text-white bg-blue bg-white-hover border-blue-hover text-16-xs text-blue-hover mt-44-xs pt-14-xs pb-14-xs pr-22-xs pl-22-xs b-r-8-xs">Смотреть весь автопарк</a>
    </div>
  </div>
</div>
