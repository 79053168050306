<app-banner 
  [image]="'/assets/images/banner/banner-spb-msk.svg'" 
  [title]="'Грузоперевозки из Санкт-Петербурга в Москву'"
  [description]="''" 
  [showButtonCall]="true" 
  [showButtonKp]="true">
</app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page [description]="descriptionFirst"></app-block-description-page>

  <app-tariff-short 
    [typeActive]="typeActive" 
    [typeTitle]="typeTitle" 
    [tariffs]="tariffs" 
    [showButtonCall]="true">
  </app-tariff-short>

  <app-block-with-items-advanced 
    [title]="blockWithItemsAdvanced1['title']"
    [description]="blockWithItemsAdvanced1['description']" 
    [rowClass]="blockWithItemsAdvanced1['rowClass']"
    [items]="blockWithItemsAdvanced1['items']">
  </app-block-with-items-advanced>

  <app-block-with-items-advanced 
    [title]="blockWithItemsAdvanced['title']"
    [description]="blockWithItemsAdvanced['description']" 
    [rowClass]="blockWithItemsAdvanced['rowClass']"
    [items]="blockWithItemsAdvanced['items']">
  </app-block-with-items-advanced>

  <!-- <div class="pb-50-xs pt-50-xs pt-60-lg pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Почему выбирают нас</h2>
      <div class="row d-flex align-items-cente">
          <div class="col-12 col-lg-8 d-flex flex-column p-0-xs">
              <div class="row mb-20-lg">
                  <div class="col-12 col-lg-6">
                  <div class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs bg-white-lg position-relative z-2-xs">
                      <p class="text-17-lg p-0 m-0">Доставка от Спб до Москвы организовывается по системе 
                        “от двери до двери”</p>
                  </div>
                  </div>
  
                  <div class="col-12 col-lg-6">
                  <div class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs bg-white-lg position-relative z-2-xs">
                      <p class="text-17-lg p-0 m-0">Осуществим доставку сборных грузов весом до 20 тонн</p>
                  </div>
                  </div>
  
              </div>
              <div class="row">
  
                  <div class="col-12">
                  <div class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs bg-white-lg position-relative z-2-xs">
                      <p class="text-17-lg p-0 m-0">Выполним express - перевозку и транспортировку с использованием разных видов спецтехники. </p>
                  </div>
                  </div>
  
              </div>
          </div>
          <div class="col-12 col-lg-4 position-relative">
              <img  class="position-absolute-lg mw-percent-130-lg right-0-lg" src="assets/images/spb-msk/delivery.svg" alt="Доставка">
          </div>
      </div>
    </div> -->

  <div class="pb-50-xs pt-50-xs pt-60-lg pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Доставка от Санкт-Петербурга до Москвы</h2>
    <div class="row d-flex align-items-cente">
      <div class="col-12 col-lg-8 d-flex flex-column p-0-xs">
        <div class="row mb-20-lg">
          <div class="col-12 col-lg-6">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs bg-white-lg position-relative z-2-xs">
              <p class="text-17-lg p-0 m-0">Доставка от Спб до Москвы организовывается по системе
                “от двери до двери”</p>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs bg-white-lg position-relative z-2-xs">
              <p class="text-17-lg p-0 m-0">Осуществим доставку сборных грузов весом до 20 тонн</p>
            </div>
          </div>

        </div>
        <div class="row">

          <div class="col-12">
            <div
              class="border-blue b-r-16-xs mb-20-xs mb-0-lg align-items-center text-gray3 p-20-xs bg-white-lg position-relative z-2-xs">
              <p class="text-17-lg p-0 m-0">Выполним express - перевозку и транспортировку с использованием разных видов
                спецтехники. </p>
            </div>
          </div>

        </div>
      </div>
      <div class="col-12 col-lg-4 position-relative">
        <img class="position-absolute-lg mw-percent-130-lg right-0-lg" src="assets/images/spb-msk/delivery.svg"
          alt="Доставка">
      </div>
    </div>
  </div>



  <div class="text-lg-start pr-20-xs pr-0-lg pl-20-xs pl-0-lg d-md-block d-none">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Стоимость доставки</h2>
    <p class="mb-20 mb-30-lg p-0-xs text-18-xs">По России и из Санкт-Петербурга в Москву в частности рассчитывается <br>
      в индивидуальном порядке по запросу и зависит от:</p>

    <div class="row text-grey3">
      <div class="col-4 pr-20-xs d-flex flex-column">
        <div class="row flex-fill bg-purple border-grey3 b-r-16-xs p-20-xs align-content-between">
          <div>
            <img loading="lazy" src="assets/images/spb-msk/handbox.svg" alt="дополнительные услуги">
          </div>
          <p class="text-17-lg p-0 m-0">Нужны ли вам дополнительные услуги (погрузка и разгрузка, необходимость
            страхования, упаковка, ответственное хранение)</p>
        </div>
      </div>
      <div class="col-4 pl-20-xs pr-20-xs d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg align-items-center text-gray3 p-20-xs"
          style="height: 50%;">
          <p class="col text-17-lg p-0 m-0">Периодичности перевозок</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/spb-msk/calendar.svg" alt="Периодичность">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg align-items-center text-gray3 p-20-xs"
          style="height: 50%;">
          <p class="col text-17-lg p-0 m-0">Типа и особенностей груза</p>
          <div class="col-auto">
            <img src="assets/images/spb-msk/boxes.svg" alt="Тип груза">
          </div>
        </div>
      </div>
      <div class="col-4 pl-20-xs pr-20-xs d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg align-items-center text-gray3 p-20-xs"
          style="height: 50%;">
          <p class="col text-17-lg p-0 m-0">Параметров груза (вес в кг, объем, длина, ширина и высота)</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/spb-msk/box.svg" alt="Параметры">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg align-items-center text-gray3 p-20-xs"
          style="height: 50%;">
          <p class="col text-17-lg p-0 m-0">Протяженности и сложности дороги, стоимости проезда платных дорог</p>
          <div class="col-auto">
            <img src="assets/images/spb-msk/geo-car.svg" alt="Протяженность">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row position-relative pt-70-xs pb-50-xs pl-20-xs pr-20-xs pr-0-sm-0 pl-0-sm">
    <div class="col col-lg-7">
      <h3 class="text-32-xs text-48-lg mb-32-xs text-grey4">Компания «Акро»</h3>
      <p>Это идеальный вариант для представителей любых сфер малого, среднего и крупного бизнеса, которым время от
        времени нужны грузоперевозки по Москве, Подмосковью
        и другим регионам на территории России.</p>
      <p>С нами прямые контейнерные перевозки по всей стране могут быть осуществлены в максимально короткие сроки. Мы
        гарантируем, что ваш груз будет доставлен быстро и без каких-либо повреждений, а подача транспорта в срочном
        порядке может быть организована уже спустя несколько часов после оформления заявки. Низкие цены и специальные
        условия для постоянных клиентов вас порадуют!</p>
    </div>
    <div class="col-12 col-lg p-0-xs d-flex justify-content-center flex-center align-items-center">
      <img loading="lazy" src="assets/images/spb-msk/cremlin.svg" class="position-absolute-lg"
        alt="геометка фургон карта">
    </div>
  </div>

  <div class="pb-50-xs pt-50-xs pt-60-lg pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row">
      <h2 class="text-36-xs text-44-lg text-grey4 mb-40-xs mt-0-xs col-12">Как оформить заявку на грузоперевозки <br> из
        Санкт-Петербурга в Москву</h2>
      <p class="col-lg-4 col-12">Для создания заявки на грузоперевозку, получения расчета стоимости или индивидуального
        коммерческого предложения
        вы можете поступить следующим образом:</p>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Заказать обратный звонок</h3>
            <p class="text-16-xs">Заполните форму на сайте - мы перезвоним вам через 2 минуты. Чтобы мы могли рассчитать
              стоимость перевозки, введите следующую информацию: ваше имя, количество коробок или количество паллет,
              общая масса груза (кг) и количество кубов (м3). Укажите дату забора и время, в которое мы можем приехать и
              забрать ваш груз, дату отправки,  а также ваш адрес, и конечную точку доставки.</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs">
            <img src="assets/images/spb-msk/phone.svg" alt="телефон">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Создание заявки через личный кабинет</h3>
            <p class="text-16-xs">Вам нужно просто указать данные по новому заказу, заполнить характеристики груза,
              выбрать желаемый тип транспорта и маршрут, а также какие услуги вам могут потребоваться дополнительно. В
              личном кабинете вы можете отслеживать ваши отправления, воспользоваться калькулятором для предварительного
              расчета, там же будут доступны копии всех закрывающих документов.</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/spb-msk/computer2.svg" alt="ноутбук">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Менеджер проверит параметры заказа</h3>
            <p class="text-16-xs">Если вы хотите получить предварительную консультацию, расчет или узнать подробнее о
              нашей работе, наберите номер телефона  8 (499) 350-90-02 (прием заявок также возможен через telegram): наш
              менеджер сможет предложить вам оптимальный тариф в зависимости от ситуации и ответит на все интересующие
              вас вопросы.</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/spb-msk/computer1.svg" alt="ноутбук">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>

</div>
