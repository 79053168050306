<header itemscope itemtype="http://schema.org/WPHeader">

  <meta itemprop="headline" content="tk-akro">
  <meta itemprop="description" content="Транспортная компания в Москве - автомобильные грузоперевозки под ключ">
  <meta itemprop="keywords" content="Транспортная компания в Москве - автомобильные грузоперевозки под ключ">

  <div class="bg-purple pt-20-xs pb-10-xs pr-50-xs pl-50-xs p-0-lg text-16-md w-percent-100-xs top-0 position-fixed z-102-xs">
    <div class="row align-items-center justify-content-between">
      <a class="nav-link col-auto d-block d-lg-none" aria-current="page" href="/">
        <app-logo-svg alt="logo image" ></app-logo-svg>
      </a>
      <div class="navbar-expand-lg col-auto p-0-xs w-percent-100-lg">
        <button
          class="navbar-toggler align-items-start d-block d-lg-none text-blue"
          type="button"
          (click)="jumbotronExample.toggle()"
          aria-controls="jumbotronExample"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Меню <img loading="eager" src="assets/images/icon/hamberger.svg" alt="hamburger menu">
        </button>

        <div class="collapse navbar-collapse z-2-xs position-absolute-xs position-relative-lg start-0 w-percent-100-xs bg-purple mt-12-xs mt-0-xs pr-20-xs pr-0-lg pl-20-xs pl-0-lg"
             mdbCollapse
             #jumbotronExample="mdbCollapse"
             id="jumbotronExample">

          <nav itemscope itemtype="http://schema.org/SiteNavigationElement" class="navbar navbar-light col-auto col-md-12 pt-16-md pb-16-md row justify-content-between container">
            <ul class="navbar-nav col-12 col-lg-auto row p-0 mb-2-xs mb-0-md ">
              <li class="nav-item col-auto d-none d-lg-block pr-50-xs">
                <a class="row flex-nowrap text-14-xs text-grey4 text-decoration-none" aria-current="page" href="/">
                  <app-logo-svg alt="logo image" class="w-100-xs p-0-xs"></app-logo-svg>
                  <div class="col-auto pr-20-xs pl-20-xs">
                    <div class="bg-grey width-to-minimal height-to-full"><hr class="border-0" /></div>
                  </div>
                  <span class="p-0-xs">Грузоперевозки <br> для бизнеса</span>
                </a>
              </li>
              <li class="nav-item col-auto position-relative" *ngFor="let item of menu">
                <span class="text-14-xs text-purple d-block text-decoration-none pt-8-xs pt-6-lg pb-8-xs pb-6-lg cursor-pointer"
                      aria-current="page"
                      *ngIf="item['items']"
                      (click)="toggleMenu(item)"
                >
                  {{item.title}}
                </span>
                <a href="{{item.link}}"
                   itemprop="url"
                   class="text-14-xs text-purple d-block text-decoration-none pt-8-xs pt-6-lg pb-8-xs pb-6-lg"
                   aria-current="page"
                   *ngIf="!item['items']"
                >
                  {{item.title}}
                </a>

                <app-menu *ngIf="item['items']" [show]="!!item['show']" [items]="item['items']"></app-menu>
              </li>
            </ul>
            <ul class="navbar-nav col-12 col-lg-auto p-0 mb-2-xs mb-0-md">
              <li class="nav-item col-auto">
                <a class="nav-link text-purple" itemprop="url" aria-current="page" href="tel: 84993509002">8 (499) 350-90-02</a>
              </li>
              <li class="nav-item col-auto">
                <a class="nav-link text-purple" itemprop="url" href="mailTo: info@tk-akro.ru">info@tk-akro.ru</a>
              </li>
              <li class="nav-item col-auto">
                <div class="bg-grey width-to-minimal height-to-full"><hr class="border-0" /></div>
              </li>
              <li class="nav-item col-auto">
                <a class="nav-link text-purple" target="_blank" itemprop="url" href="https://client.tk-akro.ru">Личный кабинет</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>
