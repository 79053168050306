<app-banner
  [image]="'/assets/images/banner/banner-shipping-on-yandex-market.svg'"
  [title]="'Доставка на маркетплейс <br>Яндекс Маркет'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [typeActive]="typeActive"
      [typeTitle]="typeTitle"
      [tariffs]="tariffs"
      [header]="header"
    >
    </app-tariff>
  </div>

  <app-block-description-page [description]="description2"></app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-xs text-grey4 mb-48-xs">Как оформить доставку:</h2>
    <div class="row text-grey9 mb-40-xs">
      <div class="col-12 col-lg mb-20-xs mb-0-lg">
        <p class="text-16-xs">Сотрудничество с «Акро» — это несколько простых шагов. Перевозка грузов с помощью транспортной компании «Акро» из любого региона или города России до склада маркетплейса быстрая, безопасная и с соблюдением всех правил площадки. Мы гарантируем полное сопровождение сделки на всех этапах. При необходимости ценный и дорогостоящий груз будет бесплатно застрахован.</p>
      </div>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Оставьте онлайн - заявку на доставку нашем сайте. Вы можете заказать звонок, заполнив форму обратной связи (укажите ваше имя, телефон и адрес электронной почты, инн), или использовать для этого удобный личный кабинет, в котором можно добавить и оформить новую доставку или скопировать предыдущую. Ваш персональный менеджер перезвонит вам в течение двух минут, уточнит стоимость тарифа в зависимости от особенностей и условий сотрудничества. Ему нужно предоставить информацию о маршруте и грузе (вес в кг  и количество коробов, габариты и т.д.), также уточнить дату забора и поставки, адрес вашего магазина, склада или производства. При оформлении доставки укажите полные сведения. Заполняя новую заявку, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-1.svg" class="margin-auto-lg" alt="Онлайн заявка">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Менеджер проверит параметры заказа, подберет подходящий транспорт и  согласует с вами условия и тариф. После этого наш специалист сообщит тип, марку и номер транспорта, который заберет груз, контакты Яндекс Маркет в Москве или другом городе, куда будет доставлена продукция.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-2.svg" class="margin-auto-lg" alt="Согасование условий">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs">Непосредственно в день или за сутки до запланированной отгрузки мы можем забрать груз с вашего склада или производства, либо вы можете отправить или привезти его к нам своими силами. Если потребуется, мы сами упакуем коробки на паллетах и поможем погрузить упакованную продукцию в автомобиль. В перспективе мы добавим новую услугу - хранение вашего товара на нашем складе. Перед отправкой наши сотрудники еще раз проверят правильность маркировки.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-3.svg" class="margin-auto-lg" alt="Забор товара">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Мы отвозим груз в распределительный центр Яндекс Маркет, где посылки сортируют и распределяют для отправки в пункты выдачи, курьерскую службу или на склад. Груз будет доставлен на склад маркетплейса в соответствии с назначенным временем. Даже если автомобиль приедет раньше, доплачивать за ожидание вам не придется. ТК «Акро» доставляет груз на склад быстро и в полной сохранности в назначенные сроки.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-4.svg" class="margin-auto-lg" alt="Отвоз груза">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">05</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">В том случае, если по какой-то причине склад маркетплейса не принимает вашу поставку, мы оперативно решаем эту проблему либо связываемся с вами. При невозможности решения вопроса на месте, мы можем осуществить возврат.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-5.svg" class="margin-auto-lg" alt="Груз">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">06</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">После оказания услуги вы производите оплату на наш расчетный счет. Копия всей документации будет доступна после регистрации, а оригиналы будут отправлены курьером в удобное для вас время.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-6.svg" class="margin-auto-lg" alt="Оплата">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-description-page [description]="description1"></app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>
  <p>Для доставки на склад Яндекс Маркет используются только европаллеты с габаритами 120 × 80 см. При формировании паллеты стоит учитывать требования каждого склада. Эти данные доступны в разделе справочной информации данного маркетплейса.</p>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>
  <p>Непосредственно перед поставкой в назначенный день мы еще раз проверяем и сдаем груз на склад Яндекс Маркет и подтверждаем доставку, а всю бухгалтерскую  документацию направим  в систему электронного документооборота.</p>

  <app-block-with-items
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-10-xs pr-0-lg pl-10-xs pl-0-lg">
    <div class="row m-0-xs">
      <div class="col-12 col-lg-4 pb-20-xs text-grey9">
          <span class="text-24-xs">Выберите нужный вам склад и укажите эти данные:</span>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Томилино</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Софьино</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Парголово</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Екатеринбург</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Шушары (транзит)</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Самарская область</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Ростовская область</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Климовск (транзит)</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 pb-20-xs">
        <div class="bg-purple border-grey3 b-r-16-xs p-20-xs">
          <p class="text-18-xs mb-22-xs">Ростовская область (транзит)</p>
          <div class="d-block">
            <a href="" class="text-16-xs border-blue pt-6-xs pb-6-xs pr-20-xs pl-20-xs b-r-16-xs text-decoration-none" role="button" (click)="$event.preventDefault(); popupService.togglePopup(popupService.PopupEnum.CALLBACK)">
              Интересует
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <app-block-with-items
    [title]="blockWithItems4['title']"
    [description]="blockWithItems4['description']"
    [class]="blockWithItems4['class']"
    [items]="blockWithItems4['items']">
  </app-block-with-items>
  <p class="text-18-xs">Большое разнообразие транспортных средств для аренды: наши авто доставят моно- и микс-паллеты, малогабаритный и крупногабаритный груз от 1 кг до 20 тонн. Вы можете выбрать например тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши менеджеры всегда готовы помочь с выбором.</p>

  <app-block-description-page [description]="description"></app-block-description-page>

  <app-tags [title]="tags['title']" [items]="tags['items']"></app-tags>

  <div class="container">
    <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
      <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Ответы на часто задаваемые вопросы</h2>
      <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
    </div>
  </div>
</div>
