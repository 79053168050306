<app-banner 
  [image]="'/assets/images/transportation-company/transportation-company3.svg'"
  [title]="'Транспортная<br> компания «Акро»'" 
  [description]="'Гибкая система скидок и индивидуальные условия'"
  [showButtonCall]="true" [showButtonKp]="true">
</app-banner>

<app-service [services]="services"></app-service>

<div class="container-fluid overflow-hidden p-0">
  <div class="container">
    <div class="pb-50-xs pt-50-xs pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
      <h2 class="text-32-xs text-48-lg mb-32-xs text-grey4">Преимущества работы<br> с нашей службой</h2>
      <div class="row m-0-xs mt-0-xs mt-48-md position-relative">
        <div class="mb-20-xs col-12 col-lg-8 p-0">
          <span class="p-0-xs align-self-center text-16-xs text-grey6">Компания «Акро» — идеальная для представителей
            сфер малого, среднего и крупного бизнеса, которым нужны грузоперевозки по Москве, Подмосковью и другим
            регионам на территории России. Наша компания в процессе своей работы успела зарекомендовать себя как
            надежный
            и честный партнер. Сотрудничая с нами, вы получаете:</span>
        </div>
        <div
          class="col-12 col-lg-5 col-xl-5 mt-20-xs mt-0-lg my-30-xs position-absolute-lg d-flex align-items-center justify-content-center"
          style="right: -100px; top: -85px;">
          <img loading="lazy" src="/assets/images/transportation-company/transportation-company2.svg" alt="манипулятор">
        </div>
      </div>

      <div class="row">
        <div class="mb-20-xs col-12 col-md-12 col-lg-4">
          <div
            class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-center align-items-start">
            <p class="text-18-xs p-0-xs m-0-xs text-grey4">Индивидуальный подход к расчету стоимости автоэкспедирования
            </p>
          </div>
        </div>
        <div class="mb-20-xs col-12 col-md-12 col-lg-4">
          <div
            class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-center align-items-start">
            <p class="text-18-xs p-0-xs m-0-xs text-grey4">Персонального менеджера, который всегда ответит на все ваши
              вопросы</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-20-xs col-12 col-md-12 col-lg-4">
          <div
            class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-center align-items-start">
            <p class="text-18-xs p-0-xs m-0-xs text-grey4">Большой выбор транспортных средств под любые грузы и
              расстояния</p>
          </div>
        </div>
        <div class="mb-20-xs col-12 col-md-12 col-lg-4">
          <div
            class="b-r-16-xs height-to-full position-relative bg-purple border-grey3 p-20-xs d-flex flex-column justify-content-center align-items-start">
            <p class="text-18-xs p-0-xs m-0-xs text-grey4">Налаженный документооборот</p>
          </div>
        </div>
        <div class="mb-20-xs col-12 col-md-12 col-lg-4">
          <div
            class="b-r-16-xs height-to-full d-flex flex-column justify-content-center align-items-start p-20-xs p-0-lg bg-purple bg-white-lg border-grey3 border-white-lg">
            <p class="text-16-xs p-0-xs m-0-xs text-grey6">Личный кабинет, в котором вы легко можете создавать новые
              заявки и отслеживать текущие</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-autopark></app-autopark>

<app-actions-and-sales [items]="actionsAndSales"></app-actions-and-sales>

<div class="container" id="ecommerce">
  <app-block-with-items 
    [title]="blockWithItems['title']" 
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']" 
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row position-relative text-grey4">
      <div class="col-12">
        <h3 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Почему это будет выгодно для вас?</h3>
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-between">
        <div class="p-20-xs bg-purple border-grey3 b-r-16-xs d-flex mb-3 mb-0-lg">
          <p class="mb-0 mb-lg-3">Оплата начинается только 3-го часа простоя</p>
        </div>
        <div class="p-20-xs bg-purple border-grey3 b-r-16-xs d-flex mb-3 mb-0-lg">
          <p class="mb-0">Перевозка с фиксированной стоимостью от 1800 ₽ с НДС за 1 палет</p>
        </div>
        <div class="p-20-xs bg-purple border-grey3 b-r-16-xs d-flex mb-3 mb-0-lg">
          <p class="mb-0">Не нужно заказывать отдельную машину, если вам требуется отправить один паллет или одну
            коробку</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <p class="text-16-xs b-r-16-xs p-24-xs border-blue text-grey6">
          С транспортной компанией «Акро» вы можете быть уверены, что перевозки грузов будут осуществлены быстро,
          аккуратно и недорого. Нас выбирают владельцы интернет-магазинов, гипермаркетов, малых и средних предприятий из
          Москвы, Санкт-Петербурга и других городов РФ. Наша организация гарантирует ответственный подход, отличный
          сервис, быстрые грузоперевозки и качество нашей работы,
          о чем свидетельствуют отзывы наших довольных клиентов! Перевозки с компанией «Акро» — это быстро, надежно
          и выгодно. Вы можете быть уверены, что в штате нашей компании работают самые квалифицированные сотрудники,
          а наш автопарк укомплектован техникой, которая удовлетворяет все требования.
        </p>
      </div>
    </div>
  </div>
</div>

<app-our-clients></app-our-clients>

<div class="container">
  <div class="row position-relative pt-70-xs pb-50-xs pl-20-xs pr-20-xs pr-0-sm-0 pl-0-sm">
    <div class="col col-lg-6">
      <h3 class="text-32-xs text-48-lg mb-32-xs text-grey4">Автомобильные транспортные перевозки грузов</h3>
      <p>Услуга, которая в той или иной степени востребована как в сфере бизнеса, так и среди обычных людей. На
        сегодняшний день рынок автоперевозок стабильно растет, что связано с восстановлением многих отраслей экономики
        после пандемии и ростом спроса на логистические услуги в целом. Фирмы и их филиалы по перевозке грузов
        представлены в огромном количестве по всей России, поэтому крайне важно выбрать того партнера, с которым ваши
        задачи будут решены быстро в сроки, без проблем и контроля.</p>
    </div>
    <div class="col-12 col-lg p-0-xs d-flex justify-content-center flex-center align-items-center">
      <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia1.svg"
        class="position-absolute-lg" alt="геометка фургон карта">
    </div>
  </div>

  <app-block-description-page [description]="descriptionTransportation"></app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row position-relative pt-80-lg pb-50-lg">
      <div class="col-12 col-lg p-0-xs bottom-0-lg d-flex align-items-center justify-content-center pb-30-xs pb-0-lg">
        <img loading="lazy" src="/assets/images/tent-furgon/tent-furgon11.svg" class="position-absolute-lg" alt="список пунктов">
      </div>
      <div class="col col-lg-6">
        <p>С транспортной компанией «Акро» вы можете быть уверены, что перевозки грузов будут осуществлены быстро,
          аккуратно и недорого. Нас выбирают владельцы интернет-магазинов, гипермаркетов, малых и средних предприятий из
          Москвы, Санкт-Петербурга и других городов РФ. Наша организация гарантирует ответственный подход, отличный
          сервис, быстрые грузоперевозки и качество нашей работы, о чем свидетельствуют отзывы наших довольных клиентов!
          Перевозки с компанией «Акро» — это быстро, надежно и выгодно. Вы можете быть уверены, что в штате нашей
          компании работают самые квалифицированные сотрудники, а наш автопарк укомплектован техникой, которая
          удовлетворяет все требования.</p>
      </div>
    </div>
  </div>

  <app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>

</div>

<div class="container">
  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
