<div class="container pt-50-xs pt-50-lg pb-50-xs pb-90-lg">
  <div class="row text-lg-start pr-30-xs pr-0-lg pl-30-xs pl-0-lg px-15-lg">
    <span class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как мы работаем</span>
    <p class="text-20-lg mb-30-xs">Для заказа авто вам доступны несколько вариантов:</p>

    <div class="row p-0-lg mb-0-lg px-15-lg">
      <div class="col-12 col-lg pr-0-xs pl-0-xs pt-12-xs pb-12-xs">
        <div class="row flex-nowrap align-items-center">
          <span class="col-auto text-36-xs text-48-lg text-blue mr-10-lg">01</span>
          <p class="col m-0 text-20-lg">вы можете оставить заявку на звонок, заполнив форму обратной связи на сайте компании (менеджер свяжется с вами с течение двух минут);</p>
        </div>
      </div>
      <div class="col-12 col-lg pr-0-xs pl-0-xs pt-12-xs pb-12-xs">
        <div class="row flex-nowrap align-items-center">
          <span class="col-auto text-36-xs text-48-lg text-blue mr-10-lg">02</span>
          <p class="col m-0 text-20-lg">создать новую заявку в личном кабинете;</p>
        </div>
      </div>
    </div>
    <div class="row p-0-lg mt-24-lg px-15-lg">
      <div class="col-12 col-lg pr-0-xs pl-0-xs pt-12-xs pb-12-xs">
        <div class="row flex-nowrap align-items-center">
          <span class="col-auto text-36-xs text-48-lg text-blue mr-10-lg">03</span>
          <p class="col m-0 text-20-lg">написать на почту info@tk-akro.ru</p>
        </div>
      </div>
      <div class="col-12 col-lg pr-0-xs pl-0-xs pt-12-xs pb-12-xs">
        <div class="row flex-nowrap align-items-center">
          <span class="col-auto text-36-xs text-48-lg text-blue mr-10-lg">04</span>
          <p class="col m-0 text-20-lg">позвонить нам по телефону 8 (499) 350-90-02;</p>
        </div>
      </div>
    </div>
  </div>
</div>

