<app-banner
  [image]="'/assets/images/banner/banner-trucking-up-to-five-tons.svg'"
  [title]="'Грузоперевозки <br>5 тонн'"
  [description]="'По Москве и Московской области'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page [description]="description"></app-block-description-page>

  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 mb-20-xs mb-0-md text-center-lg">
        У нас самые выгодные тарифы на перевозки от 5 тонн по Москве и МО на рынке логистических услуг. Грузовой транспорт 5 тонн вмещает 14 паллет (габариты 6 х 2.4 х 2.3м). Стоимость за 1 час работы составляет 1150 руб, при этом сюда входит подача авто под и 70 км по Москве.
      </div>
    </div>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-12">
        <div class="row justify-content-between">
          <div class="col-12 col-md-2 mb-20-xs mb-0-md text-center-lg">
            <br>
            выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-2 mb-20-xs mb-0-md text-center-lg">
            <br>
            выезд за МКАД <br> (31 р/км)
          </div>
          <div class="col-12 col-md-2 mb-20-xs mb-0-md text-center-lg">
            <br>
            пропуск ТТК / садовое кольцо <br> (850/1020 р соответственно)
          </div>
          <div class="col-12 col-md-2 text-center-lg">
            <br>
            помощь при погрузке и выгрузке
          </div>
          <div class="col-12 col-md-2 text-center-lg">
            <br>
            экспедирование груза
          </div>
          <div class="col-12 col-md-2 text-center-lg">
            <br>
            использование гидроборта
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-30-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        При наличии ежедневных отгрузок или постоянных маршрутов мы подготовим для вас индивидуальный прайс со скидкой.
        <br>
        <br>
        Подробную информацию о грузоперевозке можно получить по номеру телефона.
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description2">
  </app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Машина на 5 тонн может быть предоставлена
      с любым типом  кузова даже срочно день в день</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        Автомобиль на 5 тонн для срочных грузоперевозок может быть предоставлен с любым кузовом и количеством паллет даже срочно день в день в любую точку города. Индивидуально уточняется, понадобятся ли грузчики для выгрузки из машины. У грузовиков, которые мы предлагаем, есть все необходимые пропуска для перевозки и возможность передвигаться в Москве как в дневное, так и в ночное время суток ежедневно. Поэтому, обратившись в наш сервис за услугой грузоперевозки, каждый заказчик позабудет о многих головных болях, которые могли бы возникнуть в сотрудничестве с  непроверенной компанией. В перечень постоянных работ по тарифам помимо упаковки, загрузки машины и разгрузки, входит оптимизация маршрута при перевозке, чтобы добиться максимально коротких сроков выполнения (время подачи - 1 час). Мы подстраиваем направление грузоперевозок, избегая пробок, ведь каждый партнер хочет, чтобы его заказ прибыл как можно скорее и без повреждений
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-xs text-grey4 mb-48-xs">Преимущества совместной деятельности с нами</h2>
    <div class="row text-grey9 mb-40-xs">
      <div class="col-12 col-lg mb-20-xs mb-0-lg">
        <p class="text-16-xs">Наша компания в процессе своей деятельности успела зарекомендовать себя как надежный и честный партнер. С нами вы получаете:</p>
      </div>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Индивидуальный подход к расчету, минимальный тариф, акции и скидки постоянным клиентам</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img class="margin-auto-lg" loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-1.svg" alt="Индивидуальный подход">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">Персонального менеджера, который всегда ответит на все ваши вопросы о грузоперевозках, построит оптимальный путь и возьмет на себя оформление всех сопроводительных документов</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-2.svg" class="margin-auto-lg" alt="Менеджер">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs">Собственный личный кабинет, в котором вы легко можете создавать новые заявки на перевозку и отслеживать текущие, хранить и скачивать документы. Также в нем есть калькулятор, которым можно воспользоваться для расчета перевозки (руб/км).</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-3.svg" class="margin-auto-lg" alt="Личный кабинет">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs"></h3>
            <p class="text-16-xs text-grey9">В большом автопарке разнообразие транспортных средств для аренды с грузоподъемностью 500 кг, 1-3 тонны, 10-20 тонн. Вы можете заказать тент - фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши менеджеры всегда помогут с выбором.</p>
          </div>
          <div class="col-12 col-lg-auto d-flex py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/shipping-on-yandex-market/shipping-on-yandex-market-4.svg" class="margin-auto-lg"  alt="Автопарк">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Штат квалифицированных сотрудников</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        <ul>
          <li>Все рабочие процессы осуществляются опытными специалистами с большим стажем</li>
          <li>Наши водители используют новые машины из нашего большого автопарка и ежедневно доставляют до 800 тонн груза</li>
          <li>Деятельность сотрудников в процессе перевозки контролирует служба отдела качества</li>
          <li>Слаженность команды «Акро» гарантирует своевременное прибытие машины, качественную доставку и безопасность транспортировки</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">С «AKRO» ваша логистика станет эффективнее</h2>

    <app-feedback
      [title]="'Достаточно расписать тип и объем груза '"
      [description]="'Вам подберут несколько оптимальных вариантов подходящих по длине, ширине \n'+
'и высоте кузова'"
    >
    </app-feedback>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как узнать стоимость перевозки?</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg pb-30-xs pb-0-lg">
        <div class="border-blue b-r-16-xs p-30-xs">
          <span class="text-24-xs">Заранее узнать, сколько будет стоить услуга «Грузоперевозка от 5 тонн» вы можете  при помощи консультанта, который сделает предварительный расчет перевозки.</span>

          <p class="mt-16-xs text-16-xs">Мы гарантируем, что перевозка, грузоподъемность которой 5 тонн с нами будет выгоднее, чем у других ТК, а также быстро и надежно.</p>

          <a href=""
             role="button"
             class=" mt-24-xs btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
            Запросить обратный звонок
          </a>
        </div>
      </div>
      <div class="col">
        <img loading="lazy" src="assets/images/trucking-up-to-five-tons/trucking-up-to-five-tons-9.svg" alt="Склад">
      </div>
    </div>
  </div>

  <app-block-description-page
    [title]="'Нужен надежный автомобиль и грузоперевозки 5 тонн в Москве и Московской области?'"
    [description]="'Теперь вы знаете, где можно найти их по самой доступной цене'"
    [image]="'assets/images/trucking-up-to-three-tons/trucking-up-to-three-tons.svg'"
    [leaveRequest]="true"
  >
  </app-block-description-page>

  <app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Ответы на часто задаваемые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
