import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-logo-svg',
  standalone: true,
  templateUrl: './logo.component.svg',
})
export class LogoComponent implements AfterViewInit{
  @ViewChild('data') data!: ElementRef;
  constructor(private elRef : ElementRef) {}

  ngAfterViewInit(): void {
    if (this.elRef.nativeElement.classList.value) {
      (this.data.nativeElement as SVGElement)!.setAttribute('class', this.elRef.nativeElement.classList.value);
    }
  }
}
