import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-outsourcing-transportation-services',
  templateUrl: './outsourcing-transportation-services.component.html',
  styleUrls: ['./outsourcing-transportation-services.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class OutsourcingTransportationServicesPage {
  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/gruzoperevozki-dlya-yur-lic',
      linkTitle: 'Грузоперевозки для юридических лиц'
    }, {
      link: '',
      linkTitle: 'Аутсорсинг транспортных услуг'
        }]
  }

  description = 'ТК «АКРО» оказывает услуги аутсорсинга транспортных средств, чтобы доставлять грузы по Москве, а также другим регионам России. Наша компания предлагает вам делегировать все бизнес-процессы, связанные с логистикой. У нас большой опыт организации грузоперевозок, квалифицированная команда водителей, экспедиторов, логистов и отлаженная инфраструктура. У нас большой автопарк с новыми машинами разной грузоподъемности. Мы можем возить грузы от 500 кг до 20 тонн, есть специальный транспорт для товаров, требующих соблюдения особого температурного режима. Если вам нужно доставлять изделия на склады маркетплейсов, то можете быть уверены: все машины полностью отвечают требованиям Озон, Яндекс.Маркет и Wildberries, поэтому у нас не бывает технических проблем при разгрузке.'

  blockWithItems = {
    title: 'Виды автотранспортного аутсорсинга',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: 'assets/images/outsourcing-transportation-services/outsourcing-transportation-services-1.svg',
      alt: 'Оперативный',
      title: 'Оперативный',
      description: 'Наша компания предоставляет клиенту транспорт с водителями в аренду. Клиент занимается обслуживанием и оплачивает текущие расходы, при этом он может самостоятельно распоряжаться машиной и строить график доставок лично. ',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/outsourcing-transportation-services/outsourcing-transportation-services-1.svg',
      alt: 'Проектный',
      title: 'Проектный',
      description: 'Клиент частично делегирует нам доставку своего продукта, например на некоторое время, чтобы сделать определенный объем работы или выполнить грузоперевозки по проекту. Содержанием, а также обслуживанием транспортного парка занимается наша компания.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/outsourcing-transportation-services/outsourcing-transportation-services-1.svg',
      alt: 'Полный',
      title: 'Полный',
      description: 'Наша компания заключает с вами договор на комплексное обслуживание под ключ, куда входят постоянная регулярная доставка, обслуживание автопарка, регулярная отчетность. Всеми этими вопросами занимаются логисты ТК «АКРО».',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems2 = {
    title: 'Каким компаниям нужен аутсорсинг транспортных услуг',
    description: '',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: '',
      title: '',
      description: 'Тем, у кого нет своего автопарка или он им не нужен',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Тем, кто не хочет брать дополнительную нагрузку по организации логистики, найму дополнительного персонала',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Тем, у кого небольшие объемы товаров, но нужна регулярная доставка',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Тем, у кого нет свободных средств, чтобы создать отдельное подразделение логистики в штате',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  faq = [{
    title: "Какие грузы перевозим?",
    description: "Наша компания занимается перевозками между офисами, налаживает транспортное сообщения со складами, доставляет сырье на производство, а также товары клиентам. «АКРО» перевозит мебель, технику, стройматериалы, оборудование, промтовары, продукты питания, лекарства и т.д. Мы не занимаемся доставкой опасных, запрещенных, крупногабаритных предметов. Перевозка хрупких отправлений обсуждается отдельно."
  }, {
    title: "Какая цена на аутсорсинг водителя с автомобилем?",
    description: "Стоимость зависит от типа аутсорсинга, характеристик груза, величины грузопотока, частоты поставок и других параметров."
  }, {
    title: "Что вы получите, работая с АКРО?",
    description: "• оперативную перевозку юридическим лицам; <br>• гибкое ценообразование;<br>• персонального менеджера;<br>• полный пакет закрывающей документации;<br>• быструю подачу автомобиля.<br>У нас работают вежливые внимательные сотрудники. Наши менеджеры по корпоративным клиентам подробно расскажут про грузоперевозки для юридических лиц.<br>Чтобы заказать доставку, позвоните нам или оставьте заявку на сайте. Опытная команда наших менеджеров обеспечит оперативную доставку в самые сжатые сроки и организует вам качественную перевозку.<br>Оставьте заявку сегодня до 17:00, и уже завтра ваш груз отправится получателю. Также по желанию клиента мы предоставляем транспорт день в день."
  }, {
    title: "Как заказать у нас аутсорсинг транспортных услуг?",
    description: "Позвоните нам по телефону 8 (499) 521-27-66 или напишите на электронную почту info@tk-akro.ru . С нами можно заключить договор на сезонные перевозки или на оказание постоянных транспортных услуг. «АКРО» гарантирует аккуратную и своевременную доставку, а также предлагает помощь опытных грузчиков. Позвоните 8 (499) 521-27-66 или напишите на электронную почту info@tk-akro.ru, чтобы обсудить подробности."
  }]

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'AKRO - аутсорсинг транспортных услуг. Услуги аутсорсинга транспортных средств Москва, Россия' });
    this.meta.addTag({ property: 'og:description', content: 'Аутсорсинг грузоперевозок, компания АКРО.✅ Цена аутсорсинг водителя с автомобилем.✅ Аутсорсинг автотранспортных услуг.✅ Аутсорсинг транспортно-логистических услуг' });
    this.meta.addTag({ name: 'description', content: 'Аутсорсинг грузоперевозок, компания АКРО.✅ Цена аутсорсинг водителя с автомобилем.✅ Аутсорсинг автотранспортных услуг.✅ Аутсорсинг транспортно-логистических услуг' });
    this.titleService.setTitle('AKRO - аутсорсинг транспортных услуг. Услуги аутсорсинга транспортных средств Москва, Россия');
  }
}
