<app-banner
  [image]="'/assets/images/banner/banner-refrigerated-truck-transportation.svg'"
  [title]="'Рефрижераторные перевозки'"
  [description]="'Одно из важных направлений нашей работы - рефрижераторная перевозка. Перевозки рефрижератором представляют собой доставку товаров с ограниченным сроком годности, требующих определенного температурного режима и влажности. Для поддержания регламентируемых норм перевозки транспортное средство должно быть оснащено дополнительным холодильным оборудованием (изотермическим фургоном), отвечающим определенным требованиям.'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg mt-60-xs mb-60-xs border-grey3">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs">
    </app-tariff>


    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs"  alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-30-xs">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        Выгодные тарифы, широкий спектр услуг и индивидуальный подход к каждому клиенту позволяет не только проработать соответствующий маршрут для каждого направления, но и сэкономить ваши денежные и временные ресурсы.
        <br><br>
        При наличии ежедневных отгрузок или постоянных маршрутов для вас будет подготовлен персональный прайс-лист со скидкой! Подробная информация - у наших менеджеров.

      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
         Заказать обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        Большой автопарк нашей компании включает в себя различные автомобили рефрижераторов грузоподъемностью от 1 до 20 тонн.  Если вам необходима аренда рефрижератора, чтобы недорого доставить до пункта назначения свежие продукты или полуфабрикаты, вакцины или медикаменты, молоко, свежие цветы или другие вещи, для которых требуются особые нормы рефрижераторной перевозки, обращайтесь в нашу компанию.
        <br><br>
        В самое короткое время мы подберем для вашего груза наиболее оптимальный автомобиль рефрижератор. При этом ваши риски минимизированы: все грузы проходят страхование, а квалификация наших сотрудников - перевозчиков позволяет обеспечить выполнение вашего заказа вовремя. Заказывайте автомобильную грузоперевозку рефрижератором в компании «АКРО» по самым выгодным тарифам и с гарантией качества.
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Перевозка продуктов <br> питания рефрижератором</h2>
    <div class="row text-16-xs mb-48-xs">
      <div class="col-12 col-lg">
        Осуществлять перевозку продуктов питания с установленным температурным имеют возможность организации, обладающие необходимой техникой и квалифицированными кадрами. На каждый вид продукта установлен диапазон допустимых температур, несоблюдение которого приведёт к выбраковке и убыткам.
      </div>
    </div>

    <app-faq-item [item]="item" *ngFor="let item of products" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Последствия несоблюдения <br> температурного режима</h2>
    <div class="row text-16-xs mb-48-xs">
      <div class="col-auto">
        Доставка грузов с соблюдением требуемого климатического режима внутри контейнера позволяет в результате избежать убытков поставщикам, владельцам торговых точек и конечному потребителю. Если проигнорировать или недостаточно ответственно отнестись к контролю климата, возможны неприятные последствия:
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg pr-50-lg">
        <ul class="text-18-xs pl-20-xs">
          <li class="mb-24-xs">потеря целебных свойств лекарствами и изменение их химического состава (что может привести к отравлению людей);</li>
          <li class="mb-24-xs">порча плодов (их придется частично выбросить, частично – уценить);</li>
          <li class="mb-24-xs">протухание или скисание продуктов (в этом случае они идут исключительно на выброс);</li>
          <li class="mb-24-xs">потеря косметикой товарного вида;</li>
          <li>гибель саженцев.</li>
        </ul>
      </div>
      <div class="col-12 col-lg text-16-xs">
          <p>Даже если транспортная компания возместит ущерб, подобные происшествия могут подорвать доверие покупателей к определенным фирмам, и репутация будет испорчена</p>
          <p class="mt-30-xs">Мы рекомендуем заказать реф перевозку, если в вашем ассортименте есть наименования, для которых нужны специальные температурные требования перевозки на грузовой машине и хранения.</p>
          <a href=""
             role="button"
             class="row btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
            Заказать перевозку
          </a>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как рассчитать стоимость перевозки рефрижератором <br> по Москве, Московской области или в других регионах?</h2>
    <div class="row text-16-xs mb-48-xs">
      <div class="col-auto">
        Цена за услуги зависит от выбранной вами техники, особенностей груза, а также расстояния от точки отправления до пункта назначения в км. Для того, чтобы узнать точный прайс на перевозки, вы можете
      </div>
    </div>
    <div class="row">
      <div class="col pr-50-xs">
        <div class="row mb-30-xs">
          <div class="col-auto text-48-xs text-blue">01</div>
          <div class="col pl-20-xs text-20-xs">оставить онлайн обращение на сайте, <br> заполнив форму обратной связи</div>
        </div>
        <div class="row mb-30-xs">
          <div class="col-auto text-48-xs text-blue">02</div>
          <div class="col pl-20-xs text-20-xs">оформить через кабинет с комментарием <br>  “запрос стоимости”</div>
        </div>
        <div class="row">
          <div class="col-auto text-48-xs text-blue">03</div>
          <div class="col pl-20-xs text-20-xs">Позвонить нам по номеру телефона <br> 8 (499) 350-90-02</div>
        </div>
      </div>
      <div class="col-12 col-lg mt-20-xs mt-0-lg pt-30-xs">
        <div class="border-blue b-r-16-xs p-30-xs">
          <p class="text-16-xs">После поступления обращения наш сотрудник свяжется с вами в течение двух минут. Он  подберет для вас наиболее подходящий автомобиль, расскажет про тарифы логистики и озвучит стоимость  с учетом всех нюансов. Также предоставьте нашему специалисту всю информацию о грузе, который необходимо перевезти (в том числе его вес в кг, габариты и условия перевозки), адрес забора груза и место назначения.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Доставка продуктов питания рефрижератором</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        Поставка продуктов питания с установленным температурным режимом осуществляется фирмами, обладающими необходимыми моделями рефрижераторов и квалифицированными кадрами. На каждый тип продукта установлен подходящий диапазон допустимых температур, несоблюдение которого приведёт к выбраковке и убыткам.
        <br><br>
        С ТК «Акро» вы можете быть уверены, что грузоперевозки рефрижераторами для физических и юридических лиц будут осуществлены быстро и аккуратно. Нас выбирают владельцы малого и среднего бизнеса из Москвы, Санкт-Петербурга и других городов России. Мы гарантируем отличный сервис, быстрые грузоперевозки и качество нашей работы, о чем свидетельствуют отзывы наших довольных клиентов! Вы можете быть уверены, что в штате работают самые квалифицированные сотрудники, а наш гараж укомплектован техникой, которая удовлетворяет любые требования.
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg">
    <app-tags [title]="tags['title']" [items]="tags['items']"></app-tags>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
