import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Контакты'
    }]
  }

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Контакты ООО «ТК АКРО». Центральный офис' });
    this.meta.addTag({ property: 'og:description', content: 'Контакты и телефоны транспортной компании АКРО.✅ Доставка по Москве, МО, СПБ, России' });
    this.meta.addTag({ name: 'description', content: 'Контакты и телефоны транспортной компании АКРО.✅ Доставка по Москве, МО, СПБ, России' });
    this.titleService.setTitle('Контакты ООО «ТК АКРО». Центральный офис');
  }
}
