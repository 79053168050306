import {Component, Input} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent {
  @Input() title: string | undefined
  @Input() description: string | undefined

  form = this.fb.group({
    'count-box-pallet': ['', Validators.required],
    'date-delivery-on-stock': ['', Validators.required],
    'total-cargo-weight': ['', Validators.required],
    'address': ['', Validators.required],
    'date-time-take-weight': ['', Validators.required],
    'address-delivery-stock': ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    public readonly popupService: PopupService) { }
}
