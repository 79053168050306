<div class="overlay" [class.show]="popupService.show[popupService.PopupEnum.DRIVER_JOB]" (click)="popupService.closePopups(); setDefaults()"></div>
<div class="position-fixed popup p-30-xs b-r-16-xs bg-purple border-grey3" [class.show]="popupService.show[popupService.PopupEnum.DRIVER_JOB]">
  <h3 class="mb-25 text-24-xs text-grey4 p-0-xs mb-16-xs">Свяжемся с Вами</h3>
  <p class="mb-25 text-16-xs text-grey4 p-0-xs mb-24-xs">Заполните данные, что бы наш менеджер перезвонил вам и ответил на все имеющиеся вопросы.</p>

  <form [formGroup]="jobForm">
    <input formControlName="name" type="text"
           placeholder="Ваше имя"
           class="text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
    <div [class.mtm10]="jobForm.get('name')?.invalid" *ngIf="jobForm.get('name')?.invalid && jobForm.get('name')?.dirty || jobForm.get('name')?.touched">
      <small *ngIf="jobForm.get('name')?.errors?.['required']" class="text-danger">Не указано имя</small>
    </div>
    <input formControlName="phone" type="text"
           placeholder="Номер телефона" prefix="+7" mask="(000) 000 00 00"
           class="text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
    <div [class.mtm10]="jobForm.get('phone')?.invalid" *ngIf="jobForm.get('phone')?.invalid && jobForm.get('phone')?.dirty || jobForm.get('phone')?.touched">
      <small *ngIf="jobForm.get('phone')?.errors?.['required']" class="text-danger">Не указан номер телефона</small>
      <small *ngIf="jobForm.get('phone')?.errors?.['wrongNumber']" class="text-danger">Номер телефона указан неверно</small>
    </div>
    <select formControlName="category" class="form-select text-16-xs mb-8-xs pt-10-xs pb-10-xs pl-24-xs pr-24-xs b-r-12-xs w-percent-100-xs">
      <option selected [value]="null">---</option>
      <option [value]="CategoryTypeEnum.CAT1">Категория 1 (Каблук)</option>
      <option [value]="CategoryTypeEnum.CAT2UPTO1T">Категория 2 до тонны (Газель 2.8 м.)</option>
      <option [value]="CategoryTypeEnum.CAT2PLUS">Категория 2+ (Газель 4м.)</option>
      <option [value]="CategoryTypeEnum.CAT3">Категория 3 (Бычок)</option>
      <option [value]="CategoryTypeEnum.CAT5">Категория 5 (ЗИЛ)</option>
      <option [value]="CategoryTypeEnum.CAT5PLUS">Категория 5+ (КАМАЗ)</option>
      <option [value]="CategoryTypeEnum.CAT6">Категория 6 (КАМАЗ)</option>
      <option [value]="CategoryTypeEnum.CAT7">Категория 7 (Фура)</option>
    </select>
    <div class="row checkbox text-gray text-10-xs">
      <div class="col-auto pr-8-xs">
        <input formControlName="hasOwnCar" id="own-car" type="checkbox" [checked]="false">
        <label for="own-car" class="checkmark"></label>
      </div>

      <span style="margin-top: 2px" [class.text-danger]="jobForm.get('hasOwnCar')?.invalid && jobForm.get('hasOwnCar')?.touched" (click)="jobForm.get('hasOwnCar')!.setValue(!jobForm.get('hasOwnCar')!.value)" class="col pl-0-xs unselectable">
        Есть собственный транспорт
      </span>
    </div>

    <div class="row checkbox text-gray text-10-xs mb-24-xs">
      <div class="col-auto pr-8-xs">
        <input formControlName="privacy" id="privacy-call" type="checkbox" [checked]="false">
        <label for="privacy-call" class="checkmark"></label>
      </div>

      <span [class.text-danger]="jobForm.get('privacy')?.invalid && jobForm.get('privacy')?.touched" (click)="jobForm.get('privacy')!.setValue(!jobForm.get('privacy')!.value)" class="col pl-0-xs unselectable">
        Я подтверждаю ознакомление с политикой обработки персональных данных, в том числе с применением технологии SmartCaptcha
        и даю согласие на обработку моих данных в порядке и на условиях, указанных в Политике.
      </span>
    </div>
    <div #submitBtn>
      <button [class.bg-success]="isSended" [class.bg-blue]="isLoading || !isSended" type="button" [disabled]="!jobForm.valid || isLoading || isSended" (click)="onSubmit()" class="text-white b-r-8-xs text-16-xs p-10-xs unselectable">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{isLoading ? 'Отправка' : ''}}
        {{isSended ? 'Отправлено' : ''}}
        {{!isLoading && !isSended ? 'Отправить' : ''}}
      </button>
    </div>
    </form>
</div>

