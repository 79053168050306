<app-banner
  [image]="'/assets/images/banner/banner-trucking-non-cash-payment.svg'"
  [title]="'Грузоперевозки <br> безналичный расчет'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<div class="container">
  <div class="text-lg-start pb-50-xs pb-60-lg pr-30-xs pr-0-lg pl-30-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Требования к грузу</h2>
    <p>Для доставки на склады маркетплейсов мы принимаем:</p>

    <div class="row text-grey3">
      <div class="col-12 col-lg bg-purple border-grey3 b-r-16-xs mb-20-xs mb-0-lg mr-20-lg d-flex flex-column">
        <div class="row flex-fill align-items-end position-relative text-gray3 text-decoration-none">
          <div class="p-20-xs">
            <p class="position-absolute text-16-lg pt-20-xs top-0">Отправления от 5 кг до 20 тонн</p>
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-9.svg" alt="5кг-20т.">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-10-lg flex-fill align-items-end position-relative text-gray3 text-decoration-none">
          <div class="pt-20-xs pr-20-xs pl-20-xs">
            <p class="position-absolute text-16-lg pt-20-xs top-0">Максимальный вес одной коробки — 20 кг</p>
            <div class="text-end mt-40-xs">
              <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-10.svg" class="float-end" alt="20кг">
            </div>
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-0-lg mt-10-lg flex-fill align-items-end position-relative text-gray3 text-decoration-none">
          <div class="pt-20-xs pr-20-xs pl-20-xs">
            <p class="position-absolute text-16-lg pt-20-xs top-0">Высота паллеты не должна превышать 180 см</p>
            <div class="text-end mt-20-xs">
              <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-11.svg" alt="180см" class="pt-50-lg float-end">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-10-lg flex-fill align-items-end position-relative text-gray3 text-decoration-none">
          <div class="pt-20-xs pl-20-xs pr-0-xs pb-20-xs">
            <p class="position-absolute text-16-lg pt-20-xs pr-80-xs top-0">При объеме больше 1 куб м. товар нужно поместить на паллет</p>
            <div class="text-end mt-20-xs">
              <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-12.svg" alt="1куб">
            </div>
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-0-lg mt-10-lg flex-fill align-items-end position-relative text-gray3 text-decoration-none">
          <div class="pt-20-xs pr-20-xs pl-20-xs">
            <p class="position-absolute text-16-lg pt-20-xs top-0">На упаковочной таре не должно быть повреждений</p>
            <div class="text-end mt-20-xs">
              <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-13.svg" alt="без повреждений">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row border-blue b-r-16-xs flex-fill align-items-start position-relative text-gray3 text-decoration-none">
          <div class="p-30-xs">
            <p class="text-16-lg">Самые конкретные и строго регламентированные требования есть
              у маркетплейсов,
              т.е. от их выполнения зависит сама возможность и скорость разгрузки вашего товара.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Напишите нам, если у вас есть вопросы по доставке</h2>

    <app-feedback
      [title]="'Заполните форму обратной связи и получите:'"
      [description]="'' +
       '— Расчет стоимости перевозки сборных грузов <br>'+
       '— Индивидуальное коммерческое предложение <br>'+
       '— Доставку товаров в маркетплейс'"
    >
    </app-feedback>
  </div>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как оформить доставку:</h2>
    <div class="row">
      <div class="col-12 col-lg">
        <p class="text-16-xs">Сотрудничество с компанией «АКРО» — это несколько простых шагов. Перевозка грузов с помощью транспортной компании «АКРО» из любого региона или города России до склада маркетплейса быстрая, безопасная и с соблюдением всех правил площадки. </p>
      </div>
      <div class="col-12 col-lg">
        <p class="text-16-xs">Мы гарантируем полное сопровождение сделки на всех этапах. При необходимости ценный и дорогостоящий груз будет бесплатно застрахован.</p>
      </div>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Принимаем заявку через сайт или по телефону</h3>
            <p class="text-16-xs">Можно заказать обратный звонок или использовать удобный личный кабинет — не забудьте указать ваш номер телефона и электронную почту, а также адрес вашего склада или производства</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-1.svg" alt="Звонок">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Составляем договор на транспортное сопровождение.</h3>
            <p class="text-16-xs">В течение двух минут, уточнит стоимость и условия сотрудничества. Озвучит цену и заключит с вами договор. Ему нужно предоставить информацию о маршруте и грузе (вес в кг и количество коробок, габариты и т.д.)</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-2.svg" alt="Договор">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Забираем посылку со склада в день доставки или за день до неё</h3>
            <p class="text-16-xs">Мы можем сделать это заблаговременно или непосредственно в день оформления заявки. При необходимости мы сами упакуем коробки на паллетах и поможем погрузить упакованную продукцию в автомобиль</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-3.svg" alt="Забираем">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Заключительный этап — доставка и оплата по безналичному расчету</h3>
            <p class="text-16-xs">Заключительный этап — доставка и оплата по безналичному расчету</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-4.svg" alt="Оплата">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-block-with-items
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>
</div>
