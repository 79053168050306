import {Component} from '@angular/core';

@Component({
  selector: 'app-page-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.less']
})
export class ActionsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Акции'
    }]
  }

  items = [{
    title: 'Скидка для новых клиентов',
    description: 'На первую перевозку',
    percent: '10%',
    image: 'assets/images/sale-action/sale-action-2.svg',
    link: ''
  },{
    title: 'Скидка при заказе авто во вторую половину дня',
    description: 'При заказе авто после 14:00',
    percent: '15%',
    image: 'assets/images/sale-action/sale-action-1.svg',
    link: ''
  }]
}
