<app-banner
  [image]="'/assets/images/banner/banner-shipping-on-wildberries.svg'"
  [title]="'Доставка на <br>Wildberries'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [typeActive]="typeActive"
      [typeTitle]="typeTitle"
      [tariffs]="tariffs"
      [header]="header"
    >
    </app-tariff>
  </div>

  <app-block-description-page [description]="description1"></app-block-description-page>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-lg mb-40-xs text-grey4">Процесс доставки грузов на склады WILDBERRIES <br> состоит из следующих этапов:</h2>
    <div class="row">
      <div class="col-12 col-lg-8 pr-50-lg text-grey9">
        <div class="row">
          <div class="col-12 col-lg-6 mb-30-xs pr-30-lg">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">01</div>
              <div class="col pl-20-xs text-18-xs">оформление заявки клиентом (можно заказать обратный звонок или добавить заявку в личный кабинет);</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-30-xs pl-30-lg">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">02</div>
              <div class="col pl-20-xs text-18-xs">согласование всех нюансов доставки до склада с личным менеджером (дата, время и дополнительные услуги);</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-30-xs pr-30-lg">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">03</div>
              <div class="col pl-20-xs text-18-xs">менеджер компании озвучивает цены на доставку и заключает с вами договор;</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-30-xs pl-30-lg">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">04</div>
              <div class="col pl-20-xs text-18-xs">подготовка груза к отправке на склад WILDBERRIES;</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-30-xs pr-30-lg">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">05</div>
              <div class="col pl-20-xs text-18-xs">забор товаров со склада отправителя;</div>
            </div>
          </div>
          <div class="col-12 col-lg-6 pl-30-lg">
            <div class="row">
              <div class="col-auto text-48-xs text-blue">06</div>
              <div class="col pl-20-xs text-18-xs">непосредственно доставка груза до пункта назначения.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="border-blue b-r-16-xs mt-20-xs mt-0-lg p-30-xs text-grey4">
          <h3 class="text-24-xs">ВАЖНО: </h3>
          <p class="text-16-xs">мы не принимаем к доставке товары с классом опасности, а перевозки хрупкого груза до склада маркетплейса обговариваются отдельно. Для транспортировки ценных и дорогостоящих товаров мы можем заключить отдельный страховой договор.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-lg mb-40-xs text-grey4">Как правильно подготовить товары к отгрузке</h2>
    <div class="row mb-60-xs text-grey9">
      <div class="col-12 col-lg mb-20-xs mb-0-lg">При подготовке груза к перевозке на склад маркетплейса вы должны самостоятельно запечатать его в соответствии с правилами площадки и правильно разместить. Также требуется чтобы груз был промаркирован. Проверьте, что дата доставки груза совпадает с датой, которая указана в личном кабинете поставщика WILDBERRIES, поскольку в противном случае в приемке груза в распределительном пункте может быть отказано.</div>
    </div>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
    <p class="col-12 col-lg mt-60-xs mb-0-lg text-18-xs">Товар, объем которого превышает 1 м³, поставляется на склад маркетплейса на поддоне размером 800х1200х150 мм (евро), нужно чтобы груз занимал на нём все место и не выступал за края поддона более чем на 1,5 см с каждой стороны. Также существуют группы товаров, перевозка которых разрешена только монопаллетами. При формировании доставки система Вайлдберриз сама определяет, под какой тип возможна отгрузка. Груз на паллете крепится стретч - пленкой, а поддон с товаром сопровождается специальным упаковочным листом с информацией. </p>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row border-bottom pb-40-xs">
      <div class="col-12 col-lg-8 pr-50-lg">
        <div class="row">
          <h2 class="text-48-lg mb-32-xs text-grey4">Оформление документации на транспорт</h2>
        </div>
        <div class="row m-0-xs text-grey9">
          <div class="col-12 col-lg mb-30-xs mr-50-lg">
            <div class="row">
              <div class="row mb-24-xs text-48-xs text-blue">01</div>
              <div class="row mb-16-xs text-24-xs">Для сотрудника, который будет осуществлять перевозку в маркетплейсы, следует оформить пропуск в личном кабинете.</div>
              <div class="row text-16-xs">данные о нем и машине будут заблаговременно предоставлены вам на почту после распределения водителя у нас в системе</div>
            </div>
          </div>
          <div class="col-12 col-lg mb-30-xs">
            <div class="row mb-24-xs text-48-xs text-blue">02</div>
            <div class="row mb-16-xs text-24-xs"><p class="p-0-xs">Далее создайте электронную транспортную накладную.</p></div>
            <div class="row text-16-xs">важно правильно внести в нее количество грузомест и паллетомест.</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg text-grey4">
        <div class="border-blue b-r-16-xs p-30-xs">
          <h3 class="text-24-xs">При оформлении укажите данную информацию:</h3>
          <p class="text-16-xs">
            —имя и фамилия водителя <br>
            —марка автомобиля <br>
            —гос.номер автомобиля в формате А111АА111 <br>
            —тип упаковки <br>
            —количество коробов в данном ТС
          </p>
          <p class="text-16-xs">Транспортному средству будет присвоен уникальный штрихкод доставки, который нужно будет сохранить, распечатать и передать нашему  водителю либо нанести на паллету, стоящую ближе к выгрузке.</p>
          
        </div>
      </div>
    </div>
    <div class="row mt-40-xs text-grey4">
      <h2 class="text-48-lg text-grey4">Разрешение обязательно только на определенных складах маркетплейса:</h2>
      <br><br>
      <div class="row pt-30-xs pb-30-xs">
      <div class="col-12 col-sm-4">
        <ul>
          <li>Подольск</li>
          <li>Коледино (Подольск 2)</li>
        </ul>
      </div>
      <div class="col-12 col-sm-4">
        <ul>
          <li>Санкт-Петербург</li>
          <li>Электросталь</li>
        </ul>
      </div>
      <div class="col-12 col-sm-4">
        <ul>
          <li>Казань</li>
          <li>Краснодар</li>
        </ul>
      </div>
    </div>

      <div class="col-12 mb-20-xs">При отсутствии документов или ошибок в них, прием поставок выполняться не будет, поэтому очень важно внимательно заполнять все необходимые данные.

        Обратите внимание, что при обычной доставке ШК ТТН клеится только на один паллет/короб. Если товар доставляется на склад маркетплейса в нескольких машинах,то в этом случае ШК ТТН необходимо сгенерировать и распечатать по количеству транспортных средств. Если же в одной машине отгружается несколько разных поставок (от одного или нескольких поставщиков), то ШК ТТН вы генерируете отдельно для каждой поставки, а  распечатанный штрихкод клеится на каждый монопаллет.</div>
      <div class="col-12 mb-20-xs">
        Непосредственно перед поставкой в назначенную дату мы еще раз проверяем корректность упаковки груза и его маркировки, после чего сдаем груз на склад WILDBERRIES и подтверждаем доставку, а все бухгалтерские документы направим  в систему электронного документооборота.
      </div>
      <div class="col-12 mb-20-xs">Для получения более подробной консультации по доставке товаров до распределительных центров WILDBERRIES и расчета стоимости, оставьте заявку на сайте, и наш специалист свяжется с вами в самое ближайшее время. Также ориентировочные сроки и стоимость доставки вы можете узнать по телефону.</div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-48-lg mb-32-xs text-grey4">С транспортной компанией «Акро» вы можете быть уверены, что доставка вашего товара до складов (fbo или fbs) маркетплейса Вайлдберриз будет осуществлена быстро и аккуратно.</h2>

    <app-feedback
      [title]="'<p class=p-0-xs>Заполните форму обратной связи и получите:</p>'"
      [description]="'— Расчет стоимости доставки на Wildberries <br> — Ориентировочные сроки доставки <br><br>'"
    >
    </app-feedback>
  </div>

  <app-block-with-items
    class="text-grey4"
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>
  <p class="pb-30-xs">Большое разнообразие транспортных средств для аренды: доставляем моно- и микс-паллеты, малогабаритный и крупногабаритный груз от 1 кг до 20 тонн. Вы можете выбрать, например, тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши менеджеры всегда помогут с выбором.</p>

  <app-block-description-page [description]="description"></app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq2" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg">
    <app-tags [title]="tags['title']" [items]="tags['items']"></app-tags>
  </div>
</div>
