import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.less']
})
export class ServiceComponent {

  @Input() services: {
    items: {
      class: string,
      title: string,
      image: string,
      imageClass: string,
      link: string,
      alt?: string
    }[]
  }[] | undefined

  mobileItems: {
    class: string,
    title: string,
    image: string,
    imageClass: string,
    link: string,
    alt?: string
  }[] = []

  slideConfig = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  getMobileItems() {
    if (!this.mobileItems.length) {
      this.services?.forEach((service, index) => {
        service['items'].forEach((item, index) => {
          this.mobileItems.push(item);
        })
      })
    }

    return this.mobileItems;
  }
}
