import {Component, Input} from '@angular/core';
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.less']
})
export class BannerComponent {
  @Input() image: string | undefined
  @Input() title: string | undefined
  @Input() description: string | undefined
  @Input() showButtonCall: boolean | undefined
  @Input() showButtonKp: boolean | undefined
  @Input() showButtonDriverJob: boolean | undefined


  constructor(
    public readonly popupService: PopupService
  ) {
  }
}
