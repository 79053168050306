<div class="pt-50-xs pt-60-lg pb-50-xs pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg text-grey4">
  <div class="row b-r-16-xs p-20-xs p-40-lg m-0-xs" [ngClass]="class ?? 'border-blue'">
    <div class="col-12 col-lg order-2 order-lg-1 mt-0-lg">
      <h3 class="text-20-xs mb-16-xs" *ngIf="title" [innerHTML]="title"></h3>
      <span class="text-16-xs" *ngIf="description" [innerHTML]="description"></span>
      <a href=""
         role="button"
         *ngIf="leaveRequest"
         class=" mt-24-xs btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-22-xs pl-22-xs">
        Оставить заявку
      </a>
    </div>
    <div class="col-12 col-lg-auto order-1 order-lg-2" *ngIf="image">
      <img src="{{image}}">
    </div>
  </div>
</div>
