import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-freight-insurance',
  templateUrl: './freight-insurance.component.html',
  styleUrls: ['./freight-insurance.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FreightInsurancePage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Страхование грузов'
    }]
  }

  blockWithItems = {
    title: 'Риски, которые могут<br> быть застрахованы',
    class: 'col-12 col-md-4 text-center',
    items: [{
      image: '/assets/images/freight-insurance/freight-insurance4.svg',
      title: '',
      description: 'Полная или частичная утрата груза',
      class: 'p-20-xs bg-purple border-grey3 d-flex flex-column align-items-center justify-content-around'
    },{
      image: '/assets/images/freight-insurance/freight-insurance5.svg',
      title: '',
      description: 'Различные повреждения груза',
      class: 'p-20-xs bg-purple border-grey3 d-flex flex-column align-items-center justify-content-around'
    },{
      image: '/assets/images/freight-insurance/freight-insurance6.svg',
      title: '',
      description: 'Нарушение сроков доставки, если это было оговорено в договоре страхования',
      class: 'p-20-xs bg-purple border-grey3 d-flex flex-column align-items-center justify-content-around'
    }]
  }
}
