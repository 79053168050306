<app-banner
  [image]="'/assets/images/banner/banner-outsourcing-transportation-services.svg'"
  [title]="'Аутсорсинг <br> транспортных услуг'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-30-xs pr-0-lg pl-30-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Что такое аутсорсинг автотранспортных услуг</h2>
    <div class="row">
      <div class="col-12 col-lg">
        <p class="text-16-xs">Это делегирование задач по логистике подрядчикам — транспортным компаниям. Вы доставляете товар получателю, используя при этом ресурсы другой компании. Это выгодная сделка для всех сторон.</p>
      </div>
      <div class="col-12 col-lg">
        <p class="text-16-xs">Свободные ресурсы вы можете использовать для развития вашего бизнеса:  повысить качество продукта, увеличить обороты и реализовать свой потенциал. Вы повысите прибыльность за счет уменьшения расходов, связанных с логистикой, а также сможете сосредоточиться на основной деятельности, улучшить действующие и развивать новые направления.</p>
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <div class="row text-lg-start pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-30-xs pr-0-lg pl-30-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как аутсорсинг грузоперевозок помогает экономить?</h2>
    <p>При заказе аутсорсинга транспортно-логистических услуг, всем этим занимаются специалисты АКРО. Также они договариваются о доставках, забирают, привозят товар, решают возникающие трудности.</p>

    <div class="row text-grey3">
      <div class="col-12 col-lg bg-purple border-grey3 b-r-16-xs mb-20-xs mr-20-lg d-flex flex-column">
        <div class="row flex-fill align-content-between text-gray3 p-20-xs">
          <div>
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-1.svg" alt="Обслуживание">
          </div>
          <p class="text-16-lg p-0-xs m-0-xs">На обслуживании автопарка и решении технических вопросов (регистрация машин в ГАИ, страхование, ТО, контроль использования и расхода ГСМ, ремонт, закупка машин, хранение и т.д.);</p>
        </div>
      </div>
      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">На подборе персонала (водители, экспедиторы, логисты, персональные менеджеры)</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-1.svg" alt="Подбор персонала">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mt-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">На обучении, повышении квалификации сотрудников</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-1.svg" alt="Обучение">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">На ведении дополнительной бухгалтерии (оформление заявок, договоров, счетов)</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-1.svg" alt="Бухгалтерия">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">На изучении правовой базы по грузоперевозкам, нововведений (акты, постановления)</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-1.svg" alt="Право">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
