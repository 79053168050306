import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-block-description-page',
  templateUrl: './block-description-page.component.html',
  styleUrls: ['./block-description-page.component.less']
})
export class BlockDescriptionPageComponent {
  @Input() image: string | undefined;
  @Input() title: string | undefined;
  @Input() class: string | undefined;
  @Input() description: string | undefined;
  @Input() leaveRequest: boolean = false;
}
