import {Component, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.less']
})
export class TariffComponent implements OnInit {
  @Input() carActive: number | undefined
  @Input() typeActive: number | undefined
  @Input() typeTitle: string | undefined
  @Input() tariffs: any = []
  @Input() header: any = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-88-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-88-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-88-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-88-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-88-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-88-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  @ViewChild("slickModal") slickModal: SlickCarouselComponent | undefined;

  nds: boolean = false
  isMobile: boolean = false
  isLoaded = false;
  items: [] | undefined

  slideConfig = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.getScreenSize()
  }

  ngOnInit(): void {
    this.getItems()
  }

  changeTypeActive(index: any) {
    this.typeActive = index['value'] ? parseInt(index['value']) : index
    this.getItems()

    if (this.isMobile && this.slickModal) {
      this.slickModal.slides[0].refresh
    }
  }

  changeCarActive(index: number) {
    this.carActive = index
    this.getItems()

    if (this.isMobile && this.slickModal) {
      this.slickModal.slides[0].refresh
    }
  }

  getTypeItems() {
    let items = []

    if (this.carActive != undefined) {
      items = this.tariffs[this.carActive]['items']
    } else {
      items = this.tariffs
    }

    return items
  }

  getHeaders() {
    return this.header;
  }

  getItems() {
    this.items = []

    if (this.typeActive === 0) {
      if (this.carActive != undefined) {
        this.tariffs[this.carActive]['items'].forEach((typeItem: { items: any[]; }) => {
          typeItem.items.forEach((item) => {
            // @ts-ignore
            this.items.push(item);
          })
        })
      } else {
        this.tariffs.forEach((typeItem: { items: any[]; }) => {
          typeItem.items.forEach((item) => {
            // @ts-ignore
            this.items.push(item);
          })
        })
      }
    } else {
      if (this.carActive != undefined) {
        // @ts-ignore
        this.items = this.tariffs[this.carActive]['items'][this.typeActive]['items']
      } else {
        // @ts-ignore
        this.items = this.tariffs[this.typeActive]['items']
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    if (this.isLoaded) {
      this.processSlick();
    }
  }

  ngAfterContentInit(): void {
    this.isLoaded = true;
    this.processSlick();
  }

  public processSlick(): void {
    if (this.isLoaded) {
      this.isMobile = Boolean(this.document && this.document.body?.offsetWidth < 1024);
      if (this.isMobile && this.slickModal && !this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
      if (!this.isMobile && this.slickModal && this.slickModal.initialized) {
        this.slickModal.unslick()
      }
    }
  }
}
