import {Component, Input} from '@angular/core';
import {PopupService} from "../../popup.service";

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.less']
})
export class TagsComponent {
  @Input() title: string | undefined

  @Input() items: {
    title: string,
    class: string,
    children: {
      title: string,
      link: string
    }[]
  }[] | undefined

  constructor(
    public readonly popupService: PopupService
  ) { }
}
