<app-banner
  [image]="'/assets/images/banner/banner-contacts.svg'"
  [title]="'Контакты'"
  [showButtonCall]="false"
  [showButtonKp]="false"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="row container pt-60-lg">
  <div class="col-12 col-lg">
    <div class="row">
      <h2 class="text-24-xs text-36-lg mb-32-xs mb-48-lg">ООО «ТК АКРО»</h2>

      <div class="col-12 mb-24-xs mb-36-lg">
        <span class="text-20-xs text-24-lg d-block mb-8-xs mb-16-lg">Адрес</span>
        <p class="text-16-xs text-18-lg">107553, город Москва, ул Черкизовская Б., д. 24а стр. 1</p>
      </div>
      <div class="col-12 mb-24-xs mb-36-lg">
        <span class="text-20-xs text-24-lg d-block mb-8-xs mb-16-lg">ИНН/КПП</span>
        <p class="text-16-xs text-18-lg">7719497345/771801001</p>
      </div>
      <div class="col-12 mb-24-xs mb-36-lg">
        <span class="text-20-xs text-24-lg d-block mb-8-xs mb-16-lg">ОГРН</span>
        <p class="text-16-xs text-18-lg">1197746587455</p>
      </div>
      <div class="col-12 mb-24-xs mb-36-lg">
        <span class="text-20-xs text-24-lg d-block mb-8-xs mb-16-lg">График работы</span>
        <p class="text-16-xs text-18-lg">В будние дни — с 9:00 до 19:00</p>
      </div>
      <div class="col-12 mb-24-xs mb-36-lg">
        <span class="text-20-xs text-24-lg d-block mb-8-xs mb-16-lg">Телефон</span>
        <p class="text-16-xs text-18-lg">8 (499) 350-90-02</p>
      </div>
      <div class="col-12 mb-24-xs mb-36-lg">
        <span class="text-20-xs text-24-lg d-block mb-8-xs mb-16-lg">E-mail</span>
        <p class="text-16-xs text-18-lg">info@tk-akro.ru</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-auto map">
    <img loading="lazy" src="assets/images/maps.png" width="700" class="b-r-12-xs">
  </div>
</div>
