<div class="container pt-50-xs pt-30-lg pb-50-xs pb-60-lg">
  <div class="row text-lg-start pr-30-xs pr-0-lg pl-30-xs pl-0-lg px-15-lg">
    <span class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Почему нас выбирают</span>
  </div>

  <ngx-slick-carousel class="carousel navigation-bottom pr-20-xs pr-0-lg pl-20-xs pl-0-lg"
                      #slickModal="slick-carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide px-10-xs">
      <div class="bg-purple b-r-16-xs p-20-xs p-40-lg pb-60-lg border-grey3 height-to-full">
        <div class="row pb-80-xs pb-0-lg m-0-xs">
          <div class="col-12 col-lg-6 p-0-xs pr-0-xs pr-80-md">
            <span class="text-grey4 text-24-xs text-36-lg mb-26-xs d-block" [innerHTML]="slide['title']"></span>
            <img [src]="slide['image']" alt="{{slide['alt']}}" loading="lazy"/>
          </div>
          <div class="col-12 col-lg-6 p-0-xs pt-30-xs pt-0-mg">
            <div class="text-grey4 text-16-lg point-blue" [innerHTML]="slide['description']"></div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
