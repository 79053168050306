import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-spb-msk',
  templateUrl: './spb-msk.component.html',
  styleUrls: ['./spb-msk.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class SpbMskPage {
  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки из Санкт-Петербурга в Москву'
    }]
  }

  descriptionFirst = 'Транспортная компания «Акро» осуществляет грузоперевозки из Санкт-Петербурга в Москву максимально быстро и качественно. Мы гарантируем сохранность вашего груза, точное соответствие срокам и самые выгодные тарифы юридическим лицам.'

  typeActive = 0;
  typeTitle = 'Выберите грузоподъемность';

  tariffs = [{
    title: 'до 1.5 тонн',
    items: [{
      title: '<span class="d-block">Москва — Санкт&#8209;Петербург</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '65 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }, {
      title: '<span class="d-block">Санкт-Петербург — Москва</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '35 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }]
  }, {
    title: '3 тонны',
    items: [{
      title: '<span class="d-block">Москва — Санкт&#8209;Петербург</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '65 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }, {
      title: '<span class="d-block">Санкт-Петербург — Москва</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '35 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }]
  }, {
    title: '5 тонн',
    items: [{
      title: '<span class="d-block">Москва — Санкт&#8209;Петербург</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '65 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }, {
      title: '<span class="d-block">Санкт-Петербург — Москва</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '35 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }]
  }, {
    title: '5 тонн',
    items: [{
      title: '<span class="d-block">Москва — Санкт&#8209;Петербург</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '65 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }, {
      title: '<span class="d-block">Санкт-Петербург — Москва</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '35 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }]
  }, {
    title: '10 тонн',
    items: [{
      title: '<span class="d-block">Москва — Санкт&#8209;Петербург</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '65 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }, {
      title: '<span class="d-block">Санкт-Петербург — Москва</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '35 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }]
  }, {
    title: '20 тонн',
    items: [{
      title: '<span class="d-block">Москва — Санкт&#8209;Петербург</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '65 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }, {
      title: '<span class="d-block">Санкт-Петербург — Москва</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: '19000 ₽',
        ndsPrice: '35 000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md text-end'
      }]
    }]
  }]

  blockWithItemsAdvanced1 = {
    title: 'Решили заказать <br> грузоперевозку?',
    description: '',
    rowClass: '',
    items: [{
      class: 'col-12 col-md-6 pb-20-xs',
      subitems: [{
        image: '',
        title: '',
        description: 'Грузоперевозка товаров из Санкт-Петербурга в Москву — это одно из наиболее приоритетных направлений в работе нашей транспортной компании.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs height-to-full'
      }]
    }, {
      class: 'col-12 col-md-6 pb-20-xs',
      subitems: [{
        image: 'assets/images/spb-msk/spb-transportation.svg',
        title: '',
        description: '',
        descriptionClass: '',
        class: 'p-20-xs text-left b-r-16-xs height-to-full position-relative',
        imageClass: 'position-absolute-lg bottom-0-lg'
      }]
    }, {
      class: 'col-12 col-md-4 pb-20-xs',
      subitems: [{
        image: '',
        title: '',
        description: 'Мы предоставим автотранспорт, соответствующий типу перевозимого груза, весом до 20 000 кг.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-white border-blue b-r-16-xs height-to-full position-relative'
      }]
    }, {
      class: 'col-12 col-md-8 pb-20-xs',
      subitems: [{
        image: '',
        title: '',
        description: 'Персональный менеджер, закрепленный за вашей организацией, поможет рассчитать стоимость перевозки, подобрать подходящий вам автомобиль, проработает удобный с точки зрения логистики маршрут и подготовит все необходимые документы.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-white border-blue b-r-16-xs height-to-full position-relative'
      }]
    }]
  }



  blockWithItemsAdvanced = {
    title: 'Почему выбирают нас',
    description: '',
    rowClass: '',
    items: [{
      class: 'col-12 col-md-4 pb-20-xs',
      subitems: [{
        image: '',
        title: 'Штат квалифицированных сотрудников',
        description: 'Все рабочие процессы осуществляются опытными специалистами с большим стажем работы; <br><br> Наши водители ежедневно доставляют до 800 тонн груза; <br> <br> Работу водителей в процессе перевозки контролирует служба отдела качества; <br> <br> ',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs height-to-full'
      }]
    }, {
      class: 'col-12 col-md-4 pb-20-xs',
      subitems: [{
        image: 'assets/images/spb-msk/spb-autopark.svg',
        title: 'Большой <br> автопарк',
        description: 'Большой выбор транспортных средств с любым видом кузова под любые грузы; <br><br> Все автомобили проходят тщательное и своевременное обслуживание; <br><br> Систематическое пополнение и модернизация автопарка для решения большего количества логистических задач; <br> <br> Помощь в подборе варианта автомобиля конкретно под ваши нужды.',
        descriptionClass: '',
        imageClass: 'order-3',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs height-to-full'
      }]
    }, {
      class: 'col-12 col-md-4 pb-20-xs',
      subitems: [{
        image: '',
        title: 'Большой опыт работы в перевозках',
        description: '7 лет – средний стаж работы наших специалистов в логистике;<br><br>Накопленный годами опыт позволяет сэкономить ваши денежные и временные ресурсы; <br><br> Идеальный выбор для представителей любых сфер малого, среднего и крупного бизнеса, которым время от времени нужны срочные грузоперевозки из Санкт - Петербурга в Москву и подмосковье; <br><br> «Акро» - надежный и честный партнер.Наши клиенты всегда довольны и говорят нам“ спасибо”— почитайте отзывы.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs height-to-full'
      }]
    }, {
      class: 'col-12 col-md-6 pb-20-xs',
      subitems: [{
        image: '',
        title: 'Гарантия сохранности груза',
        description: 'Мы на 100% гарантируем безопасность вашего товара;<br><br>Полностью берем на себя заключение страхового договора; <br><br> Обеспечиваем самые выгодные условия на страхование груза; <br><br> Несём полную ответственность за ваш груз на всех этапах перевозки.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs height-to-full'
      }]
    }, {
      class: 'col-12 col-md-6 text-center pb-20-xs',
      subitems: [{
        image: '',
        title: 'Работа по всей России',
        description: 'Обеспечим качественную перевозку грузов по всей России, в том числе в самые отдаленные уголки страны; <br><br> Мы знаем все нюансы и тонкости работы практически в любых направлениях грузоперевозок по России; <br><br> Опытные специалисты построят оптимальный маршрут.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs height-to-full'
      }]
    }]

  }


  faq = [{
    title: "Как можно оплатить ваши услуги?",
    description: "Выберите удобную для вас систему оплаты перевозки: постоплату, которая производится на наш расчетный счет после оказания услуги, или предоплату(в этом случае действует акция, и стоимость перевозки будет ниже).Также мы предоставляем вам скидку на первый заказ.Платеж осуществляется на расчетный счет."
  }, {
    title: "Каким образом вы возвращаете документы?",
    description: "После оказания услуги грузоперевозки два раза в месяц осуществляется отправка оригиналов документов к вам в офис по адресу, указанному в личном кабинете при регистрации.Также при необходимости можем отправить акты выполненных работ почтой."
  }, {
    title: "Как зарегистрироваться в личном кабинете?",
    description: "Главная страница сайта <a href='https://tk-akro.ru/'>https://tk-akro.ru/</a> содержит ссылку «Личный кабинет», нажимая на которую, вы можете перейти в форму.Вы заполняете обязательные поля, даете разрешение на хранение и обработку персональных данных и нажимаете кнопку« зарегистрироваться». После этого вы можете войти в сервис и вам будут доступны все его опции: электронный документооборот, вы можете бесплатно отследить груз онлайн, а калькулятор рассчитает стоимость на доставку груза. Повторную заявку можно создать за пару минут.Обратите внимание, что заполняя онлайн - форму, вы принимаете нашу политику конфиденциальности и даете согласие на обработку ваших персональных данных.Также на сайте есть полезная информация о мире грузоперевозок, включая актуальные новости."
  }, {
    title: "Возможна ли экспресс - перевозка?",
    description: "Мы доставим транспорт срочно без задержек: подача транспорта до 3х т под загрузку в пределах МКАД в течение 2 часов.Если грузоподъемность машины 5 т и выше - прибытие в течение 3 - 4 часов в день обращения к пункту назначения."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Офис ООО «ТК АКРО» расположен по адресу: город Москва, ул.Щёлковское ш., дом 2а. Наше точное местоположение легко найти на карте, а также схема проезда есть на нашем сайте!"
  }];
}
