import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-flatbed-trucks',
  templateUrl: './flatbed-trucks.component.html',
  styleUrls: ['./flatbed-trucks.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FlatbedTrucksPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: 'avtopark',
      linkTitle: 'Наш автопарк'
    }, {
      link: '',
      linkTitle: 'Бортовые машины'
        }]
  }

  carActive   = 0;
  typeActive  = 0;

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3260 ₽',
          ndsPrice: '3750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '5868 ₽',
          ndsPrice: '6750 ₽',
          advPrice: '<span class="d-block">652 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">750 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '20 ₽/км',
        ndsCheckout: '23 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '3475 ₽',
          ndsPrice: '4000 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6255 ₽',
          ndsPrice: '7200 ₽',
          advPrice: '<span class="d-block">695 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">800 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4590 ₽',
          ndsPrice: '5280 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '6885 ₽',
          ndsPrice: '7920 ₽',
          advPrice: '<span class="d-block">765 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">880 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '22 ₽/км',
        ndsCheckout: '25 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '5100 ₽',
          ndsPrice: '5880 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7650 ₽',
          ndsPrice: '8820 ₽',
          advPrice: '<span class="d-block">850 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">980 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '30 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '750 ₽/950 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '7000 ₽',
          ndsPrice: '8050 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1150 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '30 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '800 ₽/1000 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '8320 ₽',
          ndsPrice: '9600 ₽',
          advPrice: '<span class="d-block">1040 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '37 ₽/км',
        pass: '870 ₽/1050 ₽/1400 ₽',
        ndsPass: '1000 ₽/1200 ₽/1600 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '9760 ₽',
          ndsPrice: '11200 ₽',
          advPrice: '<span class="d-block">1220 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '48 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽ /1750 ₽ /2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1750 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '53 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  },{
    title: 'Бортовой',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">Бизнес</span> <span class="d-block">Включено 70 км по Москве</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">Бизнес+</span> <span class="d-block">Включено 100 км по Москве</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  },{
    title: 'Манипулятор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/1.5t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '4980 ₽',
          ndsPrice: '5700 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7470 ₽',
          ndsPrice: '8550 ₽',
          advPrice: '<span class="d-block">830 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">950 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '28 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '560 ₽/650 ₽',
        ndsPass: '650 ₽/750 ₽'
      },{
        image: '//assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: '6090 ₽',
          ndsPrice: '7000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '7830 ₽',
          ndsPrice: '9000 ₽',
          advPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '35 ₽/км',
        ndsCheckout: '35 ₽/км',
        pass: '650 ₽/830 ₽',
        ndsPass: '650 ₽/750 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: '7350 ₽',
          ndsPrice: '8400 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 9,
          price: '9450 ₽',
          ndsPrice: '10800 ₽',
          advPrice: '<span class="d-block">1050 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '700 ₽/870 ₽',
        ndsPass: '750 ₽/950 ₽'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: '12600 ₽',
          ndsPrice: '14400 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '50 ₽/км',
        pass: '870 ₽/1050 ₽/1400',
        ndsPass: '1000 ₽/1200 ₽/1600'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: '14130 ₽',
          ndsPrice: '16200 ₽',
          advPrice: '<span class="d-block">1570 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1800 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: '16650 ₽',
          ndsPrice: '18900 ₽',
          advPrice: '<span class="d-block">1850 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '56 ₽/км',
        ndsCheckout: '65 ₽/км',
        pass: '1300 ₽/1750 ₽/2200 ₽',
        ndsPass: '1500 ₽/2000 ₽/2500 ₽'
      }]
    }]
  }];

  blockWithItems = {
    title: 'Качественно <br> и профессионально',
    class: 'col-12 col-md-4 text-center',
    items: [{
      image: 'assets/images/flatbed-trucks/flatbed-trucks-1.svg',
      alt: 'Подбор',
      title: '',
      description: 'Подберем машину для любых видов грузов и для любых целей',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/flatbed-trucks/flatbed-trucks-2.svg',
      alt: 'Сроки',
      title: '',
      description: 'Соблюдаем сроки и гарантируем сохранность продукции',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/flatbed-trucks/flatbed-trucks-3.svg',
      alt: 'Оптимальные цены',
      title: '',
      description: 'Предлагаем самые оптимальные цены',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }
}
