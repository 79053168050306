import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-block-with-items-advanced',
  templateUrl: './block-with-items-advanced.component.html',
  styleUrls: ['./block-with-items-advanced.component.less']
})
export class BlockWithItemsAdvancedComponent {

  @Input() title: string | undefined
  @Input() description: string | undefined
  @Input() rowClass: string | undefined
  @Input() items: {
    class: string,
    subitems: {
      image: string,
      imageClass?: string,
      title: string,
      description: string,
      descriptionClass: string,
      class: string,
      button?: {
        title: string,
        link: string
      }
    }[]
  }[] | undefined
}
