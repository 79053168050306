<app-banner
  [image]="''"
  [title]="'Карта сайта'"
  [description]="''"
  [showButtonCall]="false"
  [showButtonKp]="false"
></app-banner>

<div class="container container d-flex flex-column">
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="gruzoperevozki-po-moskve-i-mo">Грузоперевозки по Москве и МО</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="services">Услуги</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="akcii">Акции</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark">Автопарк</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="flatbed-trucks">Бортовые машины</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="novosti">Новости</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="kontakty">Контакты</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="marketplejsy">Доставка на маркетплейсы</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="marketplejsy/yandex-market">Доставка на Yandex-market</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="marketplejsy/wildberries">Доставка на Wildberries</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="marketplejsy/ozon">Доставка на Ozon</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/refrizheratornye-perevozki">Рефрижераторные перевозки</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="manipulyatory">Грузоперевозки - Манипулятор</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-na-gazeli">Грузоперевозки — Газель</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-3tonny">Грузоперевозки до 3 тонн</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-5tonn">Грузоперевозки до 5 тонн</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-10tonn">Грузоперевозки до 10 тонн</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-20tonn">Грузоперевозки до 20 тонн</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-furami">Грузоперевозки — Фуры</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-kabluk">Грузоперевозки — Каблук</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/gruzoperevozki-bychok">Грузоперевозки — Бычок</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="gruzoperevozki-dlya-yur-lic/gruzoperevozki-beznalichnyj-raschet">Грузоперевозки безналичный расчет</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="gruzoperevozki-dlya-yur-lic/gruzoperevozki-sotrudnichestvo">Грузоперевозки сотрудничество</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="gruzoperevozki-dlya-yur-lic/autsorsing-transportnyx-uslug">Аутсорсинг транспортных услуг</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="gruzoperevozki-dlya-fiz-lic">Грузоперевозки для физ.лиц</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="gruzoperevozki-dlya-yur-lic">Грузоперевозки для юр.лиц</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="strahovanie-gruza">Страхование груза</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="perevozki-po-rossii">Перевозки по России</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="avtopark/tent-furgon">Тент-Фургон</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="">Транспортная компания «Акро»</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="jobs">Вакансии</a>
    <a class="text-16-xs text-20-lg text-grey4 mb-10-xs" href="sitemap">Карта сайта</a>
</div>
