<app-banner
  [description]="'Услуги спецтехники'"
  [title]="'Тент-фургон'"
  [image]="'/assets/images/tent-furgon/tent-furgon6.svg'"
  [showButtonCall]="true">
</app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg mt-60-xs mb-60-xs border-grey3">
    <h2 class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Наш автопарк и тарифы</h2>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs">
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        Квартирный и офисные переезды, покупка габаритных грузов, различных материалов и оборудования — всегда ставит
        вопрос о перевозке
        и доставке. Грузовые перевозки оказываются как раз кстати, когда необходимо доставить новую продукцию в торговые
        сети, на маркетплейс
        или склад клиента, осуществить перевозку офисной мебели и техники. Компания «АКРО» готова предоставить грузовой
        автомобиль на заказ любой вместительности и объемов, который в целости и сохранности доставит грузы к точке
        выгрузки, как в пределах МКАД, так и за его пределами. Обращайтесь в проверенные фирмы, чтобы получить
        ответственность и перевозку лучшего качества.
        <br><br>
        Компания «АКРО» — это идеальный выбор для представителей любых сфер малого, среднего и крупного бизнеса, которым
        время от времени нужны срочные грузоперевозки по Москве и Подмосковью. Наша компания в процессе своей работы
        успела зарекомендовать себя как надежный
        и честный партнер.
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href="" role="button"
        class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href="" role="button" class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg text-grey4">
    <div class="row position-relative pb-50-lg pb-25-xs">
      <div class="col-12 col-lg p-0-xs d-flex flex-column justify-content-center order-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia2.svg"
          class="position-absolute-lg bottom-0-lg"  alt="Тент-фургон">
      </div>
      <div class="col col-lg-6 order-lg-2">
        <h3>Компания “Акро” занимается перевозками различных грузов на тентованных автомобилях</h3>
        <p>Грузоперевозки тент по России предназначены для предприятий
          и промышленных организаций. Мы перевезем любой Ваш груз до места назначения: продукты питания, медицинские
          препараты, цветочную продукцию, различное оборудование и любые другие грузы. Обращайтесь за помощью именно в
          нашу компанию, и мы гарантируем сделать все качественно.</p>
      </div>
    </div>
    <div class="row position-relative pt-80-lg pb-50-lg pb-25-xs pt-25-xs">
      <div class="col col-lg-6 order-lg-1">
        <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Что мы предлагаем</h2>
        <h3>Выгодные перевозки по всей России</h3>
        <p>Наша организация проводит выгодные перевозки по всей России. В основном востребованной перевозка остается в
          Москве, Подмосковье и Санкт-Петербурге. В этой области доставка груза распространена из-за большого количества
          крупных производств и предприятий.</p>
      </div>
      <div class="col-12 col-lg p-0-xs bottom-0-lg d-flex flex-column justify-content-center order-md-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia3.svg"
          class="position-absolute-lg" alt="По России">
      </div>
    </div>
    <div class="row position-relative pt-70-lg pb-50-lg order-2 pb-25-xs pt-25-xs">
      <div class="col-12 col-lg p-0-xs d-flex flex-column justify-content-center order-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia1.svg"
          class="position-absolute-lg" alt="К нам обращаются">
      </div>
      <div class="col col-lg-6 order-lg-2">
        <h3>К нам часто обращаются</h3>
        <p>Наша компания долго работает в этой сфере транспортной доставки по России
          и за продолжительный период развития зарекомендовала себя положительным образом, о чем свидетельствуют
          многочисленные отзывы. Много наших клиентов обращаются к нам вновь, если требуется перевезти вещи с надежной
          упаковкой.</p>
      </div>
    </div>
    <div class="row position-relative pt-70-lg pb-50-lg pb-25-xs pt-25-xs">
      <div class="col col-lg-6 ">
        <h3>Формируем опитмальный маршрут</h3>
        <p>В зависимости от количества и типа груза будет сформирован маршрут (предварительная схема) по Российской
          Федерации, который будет учитывать особенности перевоза. Наши специалисты всегда на связи, организуют все
          таким образом, чтобы Вы не платили лишние деньги за услуги.</p>
      </div>
      <div class="col-12 col-lg p-0-xs d-flex flex-column justify-content-center order-md-2">
        <img loading="lazy" src="/assets/images/transportation-on-russia/transportation-on-russia1.svg"
             alt="Оптимальный маршрут" class="position-absolute-lg">
      </div>
    </div>
  </div>


  <app-block-with-items
    [class]="blockWithItems['class']"
    [description]="blockWithItems['description']"
    [title]="blockWithItems['title']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <app-block-with-items
    [class]="blockWithItems2['class']"
    [description]="blockWithItems2['description']"
    [title]="blockWithItems2['title']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-block-with-items-advanced
    [title]="blockWithItemsAdvanced['title']"
    [description]="blockWithItemsAdvanced['description']"
    [rowClass]="blockWithItemsAdvanced['rowClass']"
    [items]="blockWithItemsAdvanced['items']">
  </app-block-with-items-advanced>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row position-relative pt-80-lg pb-50-lg">
      <div class="col-12 col-lg p-0-xs bottom-0-lg d-flex align-items-center justify-content-center order-2">
        <img loading="lazy" src="/assets/images/tent-furgon/tent-furgon11.svg" class="position-absolute-lg" alt="Гарантия">
      </div>
      <div class="col col-lg-6 order-lg-2">
        <h2>Гарантия сохранности</h2>
        <p>Мы гарантируем доставить Ваш груз в полной сохранности
          до места назначения. Можно воспользоваться нашей услугой
          по страхованию груза. Мы готовы взять на себя процедуру заключения договора страхования.
          Наша компания несет ответственность за весь Ваш груз,
          поэтому в случае причиненного ущерба Вашим вещам,
          мы предоставляем возмещение в полном размере.</p>
      </div>
    </div>
  </div>

  <app-block-with-items
    [class]="blockWithItems3['class']"
    [description]="blockWithItems3['description']"
    [title]="blockWithItems3['title']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <app-block-with-items-advanced
    [title]="blockWithItemsAdvanced2['title']"
    [description]="blockWithItemsAdvanced2['description']"
    [rowClass]="blockWithItemsAdvanced2['rowClass']"
    [items]="blockWithItemsAdvanced2['items']">
  </app-block-with-items-advanced>

</div>
