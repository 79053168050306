import {Component} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {
  public menu = [{
    title: 'Услуги',
    items: [
      [{
        title: 'Тент-фургон',
        link: '/avtopark/tent-furgon',
      }, {
        title: 'Бортовые машины',
        link: '/flatbed-trucks'
      }, {
        title: 'Грузоперевозки до 3 тонн',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Грузоперевозки до 5 тонн',
        link: '/avtopark/gruzoperevozki-5tonn'
      },{
        title: 'Грузоперевозки до 10 тонн',
        link: '/avtopark/gruzoperevozki-10tonn'
      },{
        title: 'Грузоперевозки до 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn'
      }],
      [{
        title: 'Грузоперевозки — Газель',
        link: '/avtopark/gruzoperevozki-na-gazeli'
      },{
        title: 'Грузоперевозки — Фуры',
        link: '/avtopark/gruzoperevozki-furami'
      },{
        title: 'Грузоперевозки — Каблук',
        link: '/avtopark/gruzoperevozki-kabluk'
      },{
        title: 'Грузоперевозки — Бычок',
        link: '/avtopark/gruzoperevozki-bychok'
      },{
        title: 'Грузоперевозки - Манипулятор',
        link: 'manipulyatory',
      },{
        title: 'Грузоперевозки безналичный расчет',
        link: '/gruzoperevozki-dlya-yur-lic/gruzoperevozki-beznalichnyj-raschet'
      }],
      [{
        title: 'Грузоперевозки сотрудничество',
        link: '/gruzoperevozki-dlya-yur-lic/gruzoperevozki-sotrudnichestvo/'
      },{
        title: 'Аутсорсинг транспортных услуг',
        link: '/gruzoperevozki-dlya-yur-lic/autsorsing-transportnyx-uslug/'
      },{
        title: 'Грузоперевозки для физических лиц',
        link: '/gruzoperevozki-dlya-fiz-lic',
      },{
        title: 'Грузоперевозки для юридических лиц',
        link: '/gruzoperevozki-dlya-yur-lic',
      }]
    ]
  },{
    title: 'Компания',
    items: [
      [{
        title: 'Контакты',
        link: '/kontakty'
      },{
        title: 'Новости',
        link: '/novosti'
      },{
        title: 'Вакансии',
        link: '/jobs',
      }]
    ]
  }]
}
