<div class="container pt-50-xs pt-36-lg pb-50-xs pb-60-lg">
  <div class="pr-30-xs pr-0-lg pl-30-xs pl-0-lg">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <h2 class="text-30-xs text-48-lg mb-36-xs d-block text-grey4" [innerHTML]="title"></h2>
    <p class="mb-36-xs mb-44-lg text-18-xs text-20-lg text-grey4" [innerHTML]="description"></p>

    <div class="row text-grey8 mr-0-xs ml-0-xs pr-20-xs pl-20-xs pt-10-xs pb-10-xs mt-32-xs mb-12-xs d-none d-md-block" *ngIf="getHeaders()">
      <div class="d-flex justify-content-between p-0 align-items-center"><div *ngFor="let item of getHeaders()" [innerHTML]="item['title']" [ngClass]="item['class']"></div></div>
    </div>

    <div class="row mr-0-xs ml-0-xs mb-10-xs b-r-16-xs bg-white pr-20-xs pl-20-xs pt-12-xs pb-12-xs border-grey3" *ngFor="let workingCost of workingCosts">
      <div class="d-flex p-0 justify-content-between align-items-center flex-column flex-md-row">
        <div class="text-grey8 d-sm-block d-md-none col-12 pt-20-xs">Наименование услуги</div>
        <div class="text-16-xs col-12 col-md-5">{{workingCost['designation']}}</div>
        <div class="text-grey8 d-sm-block d-md-none col-12 pt-20-xs">Ед. измерения</div>
        <div class="text-16-xs col-12 col-md-2">{{workingCost['unit']}}</div>
        <div class="text-grey8 d-sm-block d-md-none col-12 pt-20-xs">Цена</div>
        <div class="text-16-xs col-12 col-md-1">{{workingCost['price']}}</div>
        <div class="text-grey8 d-sm-block d-md-none col-12 pt-20-xs">Минимальная стоимость <br>(за 5 часов работы)</div>
        <div class="text-16-xs col-12 col-md-3">{{workingCost['min_price']}}</div>
      </div>
    </div>
    <div class="row mt-32-xs mb-12-xs">
      <div class="col-md-5 point-blue"><ul class="p-0"><li class='my-20-xs'>Услуги по разгрузке предметов весом от 150 до 00 кг</li><li class='my-20-xs'>Услуги по разгрузке предметов весом свыше 500 кг</li></ul></div>
      <div class="col-md-5 point-blue"><ul class="p-0"><li class='my-20-xs'>Этажность (подъем/спуск)</li><li class='my-20-xs'>Такелажные работы</li></ul></div>
    </div>
  </div>
</div>
</div>
