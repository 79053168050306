<div class="row align-items-end m-0-xs justify-content-between">
  <div class="col-auto pr-22-xs order-1" *ngIf="getTypeItems()">
    <div class="row text-12-xs mb-8-xs" [innerHTML]="typeTitle ? typeTitle : 'Выберите грузоподъёмность'"></div>
    <div class="row b-r-8-xs bg-white border-grey3 p-4-xs">
      <div class="col text-16-xs pt-8-xs pb-8-xs pr-12-xs pl-12-xs cursor-pointer text-nowrap d-none d-lg-flex"
           [ngClass]="{'bg-blue text-white b-r-6-xs fw-light': typeIndex === this.typeActive}"
           (click)="changeTypeActive(typeIndex)"
           *ngFor="let type of getTypeItems(); index as typeIndex">
        {{type['title']}}
      </div>

      <select class="col-12 text-16-xs pt-8-xs pb-8-xs pr-12-xs pl-12-xs cursor-pointer text-nowrap border-0 d-lg-none"
              (change)="changeTypeActive($event.target)">
        <option
             [value]="typeIndex"
             [ngClass]="{'bg-blue text-white b-r-6-xs': typeIndex === this.typeActive}"
             *ngFor="let type of getTypeItems(); index as typeIndex">
          {{type['title']}}
        </option>
      </select>
    </div>
  </div>

  <div class="col-12 col-md-auto mt-20-xs mt-0-md pr-10-md pl-10-md order-3 order-md-2" *ngIf="this.tariffs && (this.carActive || this.carActive === 0)">
    <div class="row text-12-xs mb-8-xs">Выберите тип фургона</div>
    <div class="row b-r-8-xs bg-white border-grey3 p-4-xs d-none d-lg-flex">
      <div class="col text-16-xs pt-8-xs pb-8-xs pr-12-xs pl-12-xs cursor-pointer text-nowrap"
           [ngClass]="{'bg-blue text-white b-r-6-xs fw-light': carIndex === this.carActive}"
           (click)="changeCarActive(carIndex)"
           *ngFor="let car of this.tariffs; index as carIndex">
        <span *ngIf="car">{{car['title']}}</span>
      </div>
    </div>
    <div class="row b-r-8-xs bg-blue border-blue text-white p-4-xs d-lg-none">
      <select class="text-16-xs pt-8-xs pb-8-xs pr-12-xs pl-12-xs cursor-pointer text-nowrap border-0 bg-blue text-white">
        <option
             [value]="carIndex"
             [ngClass]="{'bg-blue text-white b-r-6-xs': carIndex === this.carActive}"
             (click)="changeCarActive(carIndex)"
             *ngFor="let car of this.tariffs; index as carIndex">
          <span *ngIf="car">{{car['title']}}</span>
        </option>
      </select>
    </div>
  </div>

  <div class="col-auto pl-0-xs pl-22-lg order-2 order-md-3">
    <div class="row b-r-8-xs bg-white border-grey3 pt-8-xs pb-8-xs pl-12-xs pr-12-xs align-items-center">
      НДС
      <label class="switch ml-10-xs ml-15-md" for="checkbox">
        <input type="checkbox" id="checkbox" [checked]="this.nds" (change)="this.nds = !this.nds"/>
        <div class="slider round"></div>
      </label>
    </div>
  </div>
</div>

<div class="row text-grey8 mr-0-xs ml-0-xs pr-20-xs pl-20-xs pt-10-xs pb-10-xs mt-32-xs mb-12-xs d-none d-lg-flex"
     *ngIf="getHeaders() && !isMobile">
  <div *ngFor="let item of getHeaders()" [innerHTML]="item['title']" [ngClass]="item['class']"></div>
</div>

<div *ngIf="!isMobile">
  <div class="row mr-0-xs ml-0-xs mb-8-lg b-r-16-xs bg-white pr-20-lg pl-20-lg pt-12-lg pb-12-lg d-none d-lg-flex" *ngFor="let item of items">
    <div [ngClass]="item['imageClass'] ? item['imageClass'] : 'col-12 col-md-auto w-percent-100-md mw-110-md p-0-xs pr-15-md d-none d-md-block align-self-center'"><img src="{{item['image']}}" alt="{{item['alt']}}"></div>
    <div [ngClass]="item['titleClass'] ? item['titleClass'] : 'col-6 col-md-auto text-16-xs w-percent-100-md mw-140-md p-0-xs pr-15-md align-self-center'" [innerHTML]="item['title']"></div>
    <div class="col row" *ngIf="item['businessPlus'] && item['business']">
      <div class="col-12 col-md-auto w-percent-100-md mw-88-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center" *ngFor="let hour of [4,5,6,7,8,9]">
        <div class="row m-0-xs d-md-none" *ngIf="(item['businessPlus'] && hour == item['businessPlus']['hour']) || (item['business'] && hour == item['business']['hour'])">
          <div class="col-6 col-md-auto">{{ hour }} {{ hour === 1 ? 'час' : hour >= 2 && hour <= 4 ? 'часа' : 'часов' }}</div>
          <div class="col-6 col-md-auto p-0-xs" *ngIf="hour == item['business']['hour']">
            <span class="text-20-xs fw-bold" *ngIf="item['business']['ndsPrice'] && item['business']['price']" [innerHTML]="this.nds ? item['business']['ndsPrice'] : item['business']['price']"></span>
            <br *ngIf="item['business']['ndsPrice'] && item['business']['price']">
            <span class="text-12-xs" *ngIf="item['business']['ndsAdvPrice'] && item['business']['advPrice']" [innerHTML]="this.nds ? item['business']['ndsAdvPrice'] : item['business']['advPrice']"></span>
            <span class="text-12-xs" *ngIf="!item['business']['ndsPrice'] && !item['business']['price'] && !item['business']['ndsAdvPrice'] && !item['business']['advPrice']" [innerHTML]="'Под запрос'"></span>
          </div>
          <div class="col-6 col-md-auto p-0-xs" *ngIf="hour == item['businessPlus']['hour']">
            <span class="text-20-xs fw-bold" *ngIf="item['businessPlus']['ndsPrice'] && item['businessPlus']['price']" [innerHTML]="this.nds ? item['businessPlus']['ndsPrice'] : item['businessPlus']['price']"></span>
            <br *ngIf="item['businessPlus']['ndsPrice'] && item['businessPlus']['price']">
            <span class="text-12-xs" *ngIf="item['businessPlus']['ndsAdvPrice'] && item['businessPlus']['advPrice']" [innerHTML]="this.nds ? item['businessPlus']['ndsAdvPrice'] : item['businessPlus']['advPrice']"></span>
            <span class="text-12-xs" *ngIf="!item['businessPlus']['ndsPrice'] && !item['businessPlus']['price'] && !item['businessPlus']['ndsAdvPrice'] && !item['businessPlus']['advPrice']" [innerHTML]="'Под запрос'"></span>
          </div>
        </div>
        <div class="d-none d-md-block" *ngIf="(item['businessPlus'] && hour == item['businessPlus']['hour']) || (item['business'] && hour == item['business']['hour'])">
          <div *ngIf="hour == item['business']['hour']">
            <span class="text-20-xs fw-bold" *ngIf="item['business']['ndsPrice'] && item['business']['price']" [innerHTML]="this.nds ? item['business']['ndsPrice'] : item['business']['price']"></span>
            <br *ngIf="item['business']['ndsPrice'] && item['business']['price']">
            <span class="text-12-xs" *ngIf="item['business']['ndsAdvPrice'] && item['business']['advPrice']" [innerHTML]="this.nds ? item['business']['ndsAdvPrice'] : item['business']['advPrice']"></span>
            <span class="text-12-xs" *ngIf="!item['business']['ndsPrice'] && !item['business']['price'] && !item['business']['ndsAdvPrice'] && !item['business']['advPrice']" [innerHTML]="'Под запрос'"></span>
          </div>
          <div *ngIf="hour == item['businessPlus']['hour']">
            <span class="text-20-xs fw-bold" (show)="item['businessPlus']['ndsPrice'] && item['businessPlus']['price']" [innerHTML]="this.nds ? item['businessPlus']['ndsPrice'] : item['businessPlus']['price']"></span>
            <br *ngIf="item['businessPlus']['ndsPrice'] && item['businessPlus']['price']">
            <span class="text-12-xs" (show)="item['businessPlus']['ndsAdvPrice'] && item['businessPlus']['advPrice']" [innerHTML]="this.nds ? item['businessPlus']['ndsAdvPrice'] : item['businessPlus']['advPrice']"></span>
            <span class="text-12-xs" (show)="!item['businessPlus']['ndsPrice'] && !item['businessPlus']['price'] && !item['businessPlus']['ndsAdvPrice'] && !item['businessPlus']['advPrice']" [innerHTML]="'Под запрос'"></span>
          </div>
          <div *ngIf="hour != item['businessPlus']['hour'] && hour != item['business']['hour']">-</div>
        </div>
      </div>
    </div>
    <div [ngClass]="field['class']" *ngFor="let field of item['additionalFields']">
      <span class="text-12-xs white-space-pre-lg">{{field['title']}}</span>
      <span class="text-20-xs white-space-pre-lg fw-bold" [innerHTML]="this.nds ? field['ndsPrice'] : field['price']"></span>
    </div>
    <div class="col-6 col-md-auto text-20-xs w-percent-100-md mw-140-md p-0-xs pr-15-md align-self-center" *ngIf="item['checkout']" [innerHTML]="this.nds ? item['checkout'] : item['ndsCheckout']"></div>
    <div class="col-6 col-md-auto text-20-xs w-percent-100-md mw-130-md p-0-xs align-self-center" *ngIf="item['pass']" [innerHTML]="this.nds ? item['pass'] : item['ndsPass']"></div>
  </div>
</div>

<ngx-slick-carousel class="carousel d-block d-lg-none p-0-xs"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                    *ngIf="isMobile">
  <div ngxSlickItem class="slide p-10-xs" *ngFor="let item of items">
    <div class="b-r-8-xs bg-white p-20-xs">
      <div [ngClass]="item['imageClass'] ? item['imageClass'] : 'col-12 mb-20-xs'"><img src="{{item['image']}}" alt="{{item['alt']}}"></div>
      <div class="row m-0-xs mb-24-xs p-0-xs">
        <div class="col-6 p-0-xs pr-17-xs text-grey9 ">Характеристики</div>
        <div class="col-6 p-0-xs pl-17-xs text-16-xs fw-bold white-space-pre-lg" [innerHTML]="item['title']"></div>
      </div>
      <div class="border-bottom mb-32-xs"></div>
      <div *ngIf="item['businessPlus'] && item['businessPlus']">
        <div class="col-12 mb-16-xs mb-0-md p-0-xs" *ngFor="let hour of [4,5,6,7,8,9]">
          <div class="row m-0-xs" *ngIf="(item['businessPlus'] && hour == item['businessPlus']['hour']) || (item['business'] && hour == item['business']['hour'])">
            <div class="col-6 p-0-xs pr-17-xs">{{ hour }} {{ hour === 1 ? 'час' : hour >= 2 && hour <= 4 ? 'часа' : 'часов' }}</div>
            <div class="col-6 p-0-xs pl-17-xs" *ngIf="hour == item['business']['hour']">
              <span class="text-20-xs fw-bold white-space-pre-lg" [innerHTML]="this.nds ? item['business']['ndsPrice'] : item['business']['price']"></span>
              <br>
              <span class="text-12-xs white-space-pre-lg" [innerHTML]="this.nds ? item['business']['ndsAdvPrice'] : item['business']['advPrice']"></span>
            </div>
            <div class="col-6 p-0-xs pl-17-xs" *ngIf="hour == item['businessPlus']['hour']">
              <span class="text-20-xs fw-bold white-space-pre-lg" [innerHTML]="this.nds ? item['businessPlus']['ndsPrice'] : item['businessPlus']['price']"></span>
              <br>
              <span class="text-12-xs white-space-pre-lg" [innerHTML]="this.nds ? item['businessPlus']['ndsAdvPrice'] : item['businessPlus']['advPrice']"></span>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="field['class']" *ngFor="let field of item['additionalFields']; let index = index">
        <div class="row m-0-xs mb-24-xs p-0-xs">
          <span class="col-6 p-0-xs pr-17-xs text-grey9 fw-bold" [innerHTML]="getHeaders()[index + 2]['title']"></span>
          <span class="col-6 p-0-xs pl-17-xs text-24-xs fw-bold white-space-pre-lg" [innerHTML]="this.nds ? field['ndsPrice'] : field['price']"></span>
        </div>
      </div>
      <div class="row m-0-xs mb-16-xs p-0-xs" *ngIf="item['checkout']">
        <div class="col-6 p-0-xs pr-17-xs">Выезд за МКАД</div>
        <div class="col-6 text-20-xs fw-bold white-space-pre-lg p-0-xs pl-17-xs" [innerHTML]="this.nds ? item['checkout'] : item['ndsCheckout']"></div>
      </div>
      <div class="row m-0-xs p-0-xs" *ngIf="item['pass']">
        <div class="col-6 p-0-xs pr-17-xs">Пропуск ТТК / СК / МКАД</div>
        <div class="col-6 text-20-xs fw-bold white-space-pre-lg p-0-xs pl-17-xs" [innerHTML]="this.nds ? item['pass'] : item['ndsPass']"></div>
      </div>
    </div>
  </div>
</ngx-slick-carousel>
