import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-page-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesPage {
}
