<app-banner
  [image]="'/assets/images/banner/banner-trucking-collaboration.svg'"
  [title]="'Грузоперевозки <br> сотрудничество'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="text-lg-start pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Сотрудничество с нами имеет ряд преимуществ</h2>

    <div class="row text-grey3">
      <div class="col-12 col-lg bg-purple border-grey3 b-r-16-xs mb-20-xs mb-0-lg mr-20-lg d-flex flex-column d-none d-md-block">

        <div class="row flex-fill align-content-between text-gray3 p-20-xs">
          <div>
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-11.svg">
          </div>
          <p class="text-16-lg p-0-xs m-0-xs">Наличие собственного автопарка позволяет нам перевозить грузы
            без повреждений в сроки,
            согласованные с заказчиком</p>
        </div>
      </div>
      <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs d-flex d-md-none">
        <p class="text-18-lg p-0 m-0" style="width: 80%;">Наличие собственного автопарка позволяет нам перевозить грузы
          без повреждений в сроки,
          согласованные с заказчиком</p>
        <div class="col-auto" style="width: 20%;"><img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-10.svg"></div>
      </div>

      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Максимальный вес одной коробки — 20 кг</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-1.svg">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mt-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Опытные<br>экспедиторы</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-8.svg">
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mr-20-lg d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-20-xs mb-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Доступные цены</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-9.svg">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg flex-fill align-items-center text-gray3 p-20-xs">
          <p class="col text-16-lg p-0-xs m-0-xs">Клиент всегда знает, где товар и когда он будет доставлен</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-collaboration/trucking-collaboration-7.svg">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-with-items
    [title]="blockWithItems['title']"
    [description]="blockWithItems['description']"
    [class]="blockWithItems['class']"
    [items]="blockWithItems['items']">
  </app-block-with-items>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <app-block-with-items class="pb-50-xs pb-60-lg border-bottom"
    [title]="blockWithItems3['title']"
    [description]="blockWithItems3['description']"
    [class]="blockWithItems3['class']"
    [items]="blockWithItems3['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <app-feedback
      [title]="'Заполните форму обратной связи и получите:'"
      [description]="'' +
         '— Расчет стоимости перевозки сборных грузов <br>'+
         '— Индивидуальное коммерческое предложение <br>'+
         '— Доставку товаров в маркетплейс'"
    >
    </app-feedback>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Как мы работаем</h2>
    <div class="row">
      <div class="col">
        <p class="text-16-xs">Наша задача как надежного грузоперевозчика — доставить товар из пункта А в пункт Б</p>
      </div>
    </div>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">01</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Принимаем заявку через сайт или по телефону</h3>
            <p class="text-16-xs">Клиент оставляет заявку на сайте или по телефону</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-1.svg" alt="Заявка">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Заключаем договор</h3>
            <p class="text-16-xs">В котором отражены все условия и нюансы: описание груза, маршрут, стоимость, время доставки, ответственность сторон</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-2.svg" alt="Договор">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Доставка с помощью автотранспорта</h3>
            <p class="text-16-xs">После оформления документации транспорт доставляется к месту забора</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-3.svg" alt="Доставка">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-lg">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Заключительный этап — разгрузка </h3>
            <p class="text-16-xs">Клиент может заказать погрузочно-разгрузочные работы: наши аккуратные
              и  вежливые грузчики оперативно загрузят или разгрузят автомобиль</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img loading="lazy" src="assets/images/trucking-non-cash-payment/trucking-non-cash-payment-4.svg" alt="Разгрузка">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-block-with-items
  [title]="blockWithItems4['title']"
  [description]="blockWithItems4['description']"
  [class]="blockWithItems4['class']"
  [items]="blockWithItems4['items']">
</app-block-with-items>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>

    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
