<app-banner
  [image]="'/assets/images/banner/banner-home.svg'"
  [title]="'Грузоперевозки по Москве <br> и Московской области'"
  [description]="'Гибкая система скидок и индивидуальные условия'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>
<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>
<div class="container"><app-block-description-page [description]="descriptionTransportation"></app-block-description-page></div>
<app-service [services]="services"></app-service>
<app-what-choose-us></app-what-choose-us>
<app-lk></app-lk>
<app-autopark></app-autopark>
<app-how-to-book-shipping></app-how-to-book-shipping>
<app-our-clients></app-our-clients>
<app-actions-and-sales [items]="actionsAndSales"></app-actions-and-sales>
<div class="container">
  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Часто задаваемые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>
</div>
<app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>
