import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-page-services-special-vehicles',
  templateUrl: './services-special-vehicles.component.html',
  styleUrls: ['./services-special-vehicles.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesSpecialVehiclesPage {
}
