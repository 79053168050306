<app-banner
  [image]="'/assets/images/banner/banner-trucking-heel.svg'"
  [title]="'Грузоперевозки <br>Каблук'"
  [description]="'По Москве и Московской области'"
  [showButtonCall]="true"
  [showButtonKp]="true"
></app-banner>

<app-breadcrumbs [items]="breadcrumbs['items']"></app-breadcrumbs>

<div class="container">
  <div class="bg-purple border-grey3 b-r-16-xs p-20-xs p-40-lg">
    <span class="text-30-xs text-48-lg mb-36-xs d-block text-grey4">Тарифы</span>

    <app-tariff
      [carActive]="carActive"
      [typeActive]="typeActive"
      [tariffs]="tariffs"
    >
    </app-tariff>

    <div class="row mt-32-xs">
      <div class="col-12 col-md-3 mb-20-xs mb-0-md fw-semibold text-center-lg">
        В стоимость входит:
      </div>
      <div class="col-12 col-md-3 mb-48-xs mb-0-md d-md-none text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>

      <div class="col-12 col-md-9 fw-semibold text-center-lg">
        Дополнительно оплачивается:
      </div>
    </div>

    <div class="row mt-40-xs">
      <div class="col-12 col-md-3 d-none d-md-block text-center-lg">
        <img loading="lazy" src="assets/images/tariff/tariff-1.svg" class="mb-10-xs" alt="Подача">
        <br>
        Подача авто под загрузку <br> 70 км по Москве
      </div>
      <div class="col-12 col-md-9">
        <div class="row justify-content-between">
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-2.svg" class="mb-10-xs" alt="МКАД">
            <br>
            Выезд на МКАД <br> (в обе стороны)
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-3.svg" class="mb-10-xs" alt="Помощь">
            <br>
            Помощь водителя при <br> погрузке или выгрузке
          </div>
          <div class="col-12 col-md-auto mb-20-xs mb-0-md text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-4.svg" class="mb-10-xs" alt="Экспедирование">
            <br>
            Экспедирование <br> груза
          </div>
          <div class="col-12 col-md-auto text-center-lg">
            <img loading="lazy" src="assets/images/tariff/tariff-5.svg" class="mb-10-xs" alt="Гидроборт">
            <br>
            Использование гидроборта <br> при погрузке или выгрузке
          </div>
        </div>
      </div>
    </div>

    <div class="row b-r-16-xs bg-white align-items-start p-24-xs mt-60-xs">
      <div class="col-12 col-md-auto text-24-xs pb-20-px">
        Индивидуальные <br> условия
      </div>
      <div class="col-12 col-md text-16-xs mb-20-xs mb-0-md">
        При наличии ежедневных отгрузок или постоянных маршрутов, мы подготовим индивидуальный прайс со скидкой для вас.
        <br>
        <br>
        Подробную информацию можно получить по номеру телефона +7(499)350-90-02
      </div>
    </div>
    <div class="row mt-40-xs">
      <a href=""
         role="button"
         class="btn bg-blue text-16-lg text-white col-12 col-lg-auto mr-16-xs pt-12-xs pb-12-xs pr-24-xs pl-24-xs">
        Запросить обратный звонок
      </a>
      <a href=""
         role="button"
         class="btn text-18-lg text-blue col-auto pt-20-xs pt-0-lg margin-auto-lg">
        Получить КП
      </a>
    </div>
  </div>

  <app-block-description-page
    [description]="description">
  </app-block-description-page>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg mb-20-xs mb-0-lg">
        <h2 class="text-48-lg mb-32-xs text-grey4">Грузоперевозки на каблуке</h2>
        <span class="text-18-xs">
          Одно из направлений нашей деятельности — грузовые перевозки и переезд на так называемом каблуке. Каблук — это компактный, но при этом вместительный фургон, с помощью которого осуществляется транспортировка небольших по объему грузов.
        </span>
      </div>
      <div class="col-12 col-lg">
        <img loading="lazy" src="assets/images/trucking-heel/trucking-heel-1.svg" alt="Каблук">
      </div>
      <div class="col-12 pr-0-xs pr-50-lg mt-30-xs mb-20-xs mb-0-lg">
        <span class="text-18-xs">
          На рынке коммерческих и частных услуг каблук является наиболее доступным вариантом для грузоперевозки и переезда. Чаще всего данный транспорт заказывают клиенты, которым требуется офисный, квартирный или дачный переезд, перевозка крупной бытовой техники, мебели, стройматериалов, продуктов или других вещей, которые нельзя перевезти обычным легковым автомобилем.
        </span>
      </div>
    </div>
  </div>

  <app-block-with-items
  [title]="blockWithItems['title']"
  [description]="blockWithItems['description']"
  [class]="blockWithItems['class']"
  [items]="blockWithItems['items']">
</app-block-with-items>

  <div class="text-lg-start pr-20-xs pr-0-lg pl-20-xs pl-0-lg d-md-block d-none">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Кто заказывает</h2>
    <p class="mb-20 mb-30-lg text-24-xs p-0-xs">Чаще всего данный транспорт заказывают клиенты, которым требуется:</p>

    <div class="row text-grey3">
      <div class="col-3 pr-20-xs d-flex flex-column">
        <div class="row flex-fill bg-purple border-grey3 b-r-16-xs p-20-xs align-content-between">
          <div>
            <img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" width="33" height="33" class="m-0fmb-54-xs" alt="Офис">
          </div>
          <p class="text-18-lg p-0 m-0">Офисный, квартирный или дачный переезд</p>
        </div>
      </div>
      <div class="col-3 pl-20-xs pr-20-xs d-flex flex-column">
        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg align-items-center text-gray3 p-20-xs" style="height: 50%;">
          <p class="col text-18-lg p-0 m-0">Перевозки не крупной бытовой техники</p>
          <div class="col-auto">
            <img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Бытовая техника">
          </div>
        </div>
        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg align-items-center text-gray3 p-20-xs" style="height: 50%;">
          <p class="col text-18-lg p-0 m-0">Продуктов</p>
          <div class="col-auto">
            <img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Продукты">
          </div>
        </div>
      </div>
      <div class="col-6 pl-20-xs flex-column">
        <div class="row mb-10-lg text-gray3" style="height: 50%;">
          <div class="col align-items-center bg-purple border-grey3 b-r-16-xs p-20-xs mr-10-xs">
            <div class="row pr-10-xs pl-10-xs">
              <p class="col text-18-lg p-0 m-0">Небольшой мебели</p>
              <div class="col-auto">
                <img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Мебель">
              </div>
            </div>
          </div>
          <div class="col align-items-center bg-purple border-grey3 b-r-16-xs p-20-xs ml-10-xs">
            <div class="row pr-10-xs pl-10-xs">
              <p class="col text-18-lg p-0 m-0">Стройматериалов</p>
              <div class="col-auto">
                <img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Стройматериалы">
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-10-lg align-items-center text-gray3 pt-20-xs pb-20-xs" style="height: 50%;">
          <p class="text-18-lg p-0 m-0">Или других вещей, которые нельзя перевезти обычным легковым автомобилем, но при этом не требуется большой авто типа газели</p>
        </div>
      </div>
    </div>
  </div>

  <div class="text-lg-start pr-20-xs pr-0-lg pl-20-xs pl-0-lg d-md-none d-block">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Кто заказывает</h2>
    <p class="mb-20 mb-30-lg text-24-xs p-0-xs">Чаще всего данный транспорт заказывают клиенты, которым требуется:</p>

    <div class="row text-grey3 px-20-xs">

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="text-18-lg p-0 m-0" style="width: 80%;">Офисный, квартирный или дачный переезд</p>
          <div class="col-auto" style="width: 20%;"><img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Офис"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Перевозки не крупной бытовой техники</p>
          <div class="col-auto"><img loading="lazy" src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Бытовая техника"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Продуктов</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Продукты"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mt-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Небольшой мебели</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Мебель"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="col text-18-lg p-0 m-0">Стройматериалов</p>
          <div class="col-auto"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="Стройматериалы"></div>
        </div>

        <div class="row bg-purple border-grey3 b-r-16-xs mb-10-lg text-gray3 p-20-xs my-10-xs">
          <p class="text-18-lg p-0 m-0" style="width: 80%;">Или других вещей, которые нельзя перевезти обычным легковым автомобилем, но при этом не требуется большой авто типа газели</p>
          <div class="col-auto" style="width: 20%;"><img src="assets/images/trucking-heel/trucking-heel-2.svg" alt="другое"></div>
        </div>

    </div>

  <app-block-with-items
    [title]="blockWithItems2['title']"
    [description]="blockWithItems2['description']"
    [class]="blockWithItems2['class']"
    [items]="blockWithItems2['items']">
  </app-block-with-items>

  <div class="pb-50-xs pb-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4">Почему грузоперевозки по Москве и области каблуком – это лучший выбор?</h2>
    <p class="text-16-xs py-30-xs">Вы можете быть уверены, что в штате нашей компании работают самые квалифицированные сотрудники, а наш автопарк содержит автомобили с грузоподъемностью от 500 кг до 20 тонн: тем самым мы готовы закрыть  любые требования наших  клиентов.</p>
    <div class="row m-0-xs">
      <div class="col-12 border-blue b-r-16-xs">
        <div class="row p-20-xs p-40-lg">
          <div class="col-auto text-blue text-48-xs pr-0-xs pr-20-xs">01</div>
          <div class="col">
            <h3 class="text-24-xs mb-16-xs">Успешный опыт работы подтверждают многочисленные отзывы довольных клиентов</h3>
            <p class="text-16-xs">Перевозки с компанией «Акро» - это быстро, надежно и выгодно. Вы можете быть уверены, что в штате
              нашей компании работают самые квалифицированные сотрудники, а наш автопарк содержит автомобили
              с грузоподъемностью от 500 кг до 20 тонн: тем самым мы готовы закрыть  любые требования наших  клиентов</p>
          </div>
          <div class="col-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-3.svg" alt="перевозки акро">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">02</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Все водители имеют большой стаж работы, отлично
              ориентируются в Москве </h3>
            <p class="text-16-xs">Максимально бережно относятся к доверенному им грузу и в случае форс-мажорных ситуаций
              всегда найдут выход</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-4.svg" alt="Стаж">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">03</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Наши менеджеры гарантируют отличный сервис</h3>
            <p class="text-16-xs">Они ответят на все ваши вопросы, подберут оптимальный маршрут и индивидуальный тариф с учетом особенностей вашего груза</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-5.svg" alt="Сервис">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">04</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Если погрузочно-разгрузочные работы своими силами невозможны, к вам на помощь приедут  наши грузчики</h3>
            <p class="text-16-xs">Они осуществят вынос вашего груза из квартиры, дома или офиса, помогут осуществить подъем на этаж, загрузку в машину и т.д.</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-6.svg" alt="Помощь">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">05</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Все наши машины находятся в отличном техническом состоянии</h3>
            <p class="text-16-xs">Они регулярно проходят техосмотры и своевременно обслуживаются</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-7.svg" alt="Техосмотры">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">06</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Перевозка товара с ТК «Акро» будет произведена качественно и в срок</h3>
            <p class="text-16-xs"> Машина приедет к вам точно в оговоренное время</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-13.svg" alt="В срок">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">07</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Мы максимально аккуратно и осторожно перевезём даже самые хрупкие вещи</h3>
            <p class="text-16-xs">Стекло, антиквариат, мягкую мебель, оборудование, строительные материалы, технику</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-8.svg" alt="Аккуратно">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">08</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Перевозка грузов из любого региона или города России </h3>
            <p class="text-16-xs">Перевозка до пункта назначения быстрая, безопасная и с соблюдением всех правил транспортировки</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-9.svg" alt="Перевозка">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">09</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Гарантируем полное сопровождение сделки на всех этапах</h3>
            <p class="text-16-xs">Грузоперевозки с нами – это просто и выгодно. Мы гарантируем низкие цены на доставку, комфортные условия сотрудничества и различные варианты оплаты, сохранность вашего груза и соблюдение оговоренных сроков</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-10.svg" alt="Сопровождение">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">10</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Осуществляем перевозку нестандартного груза</h3>
            <p class="text-16-xs">Если вам требуется перевозка нестандартного груза, вы можете прислать нам фото или видео, чтобы
              наши менеджеры могли подобрать для вас наилучший авто и в кратчайшие сроки рассчитать стоимость</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-11.svg" alt="Нестандартный груз">
          </div>
        </div>
        <div class="row border-top-blue b-r-16-xs p-20-xs p-40-lg">
          <div class="col-12 col-lg-auto text-blue text-48-xs pr-0-xs pr-20-xs">11</div>
          <div class="col-12 col-lg">
            <h3 class="text-24-xs mb-16-xs">Перевозка товаров в центр города</h3>
            <p class="text-16-xs">Если перевозка ваших товаров предполагает въезд в центр города, то «каблук» будет идеальным вариантом, поскольку у этих автомобилей нет проблем с передвижением по самым оживленным улицам Москвы</p>
          </div>
          <div class="col-12 col-lg-auto text-center-lg py-20-xs py-0-lg">
            <img src="assets/images/trucking-heel/trucking-heel-12.svg" alt="В центр">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Все под контролем</h2>
    <div class="row">
      <div class="col-12 col-lg pr-0-xs pr-50-lg">
        Перевозки каблуком по сути представляют собой грузовое такси, грузоподъемность которого не превышает 500 кг, с объемом кузова 2 м³. Это идеальный вариант для малогабаритных перевозок и переезда как в черте города, так и за его пределами. Небольшие размеры, маневренность и возможность беспрепятственного въезда в центр (полная масса каблука меньше 2500 кг), куда не пустят «Газель», — это основные преимущества, которыми отличается подобный вид автомобилей. Заказ такого транспорта позволяет вам не переплачивать за свободный объем в кузове машины, поскольку для перевозок грузов от 500 кг и до 10 тонн используется совсем другая техника. При оформлении городской грузоперевозки компанией, подберем для вас оптимальный автомобиль, учитывая все нюансы дешево. Только у нас вы найдете самые выгодные условия на качественную перевозку ваших товаров и переезд в день обращения.
      </div>
    </div>
  </div>

  <app-block-description-page
  [description]="description2">
  </app-block-description-page>

  <app-tags [title]="tags['title']" [items]="tags['items']" class="pr-10-xs pr-0-lg pl-10-xs pl-0-lg"></app-tags>

  <div class="pb-50-xs pb-60-lg pt-50-xs pt-60-lg pr-20-xs pr-0-lg pl-20-xs pl-0-lg">
    <h2 class="text-36-xs text-44-lg text-grey4 mb-24-xs mb-40-md p-0-xs">Частые вопросы</h2>
    <app-faq-item [item]="item" *ngFor="let item of faq" class="p-0-xs text-18-xs text-22-lg"></app-faq-item>
  </div>


</div>
