import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-working-costs',
  templateUrl: './working-costs.component.html',
  styleUrls: ['./working-costs.component.less']
})
export class WorkingCostsComponent {
  @Input() header: any = [{
    title: 'Наименование услуги',
    class: 'col-5 p-0-xs pr-15-xs'
  }, {
    title: 'Ед. измерения',
    class: 'col-2 p-0-xs pr-15-xs'
  }, {
    title: 'Цена',
    class: 'col-1 p-0-xs pr-15-xs'
  }, {
    title: 'Минимальная стоимость <br>(за 5 часов работы)',
    class: 'col-3 p-0-xs pr-15-xs'
  }];

  @Input() title: string = '';
  @Input() description: string = '';
  @Input() workingCosts: {
    designation: string,
    unit: string,
    price: string,
    min_price: string
  } [] | undefined

  getHeaders() {
    return this.header;
  }
}
